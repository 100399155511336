import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'flagsmith/react';
import {
  FLAG_USERS_READONLY,
  NOTICE_USERS_READONLY_BODY,
  NOTICE_USERS_READONLY_TITLE
} from '../../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../actions/FeatureFlags';
import { setCurrentModal } from '../../../actions/Modals';

const UserInvitationButton = ({
  text = 'Invite Users',
  className = 'btn btn-sm btn-primary text-nowrap',
  children,
  style = {},
  modalTitle = 'Invite Users',
  locationId,
  inviteType,
  inviteTypeTitle,
  singleUserInvite,
  includeTitle,
  includeName,
  includeRole,
  allowedRoles,
  disabled
}) => {
  const dispatch = useDispatch();
  const flags = useFlags([FLAG_USERS_READONLY]);
  // Redux
  const currentUser = useSelector((state) => state.currentUser);

  const showInviteModal = () => {
    if (flags?.users_readonly?.enabled && flags?.users_readonly?.value) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_USERS_READONLY_TITLE,
        modalBody: NOTICE_USERS_READONLY_BODY
        // preventClose: true
      }));
      return;
    }

    dispatch(
      setCurrentModal({
        key: 'userInvitation',
        data: {
          modalTitle,
          inviteType,
          locationId,
          inviteTypeTitle,
          // role: ROLE_PROGRAM_TRAINER,
          allowedRoles,
          singleUserInvite: singleUserInvite || true,
          includeName: includeName || true,
          includeRole,
          includeTitle
        }
      })
    );
  };

  if (!currentUser.id) {
    return null;
  }

  return (
    <button
      className={className}
      title={text}
      onClick={showInviteModal}
      type="button"
      style={style}
      disabled={disabled}
    >
      {children || text}
    </button>
  );
};

export default UserInvitationButton;
