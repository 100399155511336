import React, { useEffect, useRef, useState } from 'react';
import { FaPaperPlane, FaTimesCircle } from 'react-icons/fa';

const InputField = ({
  className = '',
  onSubmit,
  id = 'searchValueInput',
  name = 'searchValueInput',
  value,
  loading = false,
  placeholder = ''
  // autoFocus = false
}) => {
  const searchInputRef = useRef(null);
  const [searchInputValue, setSearchInputValue] = useState(value || '');

  const clearSearchResults = () => {
    setSearchInputValue('');
  };

  const onChange = (e) => {
    const { value: inputValue } = e.currentTarget;

    setSearchInputValue(inputValue);
  };

  useEffect(() => {
    searchInputRef.current.focus();
  }, []);

  return (
    <div
      className={`${className} p-3`}
      style={{
        backgroundColor: '#3e3f4b'
      }}
    >
      <form
        onSubmit={(e) => {
          onSubmit(e);
          clearSearchResults();
        }}
      >
        <div className="d-flex">
          <div className="position-relative w-100 mr-2">
            <input
              id={id}
              ref={searchInputRef}
              type="text"
              className="form-control form-control-lg w-100 border-0"
              name={name}
              value={searchInputValue}
              onChange={onChange}
              placeholder={placeholder}
              autoFocus
            />
            {searchInputValue && (
              <button
                type="button"
                className="btn btn-link position-absolute"
                onClick={clearSearchResults}
                title="Reset Search"
                aria-label="Reset Search"
                style={{
                  top: '5px',
                  right: '5px'
                }}
              >
                <FaTimesCircle />
              </button>
            )}
          </div>
          <button
            type="submit"
            title='Send'
            aria-label='Send'
            className="btn btn-primary"
            disabled={loading}
          >
            <FaPaperPlane />
          </button>
        </div>
      </form>
    </div>
  );
};

export default InputField;
