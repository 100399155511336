import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateUser } from '../../../../services/user';
import { canManageDirectoryUsers } from '../../../../services/currentUser';
import { getDirectoryUser } from '../../../../actions/Directory';
import { getCurrentUser } from '../../../../actions/Users';
import UsersSearchSelect from '../../../ManageContent/UsersSearchSelect';
import Users from '../../../ManageContent/Users';

const ManageSupervisors = ({
  handleCancel
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const directoryUser = useSelector((state) => state.directoryUser);
  // Local State
  const [loading, setLoading] = useState(false);
  // Organization
  const orgId = organization?.id || null;
  const orgType = organization?.type || null;
  // Current User
  const userId = currentUser?.id || null;
  const role = currentUser?.role || [];

  const handleSaveSupervisors = (newSupervisorIds) => {
    setLoading(true);
    toast.info('Saving...');

    const dataToSave = {
      supervisorIds: Array.isArray(newSupervisorIds) && newSupervisorIds.length !== 0 ? newSupervisorIds : null
    };

    updateUser(dataToSave, directoryUser?.id).then(() => {
      toast.success('Supervisors saved!');
      if (directoryUser?.id === userId) {
        dispatch(getCurrentUser({ userId })).then(() => {
          setLoading(false);
          handleCancel();
        });
      }

      if (directoryUser?.id !== userId) {
        dispatch(getDirectoryUser({ orgId, userId: directoryUser?.id })).then(() => {
          setLoading(false);
          handleCancel();
        });
      }
    });
  };

  // View/Edit Supervisors
  if (canManageDirectoryUsers(role)) {
    return (
      <div
        style={{
          overflowY: 'scroll',
          height: 'calc(100vh - 170px)'
        }}
      >
        <UsersSearchSelect
          className='UsersSearchSelect-supervisors'
          ids={directoryUser?.supervisorIds}
          loading={loading}
          userIdToExclude={directoryUser?.id}
          searchFilters={{
            excludeRoles: [
              ...(orgType === 'community' ? ['student'] : []),
              'superAdmin'
            ]
          }}
          handleSecondaryAction={handleCancel}
          callbackPrimaryAction={handleSaveSupervisors}
        />
      </div>
    );
  }

  // View supervisors, no permissions to edit
  return (
    <div className='card-body'>
      <Users
        key={directoryUser?.supervisorIds.join(',')}
        userIds={directoryUser?.supervisorIds}
      />
    </div>
  );
};

export default ManageSupervisors;
