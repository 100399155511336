import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

const OrgLocation = ({
  className = '',
  locationId,
  small = false,
  imageHeight = 20,
  theme = 'light',
  showOnlyImage = false
}) => {
  // Redux
  const locations = useSelector((state) => state.locations);
  // Location
  const orgLocation = useMemo(() => {
    const locationsHash = locations?.hash || {};
    return locationsHash[locationId] || {};
  }, [locationId, locations?.hash]);
  const locationImage = orgLocation?.locationImage || null;
  const locationName = orgLocation?.name || null;

  if (orgLocation?.id) {
    return (
      <div className={className}>
        <div className="m-0 d-flex align-items-center">
          {locationImage && (
            <>
              {theme === 'dark' ? (
                <div className="bg-light mr-2 d-flex">
                  <img
                    src={locationImage}
                    height={imageHeight}
                    style={{
                      height: `${imageHeight}px`
                    }}
                    alt={locationName}
                  />
                </div>
              ) : (
                <img
                  src={locationImage}
                  height={imageHeight}
                  style={{
                    height: `${imageHeight}px`
                  }}
                  alt={locationName}
                  className="mr-2"
                />
              )}
            </>
          )}
          {!showOnlyImage && (
            <span
              className={`${small ? 'small' : ''} ${theme === 'dark' ? 'text-white' : 'text-muted'}`}
            >
              {locationName}
            </span>
          )}
        </div>
      </div>
    );
  }

  return null;
};

export default OrgLocation;
