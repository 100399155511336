const projectState = {
  id: null,
  title: null,
  subtitle: null,
  clientName: null,
  teamName: null,
  orgId: null,
  locationId: null,
  projectSize: null,
  skillIds: null,
  userGroupIds: null,
  userIds: null,
  isFeatured: null,
  addressLine1: null,
  addressLine2: null,
  addressCity: null,
  addressState: null,
  addressPostalCode: null,
  addressCountry: null,
  addressLat: null,
  addressLng: null,
  imageIds: null,
  body: null,
  cdnFiles: null,
  updatedAt: null,
  createdAt: null
};

export default projectState;
