import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { FaCog, FaPlus, FaSortAmountUp } from 'react-icons/fa';
import { withAuthorization } from '../../../../Session';
import { getApprenticeship, resetApprenticeship } from '../../../../../actions/Apprenticeships';
import { setCurrentModal } from '../../../../../actions/Modals';
import { canCreateCompetency } from '../../../../../permissions/create';
import { canManageCompetencyGroup } from '../../../../../permissions/manage';
import withOrgConsole from '../../../../App/withOrgConsole';
import OrgConsoleHeader from '../../../OrgConsoleHeader';
import OrgConsoleSubheader from '../../../OrgConsoleSubheader';
import Tabs from '../Tabs';
import Loading from '../../../../Loading';
import DotMenuCompetencies from './DotMenuCompetencies';
import DotMenu from '../DotMenu';
import CompetenciesList from '../../../../lists/CompetenciesList';
import Collapse from '../../../../ManageContent/Collapse';

const ApprenticeshipCompetencies = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Current User
  const role = currentUser?.role || [];
  // Apprenticeship
  const apprenticeshipId = params?.apprenticeshipId || null;
  const competencies = apprenticeship?.competencies || {};
  const competencyGroups = apprenticeship?.competencyGroups || [];

  const addCompetency = (competencyGroupId) => {
    dispatch(setCurrentModal({
      key: 'manageCompetency',
      data: {
        modalTitle: 'Add Competency',
        competencyGroupId
      }
    }));
  };

  const manageCompetencyGroup = (competencyGroup) => {
    dispatch(setCurrentModal({
      key: 'manageCompetencyGroup',
      data: {
        competencyGroup
      }
    }));
  };

  useEffect(() => {
    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId));
    }

    return function cleanup() {
      const { href } = window.location;
      const apprenticeshipRoute = `org/workforce/apprenticeships/${apprenticeshipId}`;

      // Only reset if not going to apprentice route
      if (!href.includes(apprenticeshipRoute)) {
        dispatch(resetApprenticeship());
      }
    };
  }, [apprenticeshipId, dispatch]);

  if (!apprenticeship.id) {
    return (
      <Loading text="Loading Training Program..." />
    );
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={apprenticeship.title}
            iconComponent={() => <FaSortAmountUp size={20} />}
            // route={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
            className="mb-3"
          >
            <DotMenu />
          </OrgConsoleHeader>

          <Tabs active="competencies" />

          <div
            className="bg-white shadow p-3 overflow-content border-left border-right border-bottom"
          >
            <OrgConsoleSubheader
              pageTitle="Competencies"
              className='mb-3'
            >
              <DotMenuCompetencies />
            </OrgConsoleSubheader>

            {competencyGroups?.length === 0 ? (
              <div>
                No competency groups.
              </div>
            ) : (
              <div>
                {competencyGroups.map((competencyGroup, i) => {
                  const childCompetencies = competencyGroup?.competencyIds?.length ? competencyGroup?.competencyIds.map((cId) => competencies[cId]) : [];

                  return (
                    <Fragment
                      key={`competencyGroup-${i}`}
                    >
                      <Collapse
                        id={`competencyGroup-${competencyGroup?.id}`}
                        title={competencyGroup?.description}
                        className="mb-3"
                        p="0"
                        ariaExpanded={competencyGroups.length === 1}
                      >
                        <CompetenciesList
                          list={childCompetencies}
                          competencyGroupId={competencyGroup?.id}
                          showClassifications
                        />
                        <div className='p-3 d-flex align-items-center justify-content-between'>
                          {canCreateCompetency(role) && (
                            <button
                              className="btn btn-sm btn-white"
                              onClick={() => {
                                addCompetency(competencyGroup?.id);
                              }}
                              type="button"
                              title='Add Competency'
                            >
                              <span className='d-flex align-items-center'>
                                <FaPlus className='mr-2' />
                                <span className='d-none d-sm-inline mr-1'>Add</span>
                                <span>
                                  Competency
                                </span>
                              </span>
                            </button>
                          )}

                          {canManageCompetencyGroup(role) && (
                            <button
                              className="btn btn-sm btn-white"
                              onClick={() => {
                                manageCompetencyGroup(competencyGroup);
                              }}
                              type="button"
                              title='Manage Competency Group'
                            >
                              <span className='d-flex align-items-center'>
                                <FaCog />
                                <span className='ml-2'>
                                  Competency Group
                                </span>
                              </span>
                            </button>
                          )}
                        </div>
                      </Collapse>
                    </Fragment>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ApprenticeshipCompetencies);
