import sysState from './sysState';

const materialState = {
  title: '',
  body: '',
  enableNewEditor: false,
  // id: null,
  // createdAt: null,
  // updatedAt: null,
  userId: null,
  orgId: null,
  classId: null,
  topicId: null,
  integration: null,
  // enableNewEditor: null,
  locationId: null,
  skillIds: null,
  promptText: null,
  promptType: null,
  resourceId: null,
  isDraft: null,
  resourceIds: null,
  fileUploadFlow: null,
  workflowType: null,
  ...sysState
};

export default materialState;
