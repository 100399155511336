import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImageLightBox from '@apprentage/components/dist/components/ImageLightBox';
import { sortArrByArr } from '@apprentage/utils';
import {
  FaArrowAltCircleRight, FaChevronDown, FaChevronUp, FaStar
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { resetProject } from '../../actions/Projects';
import ProjectDetails from '../OrgConsole/Project/ProjectDetails';
import { ORG_CONSOLE_PROJECTS } from '../../constants/routes';
import LearningModulesCard from '../OrgConsole/Project/LearningModulesCard';
import ProjectVideo from '../OrgConsole/Project/ProjectVideo';
import './FeaturedProject.css';

const FeaturedProject = ({
  className = '',
  showHeader = false,
  skipUnmount = false,
  showAllDetails = false,
  showAllProjectsLink = false,
  allowToggleDetails = false
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const project = useSelector((state) => state.project);
  // Organization
  const orgId = organization?.id || '';
  // Project
  const projectVideo = project?.projectVideo || null;
  //
  const [showDetails, setShowDetails] = useState(showAllDetails);

  const classIds = useMemo(() => {
    return project?.classIds || [];
  }, [project?.classIds]);

  const hasLearningModules = useMemo(() => {
    return Array.isArray(classIds) && classIds?.length > 0;
  }, [classIds]);

  const images = useMemo(() => {
    let imgs = [];

    if (project?.cdnFiles) {
      imgs = sortArrByArr(project?.cdnFiles, project?.imageIds, 'id');
    }

    return imgs;
  }, [project?.cdnFiles, project?.imageIds]);

  const handleOpenFirstImageInLightBox = () => {
    const lightBoxImgs = document.querySelectorAll('.ImageLightBox-container .tw-cursor-pointer img');

    lightBoxImgs[0].click();
  };

  useEffect(() => {
    return function cleanup() {
      if (!skipUnmount) {
        dispatch(resetProject());
      }
    };
  }, [dispatch, skipUnmount]);

  if (!project?.id) {
    return;
  }

  return (
    <div className={className}>
      {showHeader && (
        <div className="pb-2 h5 m-0 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <span className="">{project?.title}</span>
          </div>
          {showAllProjectsLink && (
            <Link
              className="btn btn-sm btn-link m-0 p-0"
              to={ORG_CONSOLE_PROJECTS}
            >
              <span className="d-flex align-items-center">
                <span className="mr-2 text-nowrap">All Projects</span>
                <FaArrowAltCircleRight />
              </span>
            </Link>
          )}
        </div>
      )}

      <div
        key={project?.id}
        className="card mb-3"
      >
        {!projectVideo && (
          <div className="position-relative">
            {Array.isArray(images) && images.length > 0 && (
              <img
                src={images[0]?.url}
                className="card-img-top"
                alt={images[0]?.name}
              />
            )}
            <span
              className="ml-2 h6 m-0 position-absolute"
              style={{
                top: '5px',
                right: '5px'
              }}
            >
              <span className="badge bg-dark p-1 text-white d-flex align-items-center">
                <FaStar size={10} />
                <span className="ml-1">Featured</span>
              </span>
            </span>
          </div>
        )}

        {projectVideo && <ProjectVideo projectVideo={projectVideo} />}

        {Array.isArray(images) && images.length > 0 && (
          <div
            className="ImageLightBox-container card-body bg-dark p-2 overflow-hidden"
            style={{
              height: '60px'
            }}
          >
            <div className='d-flex d-sm-none align-items-center'>
              <button
                className='mr-2'
                type='button'
                onClick={handleOpenFirstImageInLightBox}
              >
                <img
                  src={images[0].url}
                  alt={images[0].name || 'project'}
                  height="47"
                  style={{ height: '47px', padding: '.25rem' }}
                />
              </button>

              {images.length > 1 && (
                <button
                  className='font-weight-bold text-white'
                  style={{
                    borderRadius: '100%',
                    fontSize: '1rem',
                    height: '33px',
                    width: '33px',
                    backgroundColor: 'rgba(255,255,255, .25)'
                  }}
                  type='button'
                  onClick={handleOpenFirstImageInLightBox}
                >
                  +{images.length - 1}
                </button>
              )}
            </div>

            <div className='d-none d-sm-block'>
              <ImageLightBox
                showGallery
                pluginSwitch={{
                  captions: true,
                  fullScreen: true,
                  zoom: true,
                  slideshow: true
                }}
                slides={images?.map((imageItem) => ({
                  src: imageItem.url,
                  alt: imageItem.name,
                  title: imageItem.name,
                  description: imageItem.body
                }))}
                captions={{
                  showToggle: true,
                  descriptionTextAlign: 'center',
                  descriptionMaxLines: 4
                }}
              />
            </div>
          </div>
        )}

        <div
          className="card-body pt-2"
        >
          <div className='position-relative'>
            <ProjectDetails
              showAllDetails={showDetails}
            />
            {!showDetails && allowToggleDetails && (
              <button
                className="btn btn-link text-ships-officer d-flex justify-content-center w-100 pt-3 pb-0"
                type="button"
                aria-label="View Project Details"
                onClick={() => {
                  setShowDetails(true);
                  // dispatch(
                  //   setSideSheet({
                  //     key: SHEET_KEY_PROJECT,
                  //     className: 'ProjectSideSheet',
                  //     data: {
                  //       skipUnmount: true
                  //     }
                  //   })
                  // );
                }}
              >
                <FaChevronDown size={30} />
              </button>
            )}
          </div>

          {showDetails && (
            <>
              {hasLearningModules > 0 && (
                <LearningModulesCard
                  className="mt-4"
                  orgId={orgId}
                  classIds={project?.classIds}
                  skipUnmount={skipUnmount}
                />
              )}
            </>
          )}

          {showDetails && allowToggleDetails && (
            <div className='position-relative'>
              <button
                className="btn btn-link text-ships-officer d-flex justify-content-center w-100 pt-3 pb-0"
                type='button'
                aria-label="Hide Project Details"
                onClick={() => {
                  setShowDetails(false);
                }}
              >
                <FaChevronUp size={30} />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeaturedProject;
