import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ImageLightBox from '@apprentage/components/dist/components/ImageLightBox';
import { FaImages, FaStar } from 'react-icons/fa';
import { sortArrByArr } from '@apprentage/utils';
import { withAuthorization } from '../../components/Session';
import { ORG_CONSOLE_PROJECTS } from '../../constants/routes';
import { getProject, resetProject } from '../../actions/Projects';
import withOrgConsole from '../../components/App/withOrgConsole';
import OrgConsoleHeader from '../../components/OrgConsole/OrgConsoleHeader';
import Loading from '../../components/Loading';
import DotMenu from './DotMenu';
import ProjectDetails from '../../components/OrgConsole/Project/ProjectDetails';
import LearningModulesCard from '../../components/OrgConsole/Project/LearningModulesCard';
import ProjectVideo from '../../components/OrgConsole/Project/ProjectVideo';
// import OrgConsoleSubheader from '../OrgConsoleSubheader';

const Project = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  const project = useSelector((state) => state.project);
  // Organization
  const orgId = organization?.id || null;
  // Project
  const projectId = params?.projectId || null;
  const projectVideo = project?.projectVideo || null;
  const classIds = useMemo(() => {
    return project?.classIds || [];
  }, [project?.classIds]);

  const hasLearningModules = useMemo(() => {
    return Array.isArray(classIds) && classIds?.length > 0;
  }, [classIds]);

  const images = useMemo(() => {
    let imgs = [];

    if (
      Array.isArray(project?.cdnFiles)
      && project?.cdnFiles.length > 0
      && Array.isArray(project?.imageIds)
      && project?.imageIds.length > 0
    ) {
      imgs = sortArrByArr(project?.cdnFiles, project?.imageIds, 'id');
    }

    return imgs;
  }, [project?.cdnFiles, project?.imageIds]);

  useEffect(() => {
    if (projectId) {
      dispatch(getProject(projectId))
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        });
    }
  }, [dispatch, projectId]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetProject());
    };
  }, [dispatch]);

  if (projectId && !project?.id) {
    return <Loading text="Loading Project..." />;
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Projects"
            iconComponent={() => <FaImages />}
            route={ORG_CONSOLE_PROJECTS}
          >
            <DotMenu />
          </OrgConsoleHeader>
          <div className="d-flex align-items-center shadow mt-3 rounded rounded-3 bg-light p-2">
            <h4 className="m-0">{project?.title}</h4>
            {project?.isFeatured && (
              <span className="ml-2 h6 m-0">
                <span className="badge badge-primary d-flex align-items-center">
                  <FaStar size={15} />
                  <span className="ml-1">Featured</span>
                </span>
              </span>
            )}
          </div>
          <div className="bg-white border shadow rounded mt-3 p-3">
            <div className="row">
              <div className="col-12 col-md-6">
                {!projectVideo && Array.isArray(images) && images.length > 0 && (
                  <img
                    src={images[0]?.url}
                    className="card-img-top"
                    alt={images[0]?.name}
                  />
                )}

                {projectVideo && (
                  <ProjectVideo
                    projectVideo={projectVideo}
                  />
                )}

                {Array.isArray(images) && images.length > 0 && (
                  <div className="card-body bg-dark py-2 mb-4">
                    <ImageLightBox
                      showGallery
                      pluginSwitch={{
                        captions: true,
                        fullScreen: true,
                        zoom: true,
                        slideshow: true
                      }}
                      slides={images?.map((imageItem) => ({
                        src: imageItem.url,
                        alt: imageItem.name,
                        title: imageItem.name,
                        description: imageItem.body
                      }))}
                      captions={{
                        showToggle: true,
                        descriptionTextAlign: 'center',
                        descriptionMaxLines: 4
                      }}
                    />
                  </div>
                )}

                {hasLearningModules && (
                  <div
                    className="d-none d-md-block"
                  >
                    <LearningModulesCard
                      orgId={orgId}
                      classIds={classIds}
                    />
                  </div>
                )}
              </div>

              <div
                className="col-12 col-md-6 mt-3 mt-md-0"
              >
                <ProjectDetails
                  showAllDetails
                />

                {hasLearningModules > 0 && (
                  <div
                    className="d-block d-md-none"
                  >
                    <LearningModulesCard
                      className='mt-4'
                      orgId={orgId}
                      classIds={classIds}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Project);
