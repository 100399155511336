import React from 'react';
import { FaImages } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import FeaturedProject from '../../Dashboard/FeaturedProject';
import DotMenu from './DotMenu';
import './style.css';

const ProjectSideSheet = ({ className = '' }) => {
  const project = useSelector((state) => state.project);
  const sideSheet = useSelector((state) => state.sideSheet);

  return (
    <div className={`card ${className}`}>
      <div className='card-header d-flex align-items-center justify-content-between'>
        <h6 className="m-0 font-weight-bold">
          <span className="d-flex align-items-center">
            <FaImages />
            <span className="ml-1 text-nowrap">
              {project?.title}
            </span>
          </span>
        </h6>
        <DotMenu projectId={project?.id} />
      </div>
      <div className='card-body overflow-y-scroll'>
        <FeaturedProject
          skipUnmount={sideSheet?.data?.skipUnmount}
          showAllDetails
          showAllProjectsLink
        />
      </div>
    </div>
  );
};

export default ProjectSideSheet;
