import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { resetCurrentModal } from '../../../actions/Modals';
import { resetSearch, setSearchValue } from '../../../actions/Search';
import { SEARCH_CONTENT } from '../../../constants/routes';
import { SEARCH_ADVANCED_FILTERS, SEARCH_CONTENT_TYPES } from '../../../constants/globals';
import Modal from '../../Modal';
import SearchBox from '../../SearchBox';
import SearchFilters from '../../SearchContent/SearchFilters';
import SearchFolders from '../../SearchContent/SearchFolders';

const SearchContent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // redux
  const currentModal = useSelector((state) => state.currentModal);
  const search = useSelector((state) => state.search);
  // misc
  const searchValue = search?.value || null;
  const [loading, setLoading] = useState(false);
  const title = currentModal?.data?.title || 'Search Content';
  const searchGroupIds = currentModal?.data?.searchGroupIds;
  // Search ContentTypes
  const [searchContentTypes, setSearchContentTypes] = useState(
    currentModal?.data?.searchContentTypes || localStorage.getItem('searchContentTypes') || SEARCH_CONTENT_TYPES
  );
  const searchAdvancedFilters = localStorage.getItem('searchAdvancedFilters');
  const searchFilters = searchAdvancedFilters ? JSON.parse(searchAdvancedFilters) : SEARCH_ADVANCED_FILTERS;

  const onChangeSearchTypes = useCallback((contentTypes) => {
    setSearchContentTypes(contentTypes);
  }, [setSearchContentTypes]);

  const searchPlaceholder = useMemo(() => {
    let placeholder = 'Search...';

    if (searchContentTypes.includes('resources')) {
      placeholder = 'Ask or Search...';
    }

    return placeholder;
  }, [searchContentTypes]);

  const sanitizedSearchGroupIds = useMemo(() => {
    let result = null;
    if (Array.isArray(searchGroupIds) && searchGroupIds.length > 0) {
      result = searchGroupIds.pop();

      return [result];
    }

    return result;
  }, [searchGroupIds]);

  const onSubmit = (e) => {
    e.preventDefault();
    const { searchValueInput } = e.currentTarget;

    if (!searchValueInput.value.length) {
      dispatch(resetSearch());
      toast.error('Please enter a search term.');
      return false;
    }

    if (searchValueInput.value.length < 5 && searchValueInput.value.includes('.')) {
      dispatch(resetSearch());
      toast.error('Too broad a query. Try a different search term.');
      return false;
    }

    if (searchContentTypes.length === 0) {
      toast.error('Select at least one content type to search.');
      return;
    }

    setLoading(true);

    dispatch(setSearchValue({
      searchValue: searchValueInput.value.trim(),
      searchGroupIds: sanitizedSearchGroupIds
    })).then(() => {
      let redirectUrl = window.location.pathname;

      if (window.location.search) {
        redirectUrl += window.location.search;
      }

      const urlParams = new URLSearchParams({ redirectUrl });

      history.push(`${SEARCH_CONTENT}?${urlParams.toString()}`);

      dispatch(resetCurrentModal());
    });
  };

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal.visible}
      close={handleClose}
      theme="dark"
    >
      <div className="card">
        <div className="card-header p-3 bg-dark text-white">
          <h6 className="m-0 font-weight-bold">
            {title}
          </h6>
        </div>
        <div
          className="p-3"
        >
          <SearchBox
            placeholder={searchPlaceholder}
            value={searchValue}
            onSubmit={onSubmit}
            loading={loading}
            autoFocus
          />
          <SearchFilters
            expanded
            className='mt-3'
            contentTypes={searchContentTypes}
            filters={searchFilters}
            onChangeSearchTypes={onChangeSearchTypes}
            searchGroupIds={sanitizedSearchGroupIds}
          >
            <SearchFolders
              ids={sanitizedSearchGroupIds}
            />
          </SearchFilters>
        </div>
      </div>
    </Modal>
  );
};

export default SearchContent;
