import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { ICON_TURBINE } from '@apprentage/constants';
import { withAuthorization } from '../../Session';
import { mdySimple } from '../../../utils/date';
import { createCompletion } from '../../../services/openAi';
import copyToClipBoard from '../../../utils/copyToClipBoard';
import InputField from './InputField';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import OrgConsoleHeader from '../OrgConsoleHeader';
import './style.css';

const Assistant = () => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  // Misc
  const searchParams = new URLSearchParams(window.location.search);
  // Search Query
  let searchQuery = searchParams.get('q');
  searchQuery = searchQuery ? searchQuery.replaceAll('+', ' ') : '';

  const searchValue = searchQuery || '';
  const [loading, setLoading] = useState(false);
  const [responses, setResponses] = useState([]);

  const completeText = async (prompt) => {
    setLoading(true);

    setResponses((prevResponses) => {
      return [
        ...prevResponses,
        {
          date: new Date().toISOString(),
          authorName: currentUser?.name,
          message: prompt
        }
      ];
    });

    const response = await createCompletion({
      // model: 'text-davinci-003',
      // prompt: `${prompt}\n`
      prompt
      // temperature: 0.9,
      // max_tokens: 150,
      // top_p: 1,
      // frequency_penalty: 0.0,
      // presence_penalty: 0.6,
      // stop: [' Human:', ' AI:']
    });

    const newResponse = {
      date: new Date().toISOString(),
      postAsCpu: true,
      message: response?.data
    };

    setResponses((prevResponses) => {
      return [
        ...prevResponses,
        newResponse
      ];
    });

    setLoading(false);

    document.getElementById('searchValueInput').focus();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { searchValueInput } = e.currentTarget;

    if (!searchValueInput.value.length) {
      toast.error('Please enter a search term.');
      return false;
    }

    completeText(searchValueInput.value);
  };

  function avatar(text) {
    const textArr = text.split('');

    return textArr[0];
  }

  const copyText = (text) => {
    copyToClipBoard(text);
    toast.success('Copied to clipboard!');
  };

  const handleDefaultPrompt = (prompt) => {
    completeText(prompt);
  };

  useEffect(() => {
    document.body.classList.add('turbine-assistant');

    return function cleanup() {
      document.body.classList.remove('turbine-assistant');
    };
  }, []);

  const defaultPrompt1 = 'Write a cold email to Mrs Johnson that introduces our apprenticeship program starting on March 8.';
  const defaultPrompt2 = 'What business or industries is Mechatronics used for?';
  const defaultPrompt3 = 'Brainstorm 3 ideas combining VR and manufacturing for the March 8 Job fair at the local community college:';
  const defaultPrompt4 = 'What is TikTok and why is it so popular?';

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mt-3 mb-5">
          <OrgConsoleHeader
            pageTitle={loading ? 'Processing...' : 'AI Assistant'}
            icon={ICON_TURBINE}
            theme="dark"
          />

          {responses.length === 0 && (
            <div className="mt-3 p-3">
              <div className="row">
                <div className="col-6 col-sm-3 mb-3">
                  <DefaultPromptOption
                    className={`p-3 rounded ${loading ? 'pointer-events-none' : ''}`}
                    onClick={() => {
                      handleDefaultPrompt(defaultPrompt1);
                    }}
                  >
                    {defaultPrompt1}
                  </DefaultPromptOption>
                </div>
                <div className="col-6 col-sm-3 mb-3">
                  <DefaultPromptOption
                    className={`p-3 rounded ${loading ? 'pointer-events-none' : ''}`}
                    onClick={() => {
                      handleDefaultPrompt(defaultPrompt2);
                    }}
                  >
                    {defaultPrompt2}
                  </DefaultPromptOption>
                </div>
                <div className="col-6 col-sm-3 mb-3">
                  <DefaultPromptOption
                    className={`p-3 rounded ${loading ? 'pointer-events-none' : ''}`}
                    onClick={() => {
                      handleDefaultPrompt(defaultPrompt3);
                    }}
                  >
                    {defaultPrompt3}
                  </DefaultPromptOption>
                </div>

                <div className="col-6 col-sm-3 mb-3">
                  <DefaultPromptOption
                    className={`p-3 rounded ${loading ? 'pointer-events-none' : ''}`}
                    onClick={() => {
                      handleDefaultPrompt(defaultPrompt4);
                    }}
                  >
                    {defaultPrompt4}
                  </DefaultPromptOption>
                </div>
              </div>
            </div>
          )}

          {responses.length !== 0 && (
            <div className="card-body border bg-white mt-3">
              <div className="d-flex flex-column">
                {responses.map((data) => {
                  const {
                    date, postAsCpu = false, authorName, message
                  } = data;
                  return (
                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center">
                        {postAsCpu ? (
                          <MessageAvatar className="d-flex align-items-center justify-content-center border bg-dark text-white">
                            <i className={ICON_TURBINE} />
                          </MessageAvatar>
                        ) : (
                          <MessageAvatar className="d-flex align-items-center justify-content-center border bg-light">
                            {avatar(authorName)}
                          </MessageAvatar>
                        )}
                      </div>
                      <div className="ml-2">
                        <MessageContainer
                          data-demo="disabled"
                          className="notification-message-container m-0"
                        >
                          <RenderMarkdown source={message} />
                        </MessageContainer>

                        <div className="text-muted">
                          <span>{postAsCpu ? 'Turbine' : authorName} • </span>
                          <span>{mdySimple(date)} </span>
                          {postAsCpu && (
                            <span>
                              <span>•</span>
                              <button
                                className="btn-link ml-1"
                                type="button"
                                onClick={() => {
                                  copyText(message);
                                }}
                              >
                                Copy
                              </button>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <InputField
            value={searchValue}
            className="mt-3 rounded"
            onSubmit={onSubmit}
            autoFocus
            loading={loading}
            placeholder={loading ? 'Processing...' : ''}
          />
        </div>
      </div>
    </div>
  );
};

const MessageContainer = styled.div`
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const MessageAvatar = styled.div`
  border-radius: 100%;
  font-family: 'Arial Bold', sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  height: 40px;
  width: 40px;
`;

const DefaultPromptOption = styled.div`
  align-items: center;
  background-color: #3e3f4b;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
  min-height: 130px;
  width: 100%;

  &:hover {
    background-color: #202123;
  }
`;

const condition = (user) => !!user.uid;

export default withAuthorization(condition)(Assistant);
