import { toast } from 'react-toastify';
import { isLocalhost } from '../serviceWorker';
import { client, flattenItem, flattenItems } from './contentful';
import {
  PLATFORM_NAME,
  TURBINE_ADMIN_BCC_EMAILS,
  TURBINE_ADMIN_EMAIL
} from '../constants/globals';
import * as twApi from './tw-api';
import formatData from './formatData';
import { updateEntry } from './entry';
import { dateTimestamp } from '../utils/date';
import isPublished from '../utils/isPublished';
import { GMAIL_SEND } from '../constants/api';
import { TURBINE_MARKETING_SITE } from '../constants/urls';
import { SLASH } from '../constants/routes';

export const entryOrgMatchesUserOrg = (
  entryOrgId,
  userOrgId,
  routeToRedirectTo = SLASH
) => {
  if (entryOrgId === userOrgId) {
    return true;
  }

  // User was logged in to Organization A
  // and was viewing resources so there were groupIds in the URL params
  // User logged out, the current URL w/params was set as continueUrl
  // User logged into Organization B
  // the groupIds were used to fetch groups which have orgId = Organization A
  // Redirect User to Resources Home
  console.error('Organization mismatch');
  window.location.assign(routeToRedirectTo);

  return false;
};

export const fetchOrg = ({
  orgId, slug, select, include
}) => {
  const config = {
    content_type: 'organization',
    include
  };

  if (orgId) {
    config['sys.id'] = orgId;
  } else if (slug) {
    config['fields.slug'] = slug;
  }

  if (select) {
    config.select = select.join(',');
  }

  return new Promise((resolve, reject) => {
    client
      .getEntries(config)
      .then(({ items }) => {
        const organization = items[0] !== undefined ? flattenItem(items[0]) : null;

        resolve(organization);
      })
      .catch((error) => {
        console.error(error.message);
        reject(error);
      });
  });
};

export const fetchOrgs = ({
  select = ['fields.name', 'fields.orgLogo'],
  searchValue,
  orgId,
  orgIds
}) => {
  const config = {
    content_type: 'organization',
    select: select.join(',')
  };

  if (searchValue) {
    config['fields.name[match]'] = searchValue;
  }

  if (orgIds) {
    config['sys.id[in]'] = orgIds.join(',');
  }

  return new Promise((resolve, reject) => {
    client
      .getEntries(config)
      .then(({ items }) => {
        const response = items ? flattenItems(items) : [];
        const organizations = orgId
          ? response.filter((o) => o.id !== orgId)
          : response;

        resolve(organizations);
      })
      .catch((error) => {
        console.error(error.message);
        reject(error);
      });
  });
};

export const routeWithOrgId = ({
  route,
  orgId,
  urlParams
}) => {
  const params = {
    ...(orgId ? { orgId } : {}),
    ...(urlParams || {})
  };

  if (params?.orgId) {
    delete params.orgId;
  }

  const searchParams = new URLSearchParams(params);

  return `${route}?${searchParams.toString()}`;
};

export const updateOrganization = (data, id) => {
  return new Promise((resolve, reject) => {
    const preppedData = formatData(data, 'organization');

    updateEntry(preppedData, id).then((updatedOrg) => {
      setTimeout(() => {
        resolve(updatedOrg);
      }, 250);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const setUserAppVersion = (appVersion) => {
  window.localStorage?.setItem('turbine_admin', appVersion);
};

export const getUserAppVersion = () => {
  return window.localStorage?.getItem('turbine_admin');
};

export const setUserLoggedIn = () => {
  window.localStorage?.setItem('turbine_auth_active', dateTimestamp());
};

export const getUserLoggedIn = () => {
  return window.localStorage?.getItem('turbine_auth_active');
};

export const rmUserLoggedIn = () => {
  window.localStorage?.removeItem('turbine_auth_active');
};

export const usingLatestAppVersion = (appVersion) => {
  const versionFromBrowser = getUserAppVersion();

  if (!versionFromBrowser && appVersion) {
    setUserAppVersion(appVersion);
    return true;
  }

  if (versionFromBrowser && appVersion === versionFromBrowser) {
    return true;
  }

  return false;
};

/**
 * @summary Emails Turbine Admins
 * @param {string} subject // 'Billing Question: [orgName]
 * @param {string} message // message
 * @param {string} fromName // User requesting support
 * @param {string} replyTo // User requesting support's email
 * @returns {Promise<string>} emails, link
 */
export const notifyTurbineSupport = ({
  subject,
  message,
  replyTo,
  fromName
}) => {
  return new Promise((resolve, reject) => {
    const to = TURBINE_ADMIN_EMAIL;
    const emailParts = {
      to,
      from: {
        name: fromName
      },
      replyTo,
      bcc: TURBINE_ADMIN_BCC_EMAILS,
      subject,
      body: '',
      htmlBody: `
        <p>Turbine Workforce Admin,</p>
        <p>${message}</p>
        <br />
        <p>
          Best,
          <br />
          ${fromName}
        </p>
        <font color='#888888'>--</font>
        <br>
        Powered by <a href='${TURBINE_MARKETING_SITE}?track=sendEmail' target='_blank' title='${PLATFORM_NAME}'>${PLATFORM_NAME}</a>
      `
    };

    return twApi
      .post(GMAIL_SEND, '', JSON.stringify(emailParts))
      .then((response) => {
        if (response && response.status === 200) {
          resolve();
        } else {
          toast.error(response.message); // ex: 'Something went wrong, try again'
          console.error(response.status); // ex: 'error_invitation_fail'

          reject({
            message: response.message,
            status: response.status
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  });
};

export const getStripeCustomerId = (organization) => {
  if (!isPublished(organization)) return null;

  const { integration } = organization;
  if (!integration) return null;

  let customerId = null;

  if (integration && integration.stripe && integration.stripe.customer_id) {
    if (integration.stripe.customer_id[isLocalhost ? 'test' : 'live']) {
      customerId = integration.stripe.customer_id[isLocalhost ? 'test' : 'live'];
    }
  }

  return customerId;
};
