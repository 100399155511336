import React from 'react';
import { percentComplete } from '@apprentage/utils';
import { useSelector } from 'react-redux';
import ProgressBars from '../../ProgressBars';
import TimeBadge from '../../TimeBadge';

const CompetenceProgress = ({ totalHours, competencyId }) => {
  // Redux
  const logbook = useSelector((state) => state.logbook);
  const logbookHours = useSelector((state) => state.logbookHours);
  // Logbook
  const completedCompetencies = logbook?.completedCompetencies || {};
  const competencyIsComplete = completedCompetencies[competencyId] !== undefined;

  const hoursEarned = logbookHours?.competencyHours ? logbookHours?.competencyHours[competencyId] : 0;

  const percentage = percentComplete({
    current: hoursEarned,
    total: totalHours
  });

  return (
    <>
      <div className="d-flex justify-content-between mt-3 mb-2">
        <div className="small">
          <span className="mr-1">Hours Accrued</span>
          <TimeBadge
            badgeType="bg-light border"
            hours={hoursEarned}
          />
        </div>
        <div className="small">
          Target Hours
          <TimeBadge
            hours={totalHours}
            badgeType="bg-light border"
          />
        </div>
      </div>
      <div className="border-top pt-2">
        <ProgressBars
          size="sm"
          data={[
            {
              dataTip: 'Hours Accrued',
              className: `progress-bar ${competencyIsComplete ? 'badge-success' : 'bg-primary'}`,
              valueNow: hoursEarned,
              style: { width: percentage },
              text: `${hoursEarned} / ${totalHours}`
            }
          ]}
        />
      </div>
    </>
  );
};

export default CompetenceProgress;
