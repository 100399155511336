import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { SLASH } from '../../../constants/routes';
import { redirectToAuth } from '../../../services/auth';
import Loading from '../../Loading';

class Login extends Component {
  render() {
    const { match, user, currentUser } = this.props;

    if (user && currentUser) {
      return <Redirect to={SLASH} />;
    }

    if (match && match.params && match.params.organizationSlug) {
      redirectToAuth({
        organizationSlug: match.params.organizationSlug
      });
    } else {
      redirectToAuth({});
    }

    return (
      <Loading />
    );
  }
}

const mapStateToProps = ({ user, currentUser }) => ({ user, currentUser });

export default connect(mapStateToProps)(Login);
