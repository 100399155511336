import React from 'react';

const Color = ({ handleClick, color, activeColor }) => {
  return (
    <button
      className="ColorSwatches--color"
      data-hex={color.hex}
      style={{ backgroundColor: color }}
      type="button"
      onClick={() => {
        handleClick(color);
      }}
    >
      {activeColor && activeColor === color && (
        <i className="fas fa-check text-white" />
      )}
    </button>
  );
};

export default Color;
