import React from 'react';
import Table from './Table';
import DescriptionCell from './DescriptionCell';

const List = ({
  data,
  editItem,
  handleClickRow,
  showClassifications
}) => {
  const columns = React.useMemo(() => [
    {
      Header: 'Competency',
      accessor: 'description',
      disableSortBy: true,
      Cell: (props) => (
        <DescriptionCell
          {...props}
          showClassifications={showClassifications}
        />
      )
    },
    {
      Header: 'Target Hours',
      accessor: 'totalHours',
      disableSortBy: true
    }
  ], [editItem]);

  if (!data || (data && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">
          No Work Processes.
        </div>
      </div>
    );
  }

  return (
    <Table
      handleClickRow={handleClickRow}
      columns={columns}
      data={data}
    />
  );
};

export default List;
