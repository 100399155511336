import React, { useMemo } from 'react';
import { ICON_CHECK_CIRCLE, ICON_INFO_CIRCLE } from '@apprentage/components/dist/constants/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'flagsmith/react';
import {
  FLAG_LOGBOOKS_READONLY,
  NOTICE_LOGBOOKS_READONLY_TITLE,
  NOTICE_MAINTENANCE_MODE_BODY
} from '../../../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../../actions/FeatureFlags';
import { setCurrentModal } from '../../../../actions/Modals';
import { canReviewCompetence } from '../../../../permissions/manage';
import { mdySimple } from '../../../../utils/date';
import makeInitials from '../../../../utils/makeInitials';

// Field Training - Advisor has provided training and demonstration of task to the trainee.
// Demonstrates Fundamentals - Apprentice can perform the task with some coaching.
// Proficient in Task - Apprentice performs task properly and consistently.
// Completion Date - Date trainee completes final demonstration of competency.

const DateCompletedCell = ({ row }) => {
  const dispatch = useDispatch();
  const flags = useFlags([FLAG_LOGBOOKS_READONLY]);
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const logbook = useSelector((state) => state.logbook);
  const logbookHours = useSelector((state) => state.logbookHours);

  // Current User
  const userId = currentUser?.id || '';
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  // Logbook
  const completedCompetencies = logbook?.completedCompetencies || {};
  // Apprenticeship User
  const apprenticeshipUser = apprenticeship?.user || null;
  const apprenticeshipUserSupervisorIds = useMemo(() => {
    return apprenticeshipUser?.supervisorIds || [];
  }, [apprenticeshipUser?.supervisorIds]);
  const competencyId = row?.original?.id || '';
  const totalHours = row?.original?.totalHours || 0;
  const hoursEarned = useMemo(() => {
    if (logbookHours?.competencyHours) {
      if (logbookHours?.competencyHours[competencyId] !== undefined) {
        return logbookHours?.competencyHours[competencyId];
      }
    }

    return 0;
  }, [competencyId, logbookHours?.competencyHours]);

  const reviewCompetency = useMemo(() => {
    return canReviewCompetence(
      role,
      totalHours,
      hoursEarned,
      userId,
      apprenticeshipUserSupervisorIds
    );
  }, [role, totalHours, hoursEarned, userId, apprenticeshipUserSupervisorIds]);

  // let dateCompleted = '';

  const handleMakeInitials = () => {
    if (flags?.logbooks_readonly?.enabled && flags?.logbooks_readonly?.value) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_LOGBOOKS_READONLY_TITLE,
        modalBody: NOTICE_MAINTENANCE_MODE_BODY
        // preventClose: true
      }));
      return;
    }

    dispatch(
      setCurrentModal({
        key: 'competencyInitial',
        data: {
          competency: row?.original,
          hoursEarned,
          apprenticeshipUser: {
            firstName: apprenticeshipUser?.firstName,
            lastName: apprenticeshipUser?.lastName
          }
        }
      })
    );
  };

  // if (recordsCompetencyDateCompletedEnum[competencyId] !== undefined) {
  //   dateCompleted = recordsCompetencyDateCompletedEnum[competencyId];
  // }

  // Finish this logic and display date with DayJS

  if (completedCompetencies[competencyId] !== undefined) {
    const completedCompetency = completedCompetencies[competencyId];
    const initials = makeInitials(completedCompetency.approvedByName);

    return (
      <>
        <div className="btn btn-sm badge-success pointer-events-none mr-2">
          <i className={ICON_CHECK_CIRCLE} />
          <span className="ml-2">Approved</span>
        </div>

        <div className="mt-2 text-nowrap">
          <div
            className="small mb-1"
            // data-tooltip-html={`<div>Approved by: <br /> ${completedCompetency?.approvedByName}</div>`}
            // data-tooltip-id="UserCompetenciesList"
            data-tip={`<div>Approved by: <br /> ${completedCompetency?.approvedByName}</div>`}
            data-for="UserCompetenciesList"
          >
            Approved by
            <span className="ml-1 h6 m-0">
              <span className="badge badge-bg-light border">
                {initials}
                <i className={`${ICON_INFO_CIRCLE} opacity-50 ml-1`} />
              </span>
            </span>
            <span className="ml-1 h6 m-0">
              <span className="badge badge-bg-light border">
                {mdySimple(completedCompetency?.approvedAt, '-')}
              </span>
            </span>
          </div>
          <div className="small">
            <span className="mr-1">Hours Approved</span>
            <span className="h6 m-0">
              <span className="badge badge-success">
                {completedCompetency?.approvedHours}
              </span>
            </span>
          </div>
        </div>
      </>
    );
  }

  if (!reviewCompetency) {
    return (
      <>
        <div
          className="btn btn-white btn-sm text-nowrap"
          // data-tooltip-content="User needs to accrue more hours"
          // data-tooltip-id="UserCompetenciesList"
          data-tip="User needs to accrue more hours"
          data-for="UserCompetenciesList"
        >
          <i className={`${ICON_CHECK_CIRCLE} mr-1 text-muted`} /> Review
        </div>
      </>
    );
  }

  return (
    <button
      onClick={handleMakeInitials}
      className="btn btn-outline-primary btn-sm text-nowrap"
      type="button"
    >
      <i className={`${ICON_CHECK_CIRCLE} mr-1`} /> Review
    </button>
  );

  // return (
  //   <div data-id={value}>
  //     {dateCompleted}
  //   </div>
  // );
};

export default DateCompletedCell;
