import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { FaImages, FaLayerGroup, FaUsers } from 'react-icons/fa';
import pluralize from 'pluralize';
import { canDeleteEntry } from '../../../services/currentUser';
import DeleteContent from '.';
import TaskStatus from './TaskStatus';
import { checkSkillAssociations, deleteSkill } from '../../../services/skills';
import { getSkills } from '../../../actions/Skills';
import { resetCurrentModal } from '../../../actions/Modals';

const DeleteSkill = ({
  className = '',
  contentId,
  title,
  contentType = 'skills',
  buttonText = 'Delete Skill'
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgId = organization?.id || null;
  // Local State
  const [loading, setLoading] = useState(true);
  const [numProjects, setNumProjects] = useState(null);
  const [numUsers, setNumUsers] = useState(null);
  const [numCourses, setNumCourses] = useState(null);
  const [numChallenges, setNumChallenges] = useState(null);
  const [numQuizzes, setNumQuizzes] = useState(null);

  // Current User
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);

  const canDelete = useMemo(() => {
    if (!canDeleteEntry(role) || loading) {
      return false;
    }

    if (numProjects > 0) {
      return false;
    }

    if (numUsers > 0) {
      return false;
    }

    if (numCourses > 0) {
      return false;
    }

    if (numChallenges > 0) {
      return false;
    }

    if (numQuizzes > 0) {
      return false;
    }

    return true;
  }, [loading, numChallenges, numCourses, numProjects, numQuizzes, numUsers, role]);

  useEffect(() => {
    $('#collapse-deleteContentHowTo').collapse('hide');

    checkSkillAssociations({
      skillIds: [contentId],
      orgId
    }).then((responses) => {
      if (Array.isArray(responses)) {
        responses.forEach((response) => {
          if (response?.users !== undefined) {
            setNumUsers(Array.isArray(response?.users) ? response.users.length : 0);
          }

          if (response?.projects !== undefined) {
            setNumProjects(Array.isArray(response?.projects) ? response.projects.length : 0);
          }

          if (response?.courses !== undefined) {
            setNumCourses(Array.isArray(response?.courses) ? response.courses.length : 0);
          }

          if (response?.quizzes !== undefined) {
            setNumQuizzes(Array.isArray(response?.quizzes) ? response.quizzes.length : 0);
          }

          if (response?.challenges !== undefined) {
            setNumChallenges(Array.isArray(response?.challenges) ? response.challenges.length : 0);
          }
        });
      }

      setLoading(false);
    });
  }, [contentId, orgId]);

  const handleDelete = () => {
    setLoading(true);
    toast.info('Processing...', { toastId: 'deleteSkillInfo' });

    deleteSkill(contentId).then(() => {
      dispatch(getSkills({ orgId })).then(() => {
        toast.dismiss('deleteSkillInfo');
        toast.success(`${title} Deleted!`);
        dispatch(resetCurrentModal());
      });
    }).catch((error) => {
      console.error(`delete Entry: ${contentType}`, error);
    });
  };

  return (
    <DeleteContent
      id={contentId}
      title={title}
      contentType={contentType}
      buttonText={buttonText}
      handleDelete={handleDelete}
      showDeleteButton={canDelete}
      loading={loading}
      className={className}
    >
      <h6 className='my-3'>
        Disassociate Skill from...
      </h6>
      <ol className="m-0 px-4">
        <li className="mb-2 d-flex align-items-center">
          <FaImages />
          <span className='mx-2'>
            {numProjects > 0 ? pluralize('Project', numProjects, true) : 'Projects'}
          </span>
          <TaskStatus statusType="projects" complete={numProjects === 0} />
        </li>
        <li className="mb-2 d-flex align-items-center">
          <FaUsers />
          <span className='mx-2'>
            {numUsers > 0 ? pluralize('User', numUsers, true) : 'Users'}
          </span>
          <TaskStatus statusType="users" complete={numUsers === 0} />
        </li>
      </ol>
      <div className='bg-light p-3 rounded pl-2'>
        <div className='font-weight-bold pl-2 mb-2'>
          <span className='d-flex align-items-center'>
            <FaLayerGroup />
            <span className='ml-2'>
              Courses
            </span>
          </span>
        </div>
        <ol className="m-0 px-4">
          <li className="mb-2 d-flex align-items-center">
            <span className='mx-2'>
              {pluralize('Course', numCourses || 0, true)}
            </span>
            <TaskStatus statusType="courses" complete={numCourses === 0} />
          </li>
          <li className="mb-2 d-flex align-items-center">
            <span className='mx-2'>
              {numChallenges > 0 ? pluralize('Challenge', numChallenges, true) : 'Challenges'}
            </span>
            <TaskStatus statusType="challenges" complete={numChallenges === 0} />
          </li>
          <li className="mb-2 d-flex align-items-center">
            <span className='mx-2'>
              {numQuizzes > 0 ? pluralize('Quiz', numQuizzes, true) : 'Quizzes'}
            </span>
            <TaskStatus statusType="quizzes" complete={numQuizzes === 0} />
          </li>
        </ol>
      </div>

    </DeleteContent>
  );
};

export default DeleteSkill;
