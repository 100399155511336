export const goToElem = (id = '') => {
  const elem = document.getElementById(id);

  if (elem) {
    elem.scrollIntoView();
  }
};

export const scrollElem = (id, scrollTop = 0) => {
  const elem = document.getElementById(id);

  if (elem) {
    document.getElementById(id).scrollTop = scrollTop;
  }
};
