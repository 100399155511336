export const IMAGE_TOOLKIT_EMPTY = 'https://turbine-content.s3.us-east-2.amazonaws.com/toolkit-empty.png';
// MOVE FILES
export const IMAGE_MOVE_FILE_LEFT = 'https://turbine-content.s3.us-east-2.amazonaws.com/move-file--left.jpg';
export const IMAGE_MOVE_FILE_CENTER_ARROWS = 'https://turbine-content.s3.us-east-2.amazonaws.com/move-file--center-arrows.jpg';
export const IMAGE_MOVE_FILE_CENTER_ARROW_LEFT = 'https://turbine-content.s3.us-east-2.amazonaws.com/move-file--center-arrow-left.jpg';
export const IMAGE_MOVE_FILE_CENTER_ARROW_RIGHT = 'https://turbine-content.s3.us-east-2.amazonaws.com/move-file--center-arrow-right.jpg';
export const IMAGE_MOVE_FILE_RIGHT = 'https://turbine-content.s3.us-east-2.amazonaws.com/move-file--right.jpg';
// INTEGRATIONS
export const DUPLICATE_ICON = 'https://turbine-content.s3.us-east-2.amazonaws.com/duplicate-1x.png';
// CARDS
export const IMAGE_CARD_DEFAULT = 'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-image-default.png';
export const IMAGE_CARD_EMPLOYERS = 'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-image-employers.png';
export const IMAGE_CARD_JOB_PROFILES = 'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-image-job-profile.png';
export const IMAGE_CARD_INSTRUCTOR_PROVIDERS = 'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-image-instruction-provider.png';
export const IMAGE_CARD_LOGBOOKS = 'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-image-logbook.png';
export const IMAGE_CARD_PATHWAYS = 'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-image-pathways.png';
export const IMAGE_CARD_RESOURCES = 'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-image-resources.png';
export const IMAGE_CARD_TRAINING_PROGRAMS = 'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-image-training-program.png';

export const IMAGE_CARD_WIDGETS = 'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-widgets.png';
export const IMAGE_CARD_USERS = 'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-users.png';
export const IMAGE_CARD_SKILLS = 'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-skills.png';
export const IMAGE_CARD_PROJECTS = 'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-projects.png';
export const IMAGE_CARD_COURSES = 'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-courses.png';
export const IMAGE_CARD_CHALLENGES = 'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-challenges.png';

export const IMAGE_BUILD_OWN_TRAININGS = 'https://turbine-content.s3.us-east-2.amazonaws.com/training-program-create-build-1x.png';
export const IMAGE_CLONE_SUCCESSFUL_TRAININGS = 'https://turbine-content.s3.us-east-2.amazonaws.com/training-program-create-clone-1x.png';
export const IMAGE_NO_DOCUMENTS = 'https://turbine-content.s3.us-east-2.amazonaws.com/document-agreement-sign-icon.png';
