import React, { useMemo } from 'react';
import { FaImage, FaStar } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { constructWidgetUrl } from '../../../../services/widgets';

const WidgetLink = ({
  className = '',
  id = null,
  image = null,
  title = 'Widget',
  body = null,
  height = '40',
  width = '40',
  linkTarget,
  url,
  isFeatured = false,
  includeEmail = null,
  includeExternalUserId = null,
  onClickSettings = undefined,
  showFeaturedIcons = false
}) => {
  const currentUser = useSelector((state) => state.currentUser);
  const widgetUrl = useMemo(() => {
    return constructWidgetUrl({
      email: currentUser?.email || null,
      externalUserId: currentUser?.externalUserId || null,
      includeEmail,
      includeExternalUserId,
      url
    });
  }, [
    currentUser?.email,
    currentUser?.externalUserId,
    includeEmail,
    includeExternalUserId,
    url
  ]);

  return (
    <div className="col-sm-12 col-md-6 mb-3">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center w-100">
          <div className="border shadow bg-white rounded d-block mr-2 position-relative">
            {image ? (
              <div
                className="p-2"
                style={{
                  height: `${height}px`,
                  width: `${width}px`
                }}
              >
                <img
                  className="d-block w-100 h-100"
                  src={image}
                  alt={title}
                  height={height}
                  width={width}
                />
              </div>
            ) : (
              <FaImage
                size={height}
                className="p-2"
              />
            )}
            {isFeatured && showFeaturedIcons && (
              <div
                className="position-absolute widget-featured-marker"
              >
                <FaStar
                  className='text-primary opacity-50'
                  size={12}
                />
              </div>
            )}
          </div>
          <div
            className="w-100 pb-2 d-flex align-items-center justify-content-between flex-auto"
            style={{ borderBottom: '1px solid rgba(0, 0, 0, .5)' }}
          >
            <div>
              <div className="font-weight-bold widget-listView-title">
                {title}
              </div>
              <div className="widget-listView-body">{body}</div>
            </div>
            <div className="d-flex flex-column">
              <a
                className={`${className} mx-1 btn btn-sm btn-white py-1`}
                target={linkTarget}
                rel="noopener noreferrer"
                href={widgetUrl}
                title={title}
                style={{
                  minWidth: '70px',
                  borderRadius: '1rem',
                  fontSize: '.75rem'
                }}
              >
                GO
              </a>

              {Boolean(onClickSettings) && (
                <button
                  className="btn-link mt-1"
                  type="button"
                  onClick={() => {
                    onClickSettings({
                      id,
                      image,
                      title,
                      body,
                      linkTarget,
                      isFeatured,
                      url,
                      includeEmail,
                      includeExternalUserId
                    });
                  }}
                >
                  Settings
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetLink;
