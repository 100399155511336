import React, { Component } from 'react';
import { FaTimes } from 'react-icons/fa';
import classNames from 'classnames';
import ModalDialog from './ModalDialog';
import './style.css';

class Modal extends Component {
  static Dialog = ModalDialog;

  render() {
    const {
      cssClassName,
      className,
      children,
      visible,
      close,
      size,
      preventCloseDialog,
      style,
      pos = 'top',
      theme = 'light'
    } = this.props;

    const modalClassesData = {
      'turbine-modal': true,
      [size]: size,
      [pos]: pos,
      [`modal-${theme}`]: true
    };

    const modalClasses = classNames(modalClassesData);

    if (className) {
      modalClasses[className] = true;
    }

    if (!visible) return null;

    return (
      <>
        <div
          className={`${modalClasses} ${cssClassName || ''}`}
          onMouseDown={(e) => {
            e.stopPropagation();
            const { classList } = e.target;
            if (classList.contains('turbine-modal') || classList.contains('modal-container')) {
              if (close) close();
            }
          }}
        >
          <div className='modal-container'>
            <div className='modal-content' style={style}>
              {close && (
                <button
                  title='Close'
                  aria-label='Close'
                  type='button'
                  onClick={close || null}
                  className={`close ${theme === 'light' ? 'text-ships-officer' : 'text-white'}`}
                >
                  <FaTimes />
                </button>
              )}
              {children}
            </div>
          </div>
        </div>
        {preventCloseDialog && <ModalDialog {...preventCloseDialog} />}
      </>
    );
  }
}

export default Modal;
