const generateLocationsHash = (arr) => {
  const hash = {};

  if (!arr) return hash;

  arr.forEach((arrElem) => {
    hash[arrElem.id] = {
      ...arrElem
    };
  });

  return hash;
};

export default generateLocationsHash;
