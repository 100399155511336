import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { ICON_USERS } from '@apprentage/components/dist/constants/icons';
import { useHistory, useParams } from 'react-router-dom';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import { routeWithOrgId } from '../../../../../services/organizations';
import UsersList from '../../../../UsersList';

const TraineesBlock = ({
  title = 'Trainees',
  icon = ICON_USERS,
  className = 'card mt-3'
}) => {
  const history = useHistory();
  const params = useParams();
  // redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Organization
  const orgId = pathOr(null, ['id'], organization);
  // const currentUserRole = pathOr([], ['role'], currentUser);
  // Apprenticeship
  const apprenticeshipId = pathOr(null, ['apprenticeshipId'], params);
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;
  // Apprenticeship User
  const apprenticeshipUser = pathOr(null, ['user'], apprenticeship);
  const apprenticeshipUserId = pathOr('', ['id'], apprenticeshipUser);
  const apprenticeshipUserSupervisorIds = pathOr(
    [],
    ['supervisorIds'],
    apprenticeshipUser
  );

  const handleClickRow = (clickedUser) => {
    const route = `${apprenticeshipRoute}/users/${clickedUser.id}`;

    history.push(
      routeWithOrgId({
        route,
        orgId
      })
    );
  };

  const hiddenColumns = ['locationId', 'remindersCell'];

  return (
    <div className={className}>
      <div className="card-header">
        <i className={`${icon} mr-1`} /> {title}
      </div>

      <UsersList
        key={apprenticeshipUserSupervisorIds.join(',')}
        supervisorIds={[apprenticeshipUserId]}
        apprenticeshipId={apprenticeshipId}
        handleClickRow={handleClickRow}
        hiddenColumns={hiddenColumns}
        excludeTestUsers
        excludeInactive
      />
    </div>
  );
};

export default TraineesBlock;
