import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { FaImages } from 'react-icons/fa';
import SearchBox from '@apprentage/components/dist/components/SearchBox';
import { withAuthorization } from '../../Session';
import { ORG_CONSOLE_PROJECT_BASE, ORG_CONSOLE_DASHBOARD } from '../../../constants/routes';
import { PROJECTS_PAGINATION_LIMIT } from '../../../constants/api';
import { goToElem } from '../../../services/manageContent';
import withOrgConsole from '../../App/withOrgConsole';
import OrgConsoleHeader from '../OrgConsoleHeader';
import ProjectsList from '../../lists/ProjectsList';
import PaginationSupabase from '../../ManageContent/PaginationSupabase';
import ProjectsTabs from '../../tabs/ProjectsTabs';

const Projects = () => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const projects = useSelector((state) => state.projects);
  // Organization
  const orgId = organization?.id || '';
  // Pagination
  const initialListConfig = {
    orgId,
    page: 1,
    isFeatured: null,
    limit: PROJECTS_PAGINATION_LIMIT,
    title: ''
  };
  // Local State
  const [listConfig, setListConfig] = useState(initialListConfig);

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Projects"
            iconComponent={() => <FaImages />}
            route={ORG_CONSOLE_DASHBOARD}
          >
            <Link
              type="button"
              className="btn btn-sm btn-primary"
              to={`${ORG_CONSOLE_PROJECT_BASE}/manage`}
            >
              Add Project
            </Link>
          </OrgConsoleHeader>

          <ProjectsTabs
            listConfig={listConfig}
            setListConfig={setListConfig}
            className="pt-3"
          />

          <div className="bg-white border-right border-left border-bottom shadow p-3 overflow-content">
            <SearchBox
              className="w-100 py-3"
              onSubmit={(value) => {
                setListConfig((prevState) => ({
                  ...prevState,
                  title: value
                }));
              }}
              name="searchProject"
              placeholder="Search project by title..."
              autoFocus
            />
            <ProjectsList
              listConfig={listConfig}
              showLearningModulesCol
              showSkillsCol
              showCreatedAtCol
            />

            {Array.isArray(projects.list) && projects.list.length > 0 && (
              <PaginationSupabase
                items={projects?.list}
                pagination={projects?.pagination}
                page={projects?.pagination?.page}
                onClickNext={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: projects?.pagination?.page + 1
                  }));
                  goToElem('root');
                }}
                onClickPrev={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: projects?.pagination?.page - 1
                  }));
                  goToElem('root');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withOrgConsole)(Projects);
