import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaEllipsisH, FaPaperPlane } from 'react-icons/fa';
import { canEditLocation } from '../../services/currentUser';
import { setCurrentModal } from '../../actions/Modals';
import KeyValueItem from '../KeyValueItem';

const ActionsCell = ({
  locationId,
  row
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  // Misc
  const role = currentUser.role || [];
  const manageLocation = canEditLocation(role);
  // Original Invitation
  const original = row?.original || {};
  const title = original?.title || '';
  const firstName = original?.firstName || '';
  const lastName = original?.lastName || '';
  const email = original?.email || '';

  // Re-invite from inviteHistory
  const inviteToUpdateEmployerProfile = () => {
    dispatch(setCurrentModal({
      key: 'userInvitation',
      data: {
        modalTitle: 'New Request',
        ...original, // Original Invitation
        singleUserInvite: true,
        includeName: true,
        includeTitle: Boolean(title.length),
        locationId
      }
    }));
  };

  return (
    <div className="btn-group d-flex align-items-center justify-content-end">
      <button
        className="btn-link rounded text-ships-officer"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        title="Manage Menu"
        aria-label="Manage Menu"
      >
        <FaEllipsisH />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <div
          className="bg-dark px-4 py-2 text-white text-nowrap"
          data-demo="disabled"
        >
          {firstName} {lastName}
        </div>

        {manageLocation && (
          <button
            type="button"
            className="dropdown-item border-bottom  py-2"
            onClick={inviteToUpdateEmployerProfile}
          >
            <span className='d-flex align-items-center'>
              <FaPaperPlane />
              <span className='ml-2'>
                Send Invite
              </span>
            </span>
          </button>
        )}

        <div
          className='bg-light px-2 py-3 small'
        >
          <div
            className='bg-light px-2'
            data-demo="disabled"
          >
            <KeyValueItem
              className='text-nowrap mb-0'
              title="EMAIL"
              value={email}
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export default ActionsCell;
