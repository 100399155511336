import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  canAccessAllLocations,
  isProgramManager,
  isProgramTrainer
} from '@apprentage/utils';
import { FaArrowRight, FaSortAmountUp } from 'react-icons/fa';
import { withAuthorization } from '../../../../Session';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import {
  getApprenticeship,
  resetApprenticeship
} from '../../../../../actions/Apprenticeships';
import { resetUsers } from '../../../../../actions/Users';
import { routeWithOrgId } from '../../../../../services/organizations';
import { getEmployers } from '../../../../../actions/Employers';
import { canInviteProgramUsers } from '../../../../../permissions/invite';
// import { canManageLogbookReminders } from '../../../../../permissions/manage';
import withOrgConsole from '../../../../App/withOrgConsole';
import Loading from '../../../../Loading';
import UsersList from '../../../../UsersList';
import OrgConsoleHeader from '../../../OrgConsoleHeader';
import OrgConsoleSubheader from '../../../OrgConsoleSubheader';
import Tabs from '../Tabs';
import DotMenu from '../DotMenu';
import DotMenuUsers from './DotMenuUsers';
import UsersTabs from './UsersTabs';

const ApprenticeshipUsers = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  // redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const currentUser = useSelector((state) => state.currentUser);
  const users = useSelector((state) => state.users);
  //  Organization
  const orgId = organization?.id || null;
  // Apprenticeship
  const apprenticeshipId = params?.apprenticeshipId || null;
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;
  //  CurrentUser
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);

  useEffect(() => {
    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId)).then((response) => {
        const config = {
          orgId,
          ...(response?.employerIds
            ? { employerIds: response.locationIds }
            : {})
        };

        dispatch(getEmployers(config));
      });
    }
  }, [apprenticeshipId, dispatch, orgId]);

  useEffect(() => {
    return function cleanup() {
      const { href } = window?.location;

      dispatch(resetUsers()); // TODO does this need logic like logic below?

      // Only reset if not going to apprentice route
      if (!href.includes(apprenticeshipRoute)) {
        dispatch(resetApprenticeship());
      }
    };
  }, [apprenticeshipRoute, dispatch]);

  const hiddenColumns = useMemo(() => {
    const result = ['role'];

    // if (!canManageLogbookReminders(role)) {
    //   result.push('remindersCell');
    // }

    if (isProgramManager(role) || isProgramTrainer(role)) {
      result.push('userProfileId');
      result.push('isApprentice');
    }

    if (!canAccessAllLocations(role)) {
      result.push('locationId');
    }

    return result;
  }, [role]);

  const handleClickRow = (clickedUser) => {
    const route = `${apprenticeshipRoute}/users/${clickedUser.id}`;

    history.push(
      routeWithOrgId({
        route,
        orgId
      })
    );
  };

  if (!apprenticeship.id) {
    return <Loading text="Loading Training Program Users" />;
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={apprenticeship.title}
            iconComponent={() => <FaSortAmountUp size={20} />}
            // route={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
            hiddenColumns={hiddenColumns}
            className="mb-3"
          >
            <DotMenu />
          </OrgConsoleHeader>

          <Tabs active="users" />

          <div className="bg-white shadow overflow-content border-left border-right border-bottom">
            <div className="p-3">
              <OrgConsoleSubheader
                pageTitle="Users"
                className="mb-3"
                badge={() => (
                  <span
                    className="ml-2 badge bg-white border"
                  >
                    {Array.isArray(users.list) ? users.list.length : 0}
                  </span>
                )}
              >
                <DotMenuUsers />
              </OrgConsoleSubheader>

              <UsersTabs active="trainees" />

              <div className="border-left border-right border-bottom">
                <UsersList
                  roles={['student']}
                  showLogbookRecords
                  showReminders
                  supervisorIds={isProgramTrainer(currentUser?.role) ? [currentUser?.id] : undefined}
                  apprenticeshipId={apprenticeshipId}
                  hiddenColumns={hiddenColumns}
                  handleClickRow={handleClickRow}
                  excludeTestUsers
                  excludeInactive
                  showRole
                />
              </div>

              {canInviteProgramUsers(role) && (
                <div className="pt-3 w-100 text-center">
                  <Link
                    to={routeWithOrgId({
                      route: `${apprenticeshipRoute}/invites`,
                      orgId
                    })}
                    className="btn btn-sm btn-primary"
                  >
                    <span className='d-flex align-items-center'>
                      <span className="mr-2">Invite Users</span>
                      <FaArrowRight />
                    </span>
                  </Link>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ApprenticeshipUsers);
