import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { FaCog } from 'react-icons/fa';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { DASHBOARD_CONFIGURE, ORG_CONSOLE_DASHBOARD } from '../../constants/routes';
import { getFeaturedProject, resetProject } from '../../actions/Projects';
import * as analytics from '../../utils/analytics';
import { createDashboard, fetchDashboardByType } from '../../services/dashboard';
import { DASHBOARD_DEFAULT_LAYOUT, LAYOUTS } from '../../constants/dashboard';
import { canManageOrgDashboard } from '../../permissions/manage';
import Module from './DraggableWidgetsDashboard/Module';
import Loading from '../Loading';
import './style.css';

const Dashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const userLocation = useSelector((state) => state.userLocation);
  // Current User
  const role = currentUser?.role || [];

  // Local State Dashboard
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState({});
  const [zones, setZones] = useState({
    zone1: [],
    zone2: [],
    zone3: []
  });
  const [isDashboardDataLoading, setIsDashboardDataLoading] = useState(true);
  const [isZoneDataAvailable, setIsZoneDataAvailable] = useState(false);
  // Current User
  const userId = currentUser?.id || '';
  // User Location
  const locationId = userLocation?.id || '';
  const locationName = userLocation?.name || '';
  // Organization
  const orgId = organization?.id || '';
  const orgName = organization?.name || '';
  // const orgNotifications = organization?.notifications?.list || [];

  useEffect(() => {
    if (orgId) {
      dispatch(getFeaturedProject({ orgId }));
    }
  }, [dispatch, orgId]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetProject());
    };
  }, [dispatch]);

  useEffect(() => {
    if (orgId) {
      const analyticsData = {
        page_title: 'Dashboard',
        page_type: 'Organization Dashboard',
        orgId,
        orgName,
        ...(userId ? { userId } : {}),
        ...(locationId ? { locationId } : {}),
        ...(locationName
          ? { orgLocationName: `${orgName} ${locationName}` }
          : {})
      };

      analytics.logEvent('page_view', analyticsData);
      analytics.logEvent('dashboard_org_view', analyticsData);
    }
  }, [userId, orgId, locationId, orgName, locationName]);

  /** Get Dashboard Data */
  const getDashboardData = useCallback(() => {
    setIsDashboardDataLoading(true);
    fetchDashboardByType({ orgId }).then((response) => {
      if (response !== null) {
        setZones((prevZones) => ({
          ...prevZones,
          zone1: response?.zone1 || [],
          zone2: response?.zone2 || [],
          zone3: response?.zone3 || []
        }));
        setDashboardData({
          ...response
        });
        setIsZoneDataAvailable(Boolean(response?.id)); // TODO change var name
        setIsDashboardDataLoading(false);
      } else {
        setIsDashboardDataLoading(false);
      }
    });
  }, [orgId]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  if (isDashboardDataLoading) {
    return <Loading />;
  }

  if (!isDashboardDataLoading && !isZoneDataAvailable && !canManageOrgDashboard(role)) {
    // Dashboard data was fetched, No Dashboard Data available, Can NOT create a Dashboard
    // Redirect user away from Org Dashboard page
    return <Redirect to={ORG_CONSOLE_DASHBOARD} />;
  }

  return (
    <>

      {!isDashboardDataLoading && isZoneDataAvailable && (
        <>
          <div className="row pt-4 bg-light">
            {Object.keys(zones).map((zoneId, zoneIndex) => {
              return (
                <div
                  key={zoneIndex}
                  className={`col-12 col-sm-${LAYOUTS[dashboardData.activeLayout || DASHBOARD_DEFAULT_LAYOUT][zoneIndex]} w-100`}
                >
                  {zones[zoneId]?.map((module, index) => (
                    <div key={index}>
                      <Module
                        type={module.moduleType}
                        className="mb-4"
                      />
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
          {canManageOrgDashboard(role) && (
            <div className="d-flex justify-content-center my-3">
              <button
                type="button"
                className="btn btn-outline-primary btn-md"
                onClick={() => {
                  history.push(DASHBOARD_CONFIGURE);
                }}
              >
                <span className='d-flex align-items-center'>
                  <FaCog />
                  <span className='ml-2'>
                    Configure
                  </span>
                </span>
              </button>
            </div>
          )}
        </>
      )}

      {!isZoneDataAvailable && canManageOrgDashboard(role) && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: 'calc(100vh - 86px)' }}
        >
          <div>
            <button
              type="button"
              className="btn btn-primary btn-md"
              disabled={loading}
              onClick={() => {
                setLoading(true);
                if (dashboardData?.id) {
                  // Organization has a Dashboard
                  history.push(DASHBOARD_CONFIGURE);
                } else {
                  // Organization does not have a Dashboard
                  createDashboard({
                    orgId,
                    type: 'organization',
                    activeLayout: DASHBOARD_DEFAULT_LAYOUT,
                    zone1: [{ moduleType: 'NOTIFICATIONS', id: uuid() }],
                    zone2: [{ moduleType: 'LINK_WIDGETS_LIST', id: uuid() }]
                  }).then(() => {
                    setTimeout(() => {
                      history.push(DASHBOARD_CONFIGURE);
                    }, 250);
                  }).catch((error) => {
                    setLoading(false);
                    toast.error('Something went wrong, try again.');
                    console.error(error);
                  });
                }
              }}
            >
              {loading ? 'Creating...' : 'Create Dashboard'}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
