import React from 'react';
import { useSelector } from 'react-redux';
import { withAuthorization } from '../../../Session';
import {
  ORG_CONSOLE_INSTRUCTION_PROVIDERS,
  ORG_CONSOLE_LOCATIONS,
  ORG_CONSOLE_LOGBOOKS,
  ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS
} from '../../../../constants/routes';
import { routeWithOrgId } from '../../../../services/organizations';
import {
  canAccessOrgLocations,
  canManageLogbooks
} from '../../../../services/currentUser';
import { canAccessApprenticeshipInstructionProviders } from '../../../../permissions/access';
import {
  IMAGE_CARD_EMPLOYERS,
  IMAGE_CARD_INSTRUCTOR_PROVIDERS,
  IMAGE_CARD_LOGBOOKS,
  IMAGE_CARD_TRAINING_PROGRAMS
} from '../../../../constants/assets';
import Loading from '../../../Loading';
import { DashboardCards } from './DashboardCards';
// import PersonActivityCard from './PersonActivityCard';
import './style.css';

const WorkforceSuiteDashboard = () => {
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || '';
  const orgType = organization?.type || '';
  // User
  const role = currentUser?.role || [];

  if (!currentUser?.id || !organization?.id) {
    return <Loading />;
  }

  return (
    <div className="col-12 col-sm-10 mx-auto">
      <div className="mt-5 mb-4">
        <div className="row">
          <div className="col">
            <div>
              <div className="h6 font-weight-bold m-0 bg-dark text-white py-1 px-2 d-inline-block">
                Apprentage
              </div>
              <div className="h2 m-0">On-the-job Training</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {/* <div className="col-12 col-md-6 mb-3">
          <PersonActivityCard
            name={currentUser?.name}
            personRole="Frontend Developer"
            profilePicture={currentUser?.profilePicture || DEFAULT_PROFILE_PIC}
          />
        </div> */}
        <div
          className="col-12 col-md-6 mb-4" // col-xl-4
        >
          <DashboardCards
            title="Training Programs"
            onClickRoutePath={routeWithOrgId({
              route: ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS,
              orgId
            })}
            // subTitle="Build and manage apprenticeships & employer-driven training pipelines of skilled employees."
            subTitle="Facilitate apprenticeships & employer-driven trainings that provide workers with the skills and knowledge they need to succeed in the workforce."
            imageUrl={IMAGE_CARD_TRAINING_PROGRAMS}
          />
        </div>

        {canManageLogbooks(role) && (
          <div
            className="col-12 col-sm-6  mb-4" // col-xl-4
          >
            <DashboardCards
              onClickRoutePath={routeWithOrgId({
                route: ORG_CONSOLE_LOGBOOKS,
                orgId
              })}
              title="Logbooks"
              subTitle="Recording worker tasks in a job book is the basis of OJT effectiveness.  VELA, our voice-enabled AI, removes IRL obstacles to logging tasks, and provides unprecedented."
              imageUrl={IMAGE_CARD_LOGBOOKS}
            />
          </div>
        )}

        {/* {enablePathways && canAccessPathways(role) && (
          <div className="col-12 col-sm-6 col-xl-4 mb-4">
            <DashboardCards
              title="Pathways"
              onClickRoutePath={routeWithOrgId({
                route: ORG_CONSOLE_PATHWAYS,
                orgId
              })}
              subTitle="Pathways are a way to connect trainings to the jobs that employees have or want by linking trainings to job profiles & skills."
              // subTitle="Employees need trainings to connect directly to the jobs workers have, or the jobs workers want. Pathways make trainings actionable by connecting Job Profiles."
              imageUrl={IMAGE_CARD_PATHWAYS}
            />
          </div>
        )} */}

        {canAccessOrgLocations(role, orgType) && (
          <div
            className="col-12 col-sm-6 mb-4" // col-xl-4
          >
            <DashboardCards
              title="Employers"
              onClickRoutePath={routeWithOrgId({
                route: ORG_CONSOLE_LOCATIONS,
                orgId
              })}
              subTitle="Employers are unique customers, and each requires a lot of support and resources to maximize participation and realize their ROI."
              imageUrl={IMAGE_CARD_EMPLOYERS}
            />
          </div>
        )}

        {canAccessApprenticeshipInstructionProviders(role) && (
          <div
            className="col-12 col-sm-6 mb-4" // col-xl-4
          >
            <DashboardCards
              title="Instruction Providers"
              onClickRoutePath={routeWithOrgId({
                route: ORG_CONSOLE_INSTRUCTION_PROVIDERS,
                orgId
              })}
              subTitle="Align instruction with knowledge and competency requirements, and maintain employer relevance."
              imageUrl={IMAGE_CARD_INSTRUCTOR_PROVIDERS}
            />
          </div>
        )}

        {/* {enablePathways && canAccessPathways(role) && (
          <div className="col-12 col-sm-6 col-xl-4 mb-4">
            <DashboardCards
              onClickRoutePath={routeWithOrgId({
                route: ORG_CONSOLE_JOB_PROFILES,
                orgId
              })}
              title="Job Profiles"
              subTitle="Job Profiles put employers and workers on the same page with a clear description of the skills and knowledge required for a specific job at the company."
              // subTitle="Job Profiles put employers and workers on the same page with a clear description of the skills and knowledge required for a specific job at the company. JPs have value because each is employer-endorsed."
              imageUrl={IMAGE_CARD_JOB_PROFILES}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default withAuthorization(condition)(WorkforceSuiteDashboard);
