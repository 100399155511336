import { isAdminOwnerSuperAdmin } from '@apprentage/utils';
import isProgramManager from '../permissions/isProgramManager';
import isProgramTrainer from '../permissions/isProgramTrainer';
import { fetchSupabaseEntries } from './supabaseProxy';

// ROLES : SYSTEM
export const isSuperAdmin = (role = []) => {
  return role.indexOf('superAdmin') !== -1;
};

export const isOwner = (role = []) => {
  return role.indexOf('owner') !== -1;
};

export const isAdmin = (role = []) => {
  return role.indexOf('admin') !== -1;
};

export const isStudent = (role = []) => {
  return role.indexOf('student') !== -1;
};

// ROLES : COURSE

export const isTeacher = (role = []) => {
  return role.indexOf('teacher') !== -1;
};

export const getUserRole = ({ role = [] }) => {
  switch (true) {
    case isSuperAdmin(role):
      return 'superAdmin';
    case isOwner(role):
      return 'owner';
    case isAdmin(role):
      return 'admin';
    case isTeacher(role):
      return 'teacher';
    default:
      return 'student';
  }
};

export const userOrgIsCourseOrg = ({ course = {}, currentUser = {} }) => {
  if (!course?.id || !currentUser?.id) {
    return false;
  }

  if (course?.orgId !== currentUser?.orgId) {
    return false;
  }

  return true;
};

export const userOrgInCourseOrgIds = ({ course = {}, currentUser = {} }) => {
  const orgIds = course?.orgIds || [];

  if (!course?.id || !currentUser?.id) {
    return false;
  }

  if (orgIds.length > 0 && orgIds.includes(currentUser?.orgId)) {
    return true;
  }

  return false;
};

export const roleReadable = ({ role = [], orgType = '' }) => {
  switch (true) {
    case isSuperAdmin(role):
      return 'Super Admin';
    case isOwner(role):
      return 'Owner';
    case isAdmin(role):
      return 'Admin';
    case isProgramTrainer(role):
      return 'Program Trainer';
    case isProgramManager(role):
      return 'Program Manager';
    case isTeacher(role):
      return orgType !== 'workforce' ? 'Instructor' : 'Expert';
    case isStudent(role):
      return orgType !== 'workforce' ? 'Trainee' : 'User';
    default:
      return role.join(',');
  }
};

// PERMISSIONS
export const canActivateDemoMode = () => {
  // role
  return true;
};

// PERMISSIONS (content)
export const canAccessByRole = (role = [], roles) => {
  function evalRoles(arrayItem) {
    // index, array
    return role.includes(arrayItem);
  }

  if (isOwner(role) || isSuperAdmin(role)) {
    return true;
  }
  if (roles === undefined) {
    return true;
  }
  if (roles.some(evalRoles)) {
    return true;
  }

  return false;
};

export const canChangeCourseDisplayType = (role = []) => {
  return isOwner(role) || isSuperAdmin(role);
};

export const canCreateCourse = (role = [], orgType = '') => {
  if (!orgType) {
    return false;
  }

  if (isTeacher(role) && orgType === 'workforce') {
    return true;
  }

  return isAdminOwnerSuperAdmin(role);
};

export const canCreateCourseGroup = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canCreatePathway = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canManageCoursePrereqs = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canManageTopicPrereqs = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canManageResources = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canManageResourceGroups = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canCreateResource = (role = [], orgType = '') => {
  if (isAdminOwnerSuperAdmin(role)) {
    return true;
  }

  if (!orgType) {
    return false;
  }

  if (orgType === 'workforce') {
    if (isTeacher(role) || isStudent(role)) {
      return true;
    }
  }

  return false;
};

export const canDeleteResource = ({ role = [], userId, resourceUserId }) => {
  if (isAdminOwnerSuperAdmin(role)) {
    return true;
  }

  if (!userId || !resourceUserId) {
    return false;
  }

  return userId === resourceUserId;
};

export const canEditResource = ({ role = [], userId, resourceUserId }) => {
  if (isAdminOwnerSuperAdmin(role)) {
    return true;
  }

  if (!userId || !resourceUserId) {
    return false;
  }

  return userId === resourceUserId;
};

export const canCreateResourceGroup = (role = [], orgType = '') => {
  if (isAdminOwnerSuperAdmin(role)) {
    return true;
  }

  if (orgType && isTeacher(role) && orgType === 'workforce') {
    return true;
  }

  return false;
};

export const canCreateResourceTag = (role = [], orgType = '') => {
  if (isAdminOwnerSuperAdmin(role)) {
    return true;
  }

  if (!orgType) {
    return false;
  }

  if (orgType === 'workforce') {
    if (isTeacher(role) || isStudent(role)) {
      return true;
    }
  }

  return false;
};

export const canEditResourceGroup = ({
  role = [],
  userId = '',
  authorUserId = ''
}) => {
  if (isAdminOwnerSuperAdmin(role)) {
    return true;
  }

  if (!userId || !authorUserId) {
    return false;
  }

  return userId === authorUserId;
};

export const canDeleteResourceGroup = ({
  role = [],
  userId = '',
  authorUserId = ''
}) => {
  if (isAdminOwnerSuperAdmin(role)) {
    return true;
  }

  if (!userId || !authorUserId) {
    return false;
  }

  return userId === authorUserId;
};

export const canManageLogbooks = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canManageProjects = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canUploadFiles = (role = [], orgType = '') => {
  if (!orgType) {
    return false;
  }

  if (isTeacher(role) && orgType === 'workforce') {
    return true;
  }

  return isAdminOwnerSuperAdmin(role);
};

export const canViewUserCourseCertificate = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canViewRoleInTopNav = (role = []) => {
  return !isStudent(role);
};

export const canDeleteUserHard = (role = []) => {
  return isOwner(role) || isSuperAdmin(role);
};

export const canDeleteUserSoft = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canDeleteEntry = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canManageContent = (role = [], orgType = '') => {
  if (!orgType) {
    return false;
  }

  if (isTeacher(role) && orgType === 'workforce') {
    return true;
  }

  return isTeacher(role) || isAdminOwnerSuperAdmin(role);
};

export const canManageCourseContent = ({
  course = {},
  currentUser = {},
  orgType = ''
}) => {
  const role = currentUser?.role || [];
  const teacherIds = course?.teacherIds || [];

  if (!course?.id || !currentUser?.id) {
    return false;
  }

  if (userOrgIsCourseOrg({ course, currentUser })) {
    if (isTeacher(role)) {
      if (orgType === 'workforce') {
        return true;
      }

      if (teacherIds.includes(currentUser?.id)) {
        return true;
      }
    }

    return isAdminOwnerSuperAdmin(role);
  }

  return false;
};

export const canManageAssessments = ({ course = {}, currentUser = {} }) => {
  const role = currentUser?.role || [];
  const orgIds = course?.orgIds || [];

  if (!course?.id || !currentUser?.id) {
    return false;
  }

  if (orgIds.length > 0 && userOrgInCourseOrgIds({ course, currentUser })) {
    return isTeacher(role) || isAdminOwnerSuperAdmin(role);
  }

  return isTeacher(role) || isAdminOwnerSuperAdmin(role);
};

export const canManageCompletionCertificates = (role = []) => {
  return isOwner(role) || isSuperAdmin(role);
};

export const canManageUserRoles = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canManageCoursePublishSettings = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

// PERMISSIONS (users)
export const canExportUserData = (role = [], orgType = '') => {
  if (!orgType) {
    return false;
  }

  return isProgramTrainer(role) || isProgramManager(role) || isAdminOwnerSuperAdmin(role);
};

export const canViewTestUsers = (role = []) => {
  return isSuperAdmin(role);
};

export const canDirectMessageUser = (role = [], orgType = '') => {
  if (!orgType) {
    return false;
  }

  if (orgType === 'workforce' && isStudent(role)) {
    return true;
  }

  return isTeacher(role) || isAdminOwnerSuperAdmin(role);
};

export const canManageUsers = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

// PERMISSIONS (access)
export const canAccessAllUsers = (role = [], orgType) => {
  if (!orgType) {
    return false;
  }

  if (orgType === 'workforce' && (isStudent(role) || isTeacher(role))) {
    return true;
  }

  return isAdminOwnerSuperAdmin(role);
};

export const canAccessDashboard = (role = [], organization = {}) => {
  if (!organization?.id) {
    return false;
  }

  if (!organization?.dashboard) {
    return false;
  }

  if (organization?.type === 'workforce' && isStudent(role)) {
    return true;
  }

  return isTeacher(role) || isAdminOwnerSuperAdmin(role);
};

export const canAccessCourses = (role = []) => {
  return isTeacher(role) || isAdminOwnerSuperAdmin(role);
};

export const canAccessAssessments = (role = [], orgType = '') => {
  if (!orgType) {
    return false;
  }

  if (isAdminOwnerSuperAdmin(role)) {
    return true;
  }

  if (isTeacher(role) && orgType === 'community') {
    return true;
  }

  return false;
};

export const canAccessPathways = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canManagePathwayPublishSettings = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canAccessCoursesManagement = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canAccessOrgSettings = (role = []) => {
  return isOwner(role) || isSuperAdmin(role);
};

export const canAccessBilling = (role = []) => {
  return isOwner(role) || isSuperAdmin(role);
};

export const canAccessOrgLocations = (role = [], orgType = '') => {
  if (!orgType) {
    return false;
  }

  if (orgType === 'workforce' && (isStudent(role) || isTeacher(role))) {
    return true;
  }

  return isAdminOwnerSuperAdmin(role);
};

// LOCATIONS
export const canCreateLocation = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canEditLocation = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canEditDefaultLocation = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

// NOTIFICATIONS
export const canPostNotificationAsOrganization = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canPostOrgNotifications = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canViewAllRoleSpecificNotifications = (role = []) => {
  return isOwner(role) || isSuperAdmin(role);
};

// WIDGETS
export const canManageWidgets = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canPostCourseNotifications = (
  role = [],
  userId = '',
  teacherIds = []
) => {
  if (!userId) {
    return false;
  }

  if (isTeacher(role) && teacherIds.includes(userId)) {
    return true;
  }

  return isAdminOwnerSuperAdmin(role);
};

export const canEditDeleteNotification = (
  role = [],
  userId = '',
  authorId = ''
) => {
  if (isTeacher(role) && authorId) {
    // User is editing their own notification
    if (userId === authorId) {
      return true;
    }
  }

  if (isAdminOwnerSuperAdmin(role)) {
    // User is trying to edit a notification they didn't write
    // If they're role is owner+ allow them to edit/delete
    return true;
  }

  return false;
};

// /NOTIFICATIONS

export const canAccessTurbineAdmin = (role = [], orgType = '') => {
  if (!orgType) {
    return false;
  }

  if (isTeacher(role) || isAdminOwnerSuperAdmin(role)) {
    return true;
  }

  if (isProgramManager(role) || isProgramTrainer(role)) {
    return true;
  }

  if (isStudent(role) && orgType === 'workforce') {
    return true;
  }

  return false;
};

export const canAccessResources = (
  role = [],
  enableResources = false,
  orgType = ''
) => {
  if (!enableResources || !orgType) {
    return false;
  }

  if (enableResources === true) {
    if (isAdminOwnerSuperAdmin(role)) {
      return true;
    }

    if (orgType === 'workforce') {
      if (isStudent(role) || isTeacher(role)) {
        return true;
      }
    }
  }

  return false;
};

export const canAccessUserGroups = (role = [], enableUserGroups = false) => {
  if (enableUserGroups === true) {
    return isOwner(role) || isSuperAdmin(role);
  }

  return false;
};

export const canAccessCourseGroups = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canAccessCourseSyndication = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canManageCourseSyndication = (role = []) => {
  return isOwner(role) || isSuperAdmin(role);
};

export const canAccessUserPII = (role = [], enableWorkforceSuite) => {
  if (!enableWorkforceSuite) {
    return false;
  }

  return isAdminOwnerSuperAdmin(role);
};

export const canManageUserPII = (role = [], enableWorkforceSuite) => {
  if (!enableWorkforceSuite) {
    return false;
  }

  return isAdminOwnerSuperAdmin(role);
};

// PERMISSIONS (billing)

export const canAccessDirectory = (organization) => {
  if (!organization?.id) {
    return false;
  }

  return organization?.enableUserDirectory || false;
};

export const canManageDirectoryUsers = (role) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canConnectStripe = (role = []) => {
  return isOwner(role) || isSuperAdmin(role);
};

// TODO move to user service
export const isActiveMembership = (membership = []) => {
  return membership.indexOf('inactive') === -1;
};

export const getMembershipReadable = (membership = []) => {
  // TODO switch this to one value in content model
  if (!membership.length) {
    return '';
  }

  switch (true) {
    case membership.includes('inactive'):
      return 'inactive';
    case membership.includes('pro'):
      return 'pro';
    case membership.includes('premium'):
      return 'premium';
    case membership.includes('starter'):
      return 'starter';
    default:
      return membership[0];
  }
};

export const fetchUser = async ({ userId, select }) => {
  const errorUserIdRequired = 'userId is required';

  if (!userId) {
    console.error(errorUserIdRequired);
    throw new Error(errorUserIdRequired);
  }

  try {
    const params = { id: userId };

    if (Array.isArray(select) && select.length) {
      params.select = select.join(',');
    }

    const response = await fetchSupabaseEntries(params, 'users');
    const items = response?.items;
    const user = Array.isArray(items) && items[0] !== undefined ? items[0] : null;
    return user;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};
