import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaFolder, FaFolderOpen } from 'react-icons/fa';
import { fetchResourceGroups } from '../../../services/resourceGroups';
import GroupsList from '../../OrgConsole/Resources/List';
import Breadcrumb from '../../OrgConsole/Resources/Breadcrumb';

const ChooseResourceFolder = ({
  onSelect
}) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || null;
  // Local State
  const [currentGroup, setCurrentGroup] = useState(null);
  const [resourceGroupsList, setResourceGroupsList] = useState(null);
  const [groupIds, setGroupIds] = useState([]);

  const retrieveResourceGroups = useCallback(() => {
    fetchResourceGroups({
      select: ['title', 'id'],
      parentId: currentGroup?.id || null,
      orgId: organization?.id
    }).then((response) => {
      setResourceGroupsList(response?.items);
    }).catch((error) => {
      console.error('getResourceGroups', error);
    });
  }, [currentGroup?.id, organization?.id]);

  useEffect(() => {
    retrieveResourceGroups();
  }, [retrieveResourceGroups]);

  return (
    <>
      {currentGroup?.id && (
        <Breadcrumb
          className=''
          groupIds={groupIds}
          resourceGroup={currentGroup}
          resourceGroupsList={resourceGroupsList}
          onClick={(data) => {
            setCurrentGroup(data?.group);
            setGroupIds(data?.groupIds || []);
          }}
        />
      )}
      <div
        className="card-body p-0 overflow-content"
      >
        <div className="card">
          <div
            className="card-header d-flex align-items-center justify-content-between pr-3"
          >
            <h5
              className="m-0 d-flex align-items-center"
              data-cy="ChooseResourceFolder-title"
            >
              <FaFolderOpen size={20} />
              <span className='ml-2'>
                {currentGroup?.title || 'All Resources'}
              </span>
            </h5>

            {currentGroup?.id && (
              <button
                className='btn btn-sm btn-outline-primary'
                type='button'
                onClick={() => {
                  onSelect({
                    resourceGroup: currentGroup,
                    resourceGroupIds: groupIds,
                    resourceGroupsList
                  });
                }}
              >
                Select
              </button>
            )}
          </div>

          <div>
            <GroupsList
              data={currentGroup?.id ? resourceGroupsList : resourceGroupsList}
              hideTagsCol
              hideUpdatedDateCol
              onClick={(group) => {
                setCurrentGroup(group);
                setGroupIds((prev) => ([
                  ...prev,
                  group?.id
                ]));
              }}
              onSelect={(group) => {
                onSelect({
                  resourceGroup: group,
                  resourceGroupIds: [...groupIds, group?.id],
                  resourceGroupsList
                });
              }}
              // editMenu={canManageResourceGroups(role)}
              // editItem={editGroup}
              // addItem={addGroup}
              // uploadFiles={uploadFiles}
              orgId={orgId}
              groupIds={groupIds}
              limit={1000}
            />
          </div>
        </div>

        {Array.isArray(resourceGroupsList) && resourceGroupsList.length === 0 && (
          <div
            className='border-right border-left border-bottom p-3 d-flex align-items-center'
            style={{
              borderRadius: '0 0 .25rem .25rem'
            }}
          >
            <FaFolder size={22} className='text-muted ml-1' />
            <div className='ml-2'>
              No Folders
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChooseResourceFolder;
