import React from 'react';
import Table from './Table';
import DateCell from '../../../ManageContent/Tables/DateCell';
import ArrLenCell from '../../../ManageContent/Tables/ArrLenCell';

const List = ({
  data, hiddenColumns = [], onClickRow
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title'
      },
      {
        Header: 'Employers',
        accessor: 'employerIds',
        Cell: (props) => <ArrLenCell {...props} />
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        Cell: (props) => <DateCell {...props} />
      },
      {
        Header: 'End Date',
        accessor: 'expectedEndDate',
        Cell: (props) => <DateCell {...props} />
      }
    ],
    []
  );

  if (!data || (data && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">No Training Programs</div>
      </div>
    );
  }

  return (
    <Table
      columns={columns}
      hiddenColumns={hiddenColumns}
      data={data}
      onClickRow={onClickRow}
    />
  );
};

export default List;
