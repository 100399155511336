import React from 'react';
import { FaMagic } from 'react-icons/fa';
import NameIcon from './NameIcon';

const ResourceIcon = ({
  type, url, name, isEmbedded
}) => {
  return (
    <span className='position-relative'>
      <NameIcon
        type={type}
        url={url}
        name={name}
      />

      {isEmbedded && (
        <span
          className='position-absolute'
          style={{
            top: 0,
            left: 0
          }}
        >
          <FaMagic
            className='text-keppel d-inline ml-2'
            title="Context-Aware Search Engine"
          />
        </span>
      )}
    </span>
  );
};

export default ResourceIcon;
