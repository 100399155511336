import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usStateReadable } from '../../../utils/address';
import { getGroups } from '../../../actions/Groups';
import { handleOpenUserSideSheet } from '../../../actions/SideSheets';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import Users from '../../ManageContent/Users';
import SkillsTags from '../../SkillsTags';

const ProjectDetails = ({
  showAllDetails = false
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const userGroups = useSelector((state) => state.userGroups);
  const locations = useSelector((state) => state.locations);
  const project = useSelector((state) => state.project);
  // Organization
  const orgId = organization?.id || '';

  const onClickTeamMember = (userId) => {
    dispatch(handleOpenUserSideSheet({
      userId,
      orgId,
      currentUserOrgId: currentUser?.orgId
      // route,
      // history
    }));
  };

  const addressCityState = useMemo(() => {
    let result = project?.addressCity || '';

    if (project?.addressState) {
      result += `, ${usStateReadable(project?.addressState)}`;
    }

    return result;
  }, [project?.addressCity, project?.addressState]);

  const departments = useMemo(() => {
    let results = [];

    if (Array.isArray(userGroups.list) && userGroups.list.length !== 0) {
      if (Array.isArray(project?.userGroupIds) && project?.userGroupIds.length !== 0) {
        results = userGroups.list.filter(((g) => project?.userGroupIds.includes(g.id) === true));
      }
    }

    return results;
  }, [userGroups?.list, project?.userGroupIds]);

  const projectLocations = useMemo(() => {
    let results = [];

    if (project?.locationId) {
      results = locations.list.filter((location) => location?.id === project?.locationId);
    }

    return results;
  }, [locations?.list, project?.locationId]);

  useEffect(() => {
    if (orgId) {
      dispatch(getGroups({ orgId, type: 'user' }));
    }
  }, [dispatch, orgId]);

  if (!project?.id) {
    return;
  }

  return (
    <div
      style={{
        fontSize: '1rem'
      }}
    >
      <div className="mb-2">
        <strong>Project:</strong> {project?.title}
      </div>

      {addressCityState && (
        <div className="mb-2">
          <strong>Location:</strong> {addressCityState}
        </div>
      )}

      {project?.clientName && (
        <div className="mb-2">
          <strong>Client:</strong> {project?.clientName}
        </div>
      )}

      {showAllDetails && (
        <>
          {project?.projectSize && (
            <div className="mb-2">
              <strong>Project Size:</strong> {project?.projectSize}
            </div>
          )}

          {project?.teamName && (
            <div className="mb-2">
              <strong>Team:</strong> {project?.teamName}
            </div>
          )}

          {Array.isArray(departments) && departments.length > 0 && (
            <div className="mb-2">
              <div className="d-flex flex-wrap align-items-center">
                <strong className="my-1">Departments:</strong>

                {departments.map((userGroup) => (
                  <span
                    key={userGroup?.id}
                    className="ml-1 badge border bg-white py-1 px-2"
                  >
                    {userGroup?.title}
                  </span>
                ))}
              </div>
            </div>
          )}

          {projectLocations.length !== 0 && (
            <div className="mb-2">
              <div>
                <strong>Office:</strong>
                {projectLocations.map((projectLocation) => (
                  <span
                    key={projectLocation?.id}
                    className="ml-1"
                  >
                    {projectLocation?.name}
                  </span>
                ))}
              </div>
            </div>
          )}

          <SkillsTags
            title="Skills"
            ids={project?.skillIds}
          />
        </>
      )}

      {project?.body && (
        <RenderMarkdown
          source={project?.body}
          enableNewEditor
          {...(showAllDetails ? {} : { style: { height: '100px', overflow: 'hidden' } })}
        />
      )}

      {showAllDetails && (
        <>
          {Array.isArray(project?.userIds) && project?.userIds.length !== 0 && (
            <div>
              <div className="mb-2">
                For more information, contact any team member:
              </div>
              <Users
                userIds={project?.userIds}
                onClick={onClickTeamMember}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProjectDetails;
