import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import { ICON_CITY, ICON_MAP_PIN } from '@apprentage/constants';
import { client, flattenItem, flattenItems } from './contentful';
import { createEntry, updateEntry } from './entry';
import formatData from './formatData';

export const locationTerminology = ({ orgType }) => {
  return {
    text: orgType !== 'workforce' ? 'Employer' : 'Location',
    icon: orgType !== 'workforce' ? ICON_CITY : ICON_MAP_PIN
  };
};

export const createLocation = (data) => {
  return new Promise((resolve, reject) => {
    const preppedData = formatData(data, 'location');

    createEntry({
      contentType: 'location',
      data: preppedData
    }).then((newLocation) => {
      setTimeout(() => {
        resolve(newLocation);
      }, 250);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const updateLocation = (data, locationId) => {
  return new Promise((resolve, reject) => {
    const preppedData = formatData(data, 'location');

    updateEntry(preppedData, locationId).then(() => {
      resolve();
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const fetchLocation = ({ locationId }) => {
  return new Promise((resolve, reject) => {
    if (!locationId) {
      const customError = new Error('fetchLocation, missing arguments: locationId');
      reject(customError);
    }
    return client.getEntry(locationId).then((response) => {
      const orgLocation = response ? flattenItem(response) : null;

      resolve(orgLocation);
    }).catch((error) => {
      console.error(error.message);
      reject(error);
    });
  });
};

export const fetchLocations = ({
  orgId, locationIds, include = 1, excludeDefaultLocation = false
}) => {
  return new Promise((resolve, reject) => {
    const config = {
      content_type: 'location',
      'fields.orgId': orgId,
      order: 'fields.name',
      include
    };

    if (excludeDefaultLocation) {
      config['fields.defaultLocation[exists]'] = false;
    }

    if (locationIds && Array.isArray(locationIds) && locationIds.length) {
      config['sys.id[in]'] = locationIds.join(',');
    }

    client.getEntries(config).then(({ items }) => {
      const locations = items.length ? flattenItems(items) : null;

      resolve(locations);
    }).catch((error) => {
      toast.error(error.message);
      reject(error);
    });
  });
};

export const getFullAddress = ({ location }) => {
  const addressLine1 = pathOr('', ['addressLine1'], location);
  const addressLine2 = pathOr('', ['addressLine2'], location);
  const addressCity = pathOr('', ['addressCity'], location);
  const addressState = pathOr('', ['addressState'], location);
  const addressPostalCode = pathOr('', ['addressPostalCode'], location);

  let fullAddress = addressLine1;

  if (addressLine2) {
    fullAddress += ` ${addressLine2}`;
  }

  fullAddress += ', ';
  fullAddress += `${addressCity}, `;
  fullAddress += addressState;
  fullAddress += ` ${addressPostalCode}`;

  return fullAddress;
};
