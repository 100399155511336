import {
  RESET_SIDE_SHEET, SET_SIDE_SHEET
} from '../../actions/types';

const initialState = {
  key: '',
  visible: false,
  className: '',
  data: null,
  callbackPrimaryAction: null
};

export default function sideSheet(state = initialState, action) {
  switch (action.type) {
    case SET_SIDE_SHEET:
      return {
        ...state,
        ...initialState,
        ...action.sideSheet,
        visible: true
      };
    case RESET_SIDE_SHEET:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
