import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy } from 'react-table'; // useFilters
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { useHistory } from 'react-router-dom';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../constants/routes';
import { routeWithOrgId } from '../../../../services/organizations';

const Table = ({
  columns, data, hiddenColumns = [], onClickRow
}) => {
  const history = useHistory();
  // redux
  const organization = useSelector((state) => state.organization);
  // misc
  const orgId = pathOr(null, ['id'], organization);
  const initialState = {
    sortBy: [
      {
        id: 'title',
        desc: false
      }
    ],
    hiddenColumns
  };

  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
  } = useTable(
    {
      columns,
      initialState,
      data
    },
    useSortBy
  );

  const handleOnClickRow = (row) => {
    const { original } = row;
    const apprenticeshipId = pathOr(null, ['id'], original);

    if (onClickRow) {
      onClickRow(apprenticeshipId);
      return;
    }

    const route = routeWithOrgId({
      route: `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`,
      orgId
    });

    history.push(route);
  };

  return (
    <table
      className="table table-bordered m-0"
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              return column.hideHeader ? null : (
                <th
                  style={{ verticalAlign: 'center' }}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  {column.canSort && (
                    <span className="ml-1">
                      {column.isSorted ? (
                        <Fragment>
                          {column.isSortedDesc ? (
                            <i className="fas fa-sort-down" />
                          ) : (
                            <i className="fas fa-sort-up" />
                          )}
                        </Fragment>
                      ) : (
                        <i
                          className="fas fa-sort"
                          style={{ opacity: '.25' }}
                        />
                      )}
                    </span>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              onClick={() => {
                handleOnClickRow(row);
              }}
              className="row-clickable"
            >
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  columns: PropTypes.arrayOf(PropTypes.shape())
};
