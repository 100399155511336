import React, {
  Fragment, useEffect, useRef, useState
} from 'react';
import RenderTinyMceEditor from '../../ManageContent/Wysiwyg/RenderTinyMceEditor';
import { getOpenGraphData } from '../../../services/courses';
import Loading from '../../Loading';
import urlHasProtocol from '../../../utils/urlHasProtocol';

export default ({
  data, updateState
}) => {
  const descriptionRef = useRef(null);
  // Local State
  const [loading, setLoading] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [currentState, setState] = useState(data);
  const {
    enableCourseContent,
    externalCourseUrl,
    title,
    description
  } = currentState;

  useEffect(() => {
    if (enableCourseContent === false && externalCourseUrl) {
      setLoading(true);
      getOpenGraphData(externalCourseUrl).then(({
        ogTitle, ogDescription, ogSiteName, ogImage
      }) => {
        const newState = { ...currentState };

        if (ogTitle) newState.title = ogTitle;
        if (ogDescription) newState.description = ogDescription;
        if (ogSiteName) newState.topics = [ogSiteName]; // Save website name as courseTag

        if (ogImage && Array.isArray(ogImage) && ogImage.length > 0) {
          // Check if URL exists and is an absolute url
          if (ogImage[0].url && urlHasProtocol(ogImage[0].url)) {
            newState.classImage = ogImage[0].url;
          }
        }

        // Has title & description, enable next button
        if (newState.title && newState.description) {
          setNextDisabled(false);
        }

        setState(newState);
        updateState(newState);
        setLoading(false);
      });
    }
  }, []);

  const handleNext = () => {
    const dataToSave = {
      ...currentState,
      currentStep: 2
    };

    if (descriptionRef && descriptionRef.current) {
      dataToSave.description = descriptionRef.current.getContent();
    }

    if (dataToSave.description) {
      updateState(dataToSave);
    } else {
      // User filled out all required items
      // User deleted description
      // User clicked "Next"
      setNextDisabled(true);
    }
  };

  const handleChange = (newState) => {
    const dataToSave = {
      ...currentState,
      ...(newState || {})
    };

    let descriptionContent = false;
    if (descriptionRef && descriptionRef.current) {
      descriptionContent = descriptionRef.current.getContent();
    }

    if (dataToSave.title && descriptionContent) {
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }

    setState(dataToSave);
    updateState(dataToSave);
  };

  if (loading) {
    return (
      <div className="card-body">
        <Loading
          text="Analyzing Course Data..."
          className='h-100 bg-white position-relative'
          style={{ minHeight: '300px' }}
        />
      </div>
    );
  }

  return (
    <Fragment>
      <div className="card-body">
        <div id="course-title" className="mb-4">
          <label htmlFor="title">
            <div className="font-weight-bold">
              What's the title of this course?
            </div>
          </label>
          <input
            id="title"
            type="text"
            className="form-control form-control-md"
            name="title"
            onChange={(e) => {
              const { name, value } = e.currentTarget;
              handleChange({ [name]: value });
            }}
            defaultValue={title}
            autoFocus
            required
          />
        </div>

        <div className="font-weight-bold mb-2">
          Briefly describe this course
        </div>
        <RenderTinyMceEditor
          id="courseDescription"
          contentId={undefined}
          contentType="class"
          className="mb-3"
          defaultValue={description}
          editorRef={descriptionRef}
          onChange={handleChange}
          uploadImgButton={false}
          uploadPdfButton={false}
          embedMediaButton={false}
          maxCharCount={5000}
          required
        />
      </div>
      <div className="card-footer">
        <button
          className="btn btn-sm btn-primary"
          onClick={handleNext}
          type="button"
          disabled={nextDisabled}
        >
          Next
        </button>
      </div>
    </Fragment>
  );
};
