import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowsAlt } from 'react-icons/fa';
import { ORG_CONSOLE_BASE } from '../../../../constants/routes';
import WidgetLink from './WidgetLink';
import './style.css';

const ListView = ({
  data = null,
  className = '',
  onClickSettings,
  showReorderButton = false,
  showFeaturedIcons = false
}) => {
  const hasData = Array.isArray(data) && data.length > 0;

  return (
    <div className={className}>
      <div className="row">
        {hasData && data.map((widget) => {
          return (
            <Fragment key={widget?.id}>
              <WidgetLink
                showFeaturedIcons={showFeaturedIcons}
                onClickSettings={onClickSettings}
                {...widget}
              />
            </Fragment>
          );
        })}
      </div>

      {showReorderButton && hasData && (
        <div
          className='d-flex justify-content-center border-top pt-4 mt-4'

        >
          <Link
            className="btn btn-sm btn-outline-primary"
            to={`${ORG_CONSOLE_BASE}/widgets/link/reorder`}
          >
            <span className="d-flex align-items-center">
              <FaArrowsAlt size={15} />
              <span className="ml-2">Reorder</span>
            </span>
          </Link>
        </div>
      )}
    </div>
  );
};

export default ListView;
