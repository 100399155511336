import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListView from './ListView';
import Loading from '../../Loading';
import { getWidgets } from '../../../actions/Widgets';
import { IMAGE_TOOLKIT_EMPTY } from '../../../constants/assets';
import LinksList from '../LinksList';

const WidgetsList = ({
  data,
  dataFetched,
  manualFetchData,
  className,
  onClickSettings,
  isFeatured,
  showReorderButton = false,
  showTableView = false,
  showFeaturedIcons = false
}) => {
  const dispatch = useDispatch();
  const organization = useSelector((state) => state.organization);
  const widgetLinks = useSelector((state) => state.widgetLinks);

  const dashboardWidgetIds = useMemo(() => {
    return organization?.dashboardWidgetIds || [];
  }, [organization?.dashboardWidgetIds]);

  const showLoading = useMemo(() => {
    if (manualFetchData && !data && !dataFetched) {
      return true;
    }

    if (!manualFetchData && !widgetLinks?.list && !widgetLinks?.fetched) {
      return true;
    }

    return false;
  }, [data, dataFetched, manualFetchData, widgetLinks?.fetched, widgetLinks?.list]);

  const showNoDataUI = useMemo(() => {
    if (manualFetchData && !data && dataFetched) {
      return true;
    }

    if (!manualFetchData && !widgetLinks?.list && widgetLinks?.fetched) {
      return true;
    }

    return false;
  }, [data, dataFetched, manualFetchData, widgetLinks?.fetched, widgetLinks?.list]);

  useEffect(() => {
    if (!manualFetchData) {
      dispatch(getWidgets({
        orgId: organization?.id,
        ...(isFeatured ? { isFeatured } : {}),
        type: 'link',
        sortByIds: dashboardWidgetIds
      }));
    }
  }, [dashboardWidgetIds, dispatch, isFeatured, manualFetchData, organization?.id]);

  if (showLoading) {
    return (
      <Loading
        text="Loading..."
        className="bg-white h-100 py-5"
      />
    );
  }

  if (showNoDataUI) {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center justify-content-center"
        style={{
          minHeight: '400px'
        }}
      >
        <img
          src={IMAGE_TOOLKIT_EMPTY}
          className='mb-3'
          height={150}
          style={{
            height: '150px'
          }}
          alt="No Widgets"
        />
        <p className="mt-1 mb-3">No link widgets.</p>
      </div>

    );
  }

  if (showTableView) {
    return (
      <LinksList
        data={data || widgetLinks?.list || []}
        showReorderButton={showReorderButton}
      />
    );
  }

  return (
    <ListView
      data={data || widgetLinks?.list || []}
      className={className}
      showReorderButton={showReorderButton}
      onClickSettings={onClickSettings}
      showFeaturedIcons={showFeaturedIcons}
    />
  );
};

export default WidgetsList;
