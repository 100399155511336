import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFlags } from 'flagsmith/react';
import { FaImages, FaStar } from 'react-icons/fa';
import {
  FLAG_PROJECTS_READONLY,
  NOTICE_PROJECTS_READONLY_TITLE
} from '../../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../actions/FeatureFlags';
import { withAuthorization } from '../../Session';
import { ORG_CONSOLE_PROJECTS, ORG_CONSOLE_PROJECT_BASE } from '../../../constants/routes';
import withOrgConsole from '../../App/withOrgConsole';
import OrgConsoleHeader from '../OrgConsoleHeader';
import { getProject, resetProject } from '../../../actions/Projects';
import Loading from '../../Loading';
import Form from './Form';
// import CdnFiles from '../../ManageContent/CdnFiles';
import { getGroups } from '../../../actions/Groups';

const Project = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  const project = useSelector((state) => state.project);
  // Project
  const projectId = params?.projectId || null;

  const flags = useFlags([FLAG_PROJECTS_READONLY]);

  useEffect(() => {
    if (projectId) {
      dispatch(getProject(projectId))
        .then(() => {
          dispatch(getGroups({ orgId: organization?.id, type: 'user' }));
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        });
    }

    return function cleanup() {
      dispatch(resetProject());
    };
  }, [dispatch, organization?.id, projectId]);

  if (flags?.projects_readonly?.enabled && flags?.projects_readonly?.value) {
    dispatch(showFeatureFlagNoticeModal({
      modalTitle: NOTICE_PROJECTS_READONLY_TITLE
    }));
    return;
  }

  if (projectId && !project?.id) {
    return (
      <Loading text='Loading Project...' />
    );
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={project?.id ? 'Projects' : 'New Project'}
            iconComponent={() => <FaImages />}
            route={
              project?.id
                ? `${ORG_CONSOLE_PROJECT_BASE}/${project.id}`
                : ORG_CONSOLE_PROJECTS
            }
          />
          {project?.id && (
            <div className="d-flex align-items-center shadow mt-3 rounded rounded-3 bg-light p-2">
              <h4 className="m-0">{project?.title}</h4>
              {project?.isFeatured && (
                <span className="ml-2 h6 m-0">
                  <span className="badge badge-primary d-flex align-items-center">
                    <FaStar size={15} />
                    <span className="ml-1">Featured</span>
                  </span>
                </span>
              )}
            </div>
          )}

          <Form data={project?.id ? project : {}} />

          {/* {project?.id && (
            <div
              className="shadow rounded mt-3"
            >
              <CdnFiles
                id="project-cdnFiles"
                contentId={project?.id}
                contentType="projects"
                files={project?.cdnFiles}
                fileUpload
                multiple
                editMenu
              />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Project);
