import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentModal } from '../../actions/Modals';
import { canCreateCourseGroup } from '../../permissions/create';

// TODO Rename to AddCourseGroupButton
const AddGroupButton = ({
  className = 'btn btn-sm btn-primary',
  disabled = false,
  children
}) => {
  const dispatch = useDispatch();
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  // User
  const role = currentUser?.role || [];

  const handleClick = () => {
    dispatch(setCurrentModal({
      key: 'manageGroup',
      data: {
        type: 'course',
        editMode: false
      }
    }));
  };

  return (
    <button
      className={className}
      title="Create Group"
      onClick={handleClick}
      disabled={disabled || !canCreateCourseGroup(role)}
      type="button"
    >
      {children || 'Create Group'}
    </button>
  );
};

export default AddGroupButton;
