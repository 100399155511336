import React from 'react';
import { FaCog, FaEllipsisH } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa6';
import { DASHBOARD_MODULES } from '../../../constants/dashboard';

const DotMenu = ({ className, handleRemove, moduleType }) => {
  return (
    <div className={`btn-group align-items-center ${className}`}>
      <button
        className="btn-sm btn-white border text-muted"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        aria-label="Manage Menu"
        title="Manage Menu"
      >
        <FaEllipsisH />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <div className="bg-dark px-4 py-2 text-white text-nowrap">
          {DASHBOARD_MODULES[moduleType]}
        </div>

        <button
          type="button"
          className="dropdown-item border-bottom py-2"
          onClick={handleRemove}
        >
          <span className="d-flex align-items-center">
            <FaTrash color="red" />
            <span className="ml-2">Remove</span>
          </span>
        </button>
        <button
          type="button"
          className="dropdown-item py-2"
          disabled
        >
          <span className="d-flex align-items-center">
            <FaCog />
            <span className="ml-2">Settings</span>
          </span>
        </button>
      </div>
    </div>
  );
};

export default DotMenu;
