import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaBell, FaWindows } from 'react-icons/fa6';
import { FaLink } from 'react-icons/fa';
import { ImEmbed2 } from 'react-icons/im';
import Modal from '../../Modal';
import { resetCurrentModal } from '../../../actions/Modals';

const MODULES_TYPE = [
  {
    title: 'Featured Project',
    icon: <FaWindows size={35} />,
    type: 'PROJECT'
  },
  {
    title: 'Notifications',
    icon: <FaBell size={35} />,
    type: 'NOTIFICATIONS'
  },
  {
    title: 'Embed Widgets',
    icon: <ImEmbed2 size={35} />,
    type: 'EMBED_WIDGETS_LIST'
  },
  {
    title: 'Link Widgets',
    icon: <FaLink size={35} />,
    type: 'LINK_WIDGETS_LIST'
  }
];

const CreateModuleModal = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  const handleClose = () => {
    dispatch(resetCurrentModal());
  };
  const handleSelectModule = (type) => {
    currentModal.callbackPrimaryAction(type);
    handleClose();
  };
  return (
    <Modal
      visible={currentModal.visible}
      close={handleClose}
      theme="dark"
    >
      <div className="card">
        <div className="card-header bg-dark text-white">
          <div>Choose type of module</div>
        </div>
        <div className="card-body container">
          <div className="row">
            {MODULES_TYPE.map((moduleTypeData) => (
              <div
                key={moduleTypeData.type}
                className="col-6 mb-2"
              >
                <ModuleType
                  title={moduleTypeData.title}
                  icon={moduleTypeData.icon}
                  type={moduleTypeData.type}
                  handleSelect={handleSelectModule}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ModuleType = ({
  title = '', icon, handleSelect, type
}) => {
  return (
    <div className="card rounded rounded-2 text-center widget-type-card shadow w-100">
      <div className="border p-3 bg-light">
        <h5 className="m-0">{title}</h5>
      </div>
      <div className="card-body d-flex justify-content-center p-5">{icon}</div>
      <div className="card-footer">
        <button
          className="btn btn-primary btn-sm"
          type="button"
          onClick={() => handleSelect(type)}
        >
          Select
        </button>
      </div>
    </div>
  );
};

export default CreateModuleModal;
