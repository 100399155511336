import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetCurrentModal } from '../../../actions/Modals';
import Modal from '../../Modal';
import UsersSearchSelect from '../../ManageContent/UsersSearchSelect';

const SearchSelectUsers = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  // Modal
  const callbackPrimaryAction = currentModal?.callbackPrimaryAction || null;
  const modalData = currentModal?.data || {};
  const title = modalData?.title || 'Search Users';
  const ids = modalData?.ids || [];
  const searchFilters = modalData?.searchFilters || {};

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal?.visible}
      close={false}
    >
      <UsersSearchSelect
        className='card'
        callbackPrimaryAction={callbackPrimaryAction}
        handleSecondaryAction={handleClose}
        ids={ids}
        searchFilters={searchFilters}
        title={title}
      />
    </Modal>
  );
};

export default SearchSelectUsers;
