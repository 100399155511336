import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import SearchBox from '@apprentage/components/dist/components/SearchBox';
import Collapse from '@apprentage/components/dist/components/Collapse';
import NameBlock from '@apprentage/components/dist/components/NameBlock';
import { roleReadable, sortArrByArr, sortArrByKey } from '@apprentage/utils';
import { FaTimes, FaUsers } from 'react-icons/fa';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import { fetchUsers } from '../../services/users';
import arraysValuesAreEqual from '../../utils/arraysValuesAreEqual';
import DataNotAvailable from '../lists/DataNotAvailable';

const UsersSearchSelect = ({
  autoSearch = false,
  locationId,
  userIdToExclude,
  className = '',
  callbackPrimaryAction,
  handleSecondaryAction,
  ids = null,
  searchFilters,
  searchHelpText = 'Search users by name',
  title = ''
}) => {
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || '';
  const orgType = organization?.type || '';
  // Local State
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(undefined);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedExpanded, setSelectedExpanded] = useState(Array.isArray(ids) && ids.length !== 0);

  const selectFields = [
    'id',
    'name',
    'title',
    'role',
    'profilePicture'
  ];

  useEffect(() => {
    // ids exist and have Array value of at least 1
    if (orgId && Array.isArray(ids) && ids.length !== 0) {
      fetchUsers({
        userIds: ids,
        orgId,
        select: selectFields
      }).then((response) => {
        const whichUsers = Array.isArray(response?.items) ? sortArrByArr(response?.items, ids, 'id') : [];

        setSelectedUsers(whichUsers);
      });
    }
  }, []);

  useEffect(() => {
    if (autoSearch && locationId) {
      fetchUsers({
        ...searchFilters,
        orgId,
        locationId,
        select: selectFields,
        excludeInactive: true,
        excludeTestUsers: true
      }).then((response) => {
        // setSelectedExpanded(false);
        setAllUsers(
          Array.isArray(response?.items) ? excludeSelectedUsers(response?.items, selectedIds) : []
        );
      });
    }
  }, [autoSearch, locationId]);

  const selectedIds = useMemo(() => {
    if (Array.isArray(selectedUsers)) {
      return selectedUsers.map((user) => user.id);
    }

    return [];
  }, [selectedUsers]);

  const excludeSelectedUsers = (rawUsers, userIds) => {
    let results = [];

    results = rawUsers.filter((user) => !userIds.includes(user?.id));

    if (userIdToExclude) {
      results = results.filter((user) => user?.id !== userIdToExclude);
    }

    return results;
  };

  const handleSearchUsers = (newSearchValue) => {
    if (newSearchValue === '' || newSearchValue === ' ' || !orgId) {
      setAllUsers(null);
      setSelectedExpanded(false);
      return;
    }

    setSearchValue(newSearchValue);

    fetchUsers({
      searchValue: newSearchValue,
      ...searchFilters,
      orgId,
      select: selectFields,
      excludeInactive: true,
      excludeTestUsers: true
    }).then((response) => {
      setSelectedExpanded(false);
      setAllUsers(
        Array.isArray(response?.items) ? excludeSelectedUsers(response?.items, selectedIds) : []
      );
    });
  };

  const selectUser = (user) => {
    const newSelectedUsers = [...(selectedUsers || []), user];
    let updatedUsers = allUsers.slice();
    updatedUsers = updatedUsers.filter((u) => u?.id !== user?.id);

    setAllUsers(updatedUsers);
    const userExists = selectedUsers.find((u) => u.id === user.id);

    if (!userExists) {
      setSelectedUsers(newSelectedUsers);
    }
  };

  const removeUser = (user) => {
    let updatedSelectedUsers = selectedUsers.slice();
    updatedSelectedUsers = updatedSelectedUsers.filter((u) => u?.id !== user?.id);

    setSelectedUsers(updatedSelectedUsers);
  };

  const handlePrimaryAction = () => {
    setLoading(true);

    if (callbackPrimaryAction) {
      callbackPrimaryAction(selectedIds);
    }

    if (handleSecondaryAction) {
      handleSecondaryAction();
    }
  };

  return (
    <div
      className={className}
    >
      {title && (
        <div className="card-header">
          <h1 className="h5 m-0">
            {title}
          </h1>
        </div>
      )}
      <div className="card-body">
        {Array.isArray(selectedUsers) && selectedUsers.length !== 0 && (
          <div className="mb-3">
            <Collapse
              id="UserSearchSelect-Collapse"
              title="Selected"
              p={3}
              ariaExpanded={selectedExpanded}
              badge={() => {
                return (
                  <span className="ml-1 badge badge-primary">
                    {selectedUsers.length}
                  </span>
                );
              }}
            >
              {sortArrByKey(selectedUsers, 'name').map((user) => (
                <div
                  key={user.id}
                  className="border p-2 d-flex align-items-center justify-content-between mb-1 position-relative"
                >
                  <NameBlock
                    key={user?.id}
                    name={user?.name}
                    nameSize="md"
                    title={user?.title || roleReadable(user?.role, orgType)}
                    profilePicture={user?.profilePicture || DEFAULT_PROFILE_PIC}
                    pictureSize="sm"
                    className="my-1"
                  />
                  <div className="p-2">
                    <button
                      title="Remove user"
                      aria-label="Remove user"
                      className="btn btn-sm btn-outline-secondary"
                      type="button"
                      onClick={() => {
                        removeUser(user);
                      }}
                    >
                      <FaTimes />
                    </button>
                  </div>
                </div>
              ))}
            </Collapse>
          </div>
        )}

        <div className='card'>
          <div className='card-header p-3'>
            <h6 className='font-weight-bold m-0'>
              Search
            </h6>
          </div>
          <div className='card-body border-bottom'>
            <SearchBox
              className="w-100"
              onSubmit={handleSearchUsers}
              onClear={() => {
                setSelectedExpanded(false);
                setAllUsers(null);

                const searchBox = document.querySelector(
                  'input[name="userName"]'
                );

                searchBox.focus();
              }}
              name="userName"
              value={searchValue}
              loading={loading}
              placeholder="Search by name..."
              autoFocus
            />
            <div className="text-muted mt-1">
              {searchHelpText}
            </div>
          </div>

          <div className="card-body px-2 pb-1 pt-2">
            {(!allUsers || (Array.isArray(allUsers) && allUsers.length === 0)) && (
              <DataNotAvailable
                text="No users found"
                iconComponent={() => <FaUsers size={30} />}
              />
            )}

            {Array.isArray(allUsers) && allUsers.length !== 0 && (
              <>
                {/* <div className="mb-2">
                  <div className="font-weight-bold">Users:</div>
                </div> */}

                {allUsers.map((user) => (
                  <div
                    key={user.id}
                    className="rounded border p-2 d-flex align-items-center justify-content-between mb-2"
                  >
                    <NameBlock
                      key={user?.id}
                      name={user?.name}
                      nameSize="sm"
                      title={user?.title || roleReadable(user?.role, orgType)}
                      profilePicture={user?.profilePicture || DEFAULT_PROFILE_PIC}
                      pictureSize="sm"
                    />

                    <div className="p-2">
                      <button
                        title="Select user"
                        aria-label="Select user"
                        className="btn btn-sm btn-outline-primary"
                        type="button"
                        onClick={() => {
                          selectUser(user);
                          setSelectedExpanded(true);
                        }}
                      >
                        Select
                      </button>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="card-footer d-flex align-items-center bg-light">
        <button
          className="btn btn-primary btn-md"
          disabled={loading || arraysValuesAreEqual((ids || []), selectedIds)}
          type="button"
          onClick={handlePrimaryAction}
        >
          {loading ? 'Saving...' : 'Save'}
        </button>
        {handleSecondaryAction && (
          <button
            onClick={handleSecondaryAction}
            className="btn btn-link ml-2"
            disabled={loading}
            type="button"
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  );
};

export default UsersSearchSelect;
