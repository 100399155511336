import { PROJECTS_PAGINATION_LIMIT } from '../constants/api';
import { fetchProject, fetchProjects } from '../services/projects';
import { getResourcesByParentId } from './Resources';
import {
  SET_PROJECTS,
  RESET_PROJECTS,
  RESET_PROJECT,
  SET_PROJECT,
  SET_ENTRY_ORG_ID,
  SET_PROJECT_FILES
} from './types';

export const getProjects = ({
  orgId,
  locationId,
  isFeatured = null,
  limit = PROJECTS_PAGINATION_LIMIT,
  page = 1,
  order = 'title',
  title = ''
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchProjects({
        locationId,
        orgId,
        isFeatured,
        page,
        limit,
        order,
        title
      })
        .then((response) => {
          const list = response?.items || [];
          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          dispatch({
            type: SET_PROJECTS,
            list,
            pagination: {
              limit,
              total: response?.total,
              page: response?.page || 1,
              rangeFrom: response?.rangeFrom,
              rangeTo: response?.rangeTo
            }
          });

          resolve(response?.items);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const getProject = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchProject(id)
        .then((project) => {
          if (!project?.id) {
            reject(new Error('No project found (id)'));
            return;
          }

          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: project?.orgId || null
          });

          dispatch({
            type: SET_PROJECT,
            project: project?.id ? { ...project, cdnFiles: null } : null
          });
          dispatch(getResourcesByParentId(project?.id, 'projects'));
          resolve(project);
        })
        .catch((error) => {
          console.error('get project', error);
          reject(error);
        });
    });
  };
};

export const getFeaturedProject = ({ orgId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchProjects({
        orgId,
        isFeatured: true,
        limit: 1
      })
        .then((response) => {
          const list = response?.items || [];
          if (list && list[0] && list[0]?.id) {
            dispatch(getProject(list[0]?.id)).then((project) => {
              resolve(project);
            });
          } else {
            dispatch({
              type: SET_PROJECT,
              project: null
            });
          }
        })
        .catch((error) => {
          console.error('getFeaturedProject', error);
          reject(error);
        });
    });
  };
};

export const setProjectFiles = (cdnFiles = []) => {
  return (dispatch, getState) => {
    const { project } = getState();
    return new Promise((resolve) => {
      const newCdnFiles = [
        ...(project?.cdnFiles ? project.cdnFiles : []),
        ...cdnFiles
      ];

      // const sortedCdnFiles = newCdnFiles.sort((a, b) => sortCaseInsensitive(a, b, 'name'));

      dispatch({
        type: SET_PROJECT_FILES,
        cdnFiles: newCdnFiles
      });
      resolve(newCdnFiles);
    });
  };
};

export const resetProject = () => {
  return (dispatch) => {
    dispatch({ type: RESET_PROJECT });
  };
};

export const resetProjects = () => {
  return (dispatch) => {
    dispatch({ type: RESET_PROJECTS });
  };
};
