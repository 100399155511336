export const diataxisPrompts = [
  {
    text: 'Step-by-step Instructions',
    badge: 'How-To',
    type: 'howTo'
  },
  {
    text: 'Demystify Complex Concepts',
    badge: 'Explanation',
    type: 'explanation'
  },
  {
    text: 'Roadmap to Key Information',
    badge: 'Reference',
    type: 'reference'
  },
  {
    text: 'Practical Active Learning Guide', // Guide Learners through Complex Skills
    badge: 'Tutorial',
    type: 'tutorial'
  }
];
export const executivePrompts = [
  {
    text: 'Create an Executive Summary',
    type: 'execSummary'
  }
];
export const defaultPrompts = [
  {
    text: 'Get 3 Question/Answer Pairs',
    type: 'questionAnswerPairs'
  },
  {
    text: 'Get Main Points & Observations',
    type: 'mainPointsObservations'
  },
  {
    text: 'Write a LinkedIn Post',
    type: 'linkedInPost'
  },
  {
    text: 'Draft an Email',
    type: 'email'
  }
];

export const turbinePrompts = [
  ...diataxisPrompts,
  ...executivePrompts,
  ...defaultPrompts
];

export const templateHowToTitle = 'How-To Guide';
export const templateHowTo = `
## How-To Guide

Effective How-To guides tackle practical tasks with clear actions and goals, assuming user awareness and focusing purely on step-by-step instructions without explanations or distractions.

**Title:** Clearly state the action or skill being taught.

**I. Materials:**
* List anything needed to complete the task.

**II. Steps:**
   * Use numbered steps with clear and concise instructions.
   * Avoid unnecessary details or explanations.
   * Focus on the action itself.
   * Use active voice.

**III. Tips (Optional):**
   * Include a short section with helpful hints or troubleshooting advice.
`;

export const templateExecSummaryTitle = 'Executive Summary';
export const templateExecSummary = `
## Executive Summary

**Introduction**

* Briefly describe the project or briefing and its central objective.
* Clearly define the problem being addressed or the opportunity being pursued.

**Key Findings**

* Highlight the most critical insights gleaned from the project/briefing.
* Emphasize findings with the greatest potential impact or benefit to the company.
* Use concise bullet points to present key findings in a clear and scannable format.

**Recommendations**

* Offer actionable steps based on the key findings.
* Clearly link each recommendation to a specific finding.
* Quantify the expected impact of the recommendations whenever possible (e.g., cost savings, revenue growth, etc.).

**Conclusion**

* Summarize the importance of the project/briefing and its findings.
* Issue a clear call to action, urging the reader to act upon the recommendations.
* Emphasize the potential benefits and urgency of taking action.
`;

// **Remember:**

// * Write in concise and active voice, using clear and jargon-free language.
// * Tailor the level of detail to the intended audience - a high-level executive.
// * Create a compelling narrative that captures the essence of the project/briefing.
// * Focus on the critical issues, findings, and recommendations most relevant to decision-making.

export const templateExplainerTitle = 'Explanation';
export const templateExplainer = `
## Explanation

**I. Introduction**

* **Title:** A clear and concise title that piques the reader's interest and accurately reflects the technical concept being explained.
* **Target Audience:** Who is this explanation for? Beginners, those with some technical knowledge, or a specific department/team?
* **Problem/Confusion:** What common misconceptions or challenges does this explanation address?

**II. Background (Optional)**

* Briefly introduce any relevant background information or prerequisite knowledge for a smooth understanding.

**III. Explanation**

* **Break down complex concepts:**  Use clear and concise language, avoiding jargon where possible.
* **Analogies and metaphors:**  Utilize relatable examples or metaphors to simplify complex ideas.
* **Visuals:**  Diagrams, charts, or illustrations can significantly enhance understanding.
* **Step-by-step approach (if applicable):**  For processes or mechanisms, break it down into a logical sequence.
* **Define key terms:**  Introduce and define any essential technical terms used in the explanation.

**IV. Examples (Optional)**

* Provide real-world examples or case studies to solidify understanding and illustrate practical applications.

**V. Conclusion**

* **Summary:** Briefly recap the key takeaways and main points of the explanation.
* **Call to action:**  Encourage further exploration (optional, e.g., suggesting related resources).

**VI. (Optional) Additional Considerations**

* **Complexity Level:**  Tailor the level of detail and complexity to your target audience.
* **Glossary:**  Include a glossary of key terms for easy reference (especially for complex topics).
* **Further Resources:**  Provide links to relevant documentation or articles for deeper exploration.
`;

export const templateTutorialTitle = 'Technical Tutorial';
export const templateTutorial = `
## Technical Tutorial

**I. Introduction**

* **Title:** A clear and concise title that reflects the technical skill being taught.
* **Learning Objectives:**  What will learners be able to do by the end of the tutorial?
* **Target Audience:**  Who is this tutorial for? Beginners, intermediate users, or experts?
* **Prerequisites:** Are there any prior skills or knowledge needed for this tutorial? (Optional)
* **Estimated Time to Complete:**  Optional, but helpful for readers to gauge their time investment.

**II. Setting Up (Optional)**

* **Software/Hardware Requirements:**  List any specific software, tools, or equipment required.
* **Download and Installation Instructions:**  Guide users through setting up any necessary software or tools. (Optional)

**III. Steps**

* **Number each step:**  Use clear and concise language with a focus on action verbs.
* **Screenshots/Videos:**  Incorporate screenshots or video recordings to visually demonstrate each step.
* **Break down complex steps:** Divide lengthy or intricate tasks into smaller, manageable units.
* **Code Snippets (if applicable):**  For coding tutorials, include relevant and well-commented code snippets.

**IV. Testing and Troubleshooting**

* **Test Instructions:**  Guide users on how to test the functionality of what they've built or achieved.
* **Common Issues:**  Address potential challenges users might encounter and provide solutions.

**V. Conclusion**

* **Summary:** Briefly recap the key skills covered in the tutorial.
* **Next Steps:**  Suggest additional learning resources or related tutorials for further exploration. (Optional)

**VI. (Optional) Additional Considerations**

* **Complexity Level:** Tailor the depth and detail of the tutorial to your target audience.
* **Safety Notes:**  Include any important safety precautions if applicable.
* **Interactive Elements (if applicable):**  Consider adding quizzes or interactive exercises to enhance learning.
`;

export const templateReferenceTitle = 'Reference Guide ';
export const templateReference = `
## Reference Guide

An effective Reference Guide offers a clear roadmap to specific information, condensing details and prioritizing organization for quick and easy access.

**Introduction:**

This document serves as a comprehensive reference guide for {**Document Title**}. It provides key information, definitions, and processes tailored to your needs.

**1. Quick-Access Definitions and Key Terms:**

* **Term 1**: Concise definition relevant to the context.
* **Term 2**: Concise definition relevant to the context.
* **Term 3**: Concise definition relevant to the context.

**2. Context-Specific Policies and Processes:**

**2.1. Key Policies:**

* **Policy 1**: Briefly explain the policy and its relevance to the context. Include links to the full policy document if applicable.
* **Policy 2**: Briefly explain the policy and its relevance to the context. Include links to the full policy document if applicable.

**2.2. Key Processes:**

* **Process 1**: Briefly explain the process and its key steps relevant to the context.
* **Process 2**: Briefly explain the process and its key steps relevant to the context.

**3. Navigation Aids:**

* **Table of Contents:**  This document includes a detailed table of contents for easy reference.
* **Hyperlinks:**  Key terms and references are hyperlinked to relevant sections within the document or external resources.
* **Search Function:**  Utilize the search function (if available) to find specific information quickly.

**4. Additional Resources:**

* **Link 1**: Link to a relevant resource document.
* **Link 2**: Link to a relevant resource document.

**5. Revision History:**

* **Date**:  Brief description of changes made to the document.
`;
