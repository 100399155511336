import React from 'react';
import Table from './Table';
import TitleCell from './TitleCell';
import SyndicatedOrgAuthor from './SyndicatedOrgAuthor';
import GroupsCell from './GroupsCell';
import CheckBoxCell from './CheckBoxCell';
import CourseContentCell from './CourseContentCell';

const List = ({
  data,
  courseIds,
  selectedCourseIds,
  handleSelectCourse,
  hiddenColumns
}) => {
  const columns = React.useMemo(() => [
    {
      Header: '',
      accessor: 'createdAt',
      disableSortBy: true,
      Cell: (props) => (
        <CheckBoxCell
          {...props}
          handleSelectCourse={handleSelectCourse}
          selectedCourseIds={selectedCourseIds}
        />
      )
    },
    {
      Header: 'Course',
      accessor: 'title',
      // ...(courseIds ? { disableSortBy: true } : {}),
      Cell: TitleCell
    },
    {
      Header: 'Groups',
      accessor: 'groupsCol',
      Cell: GroupsCell
    },
    {
      Header: 'Content',
      accessor: 'enableCourseContent',
      Cell: CourseContentCell
    },
    {
      Header: 'Syndication',
      accessor: 'orgIds',
      Cell: SyndicatedOrgAuthor
    }
  ], [handleSelectCourse, selectedCourseIds]);

  if (!data || (data && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">
          No Courses.
        </div>
      </div>
    );
  }

  return (
    <Table
      columns={columns}
      data={data}
      courseIds={courseIds}
      hiddenColumns={hiddenColumns}
    />
  );
};

export default List;
