import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Collapse from '@apprentage/components/dist/components/Collapse';
import UserCompetenciesList from '../lists/UserCompetenciesList';

const LogbookView = ({
  className,
  ariaExpanded
}) => {
  // Redux
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Misc
  const competencies = apprenticeship?.competencies || {};
  const competencyGroups = apprenticeship?.competencyGroups || [];

  return (
    <div className={`LogbookView ${className}`}>
      {competencyGroups?.length === 0 ? (
        <div>No competency groups.</div>
      ) : (
        <div>
          {competencyGroups.map((competencyGroup) => {
            const childCompetencies = competencyGroup?.competencyIds?.length
              ? competencyGroup?.competencyIds.map((cId) => competencies[cId])
              : [];

            return (
              <Fragment key={`competencyGroup-${competencyGroup?.id}`}>
                <Collapse
                  id={`competencyGroup-${competencyGroup?.id}`}
                  title={competencyGroup?.description}
                  className="mb-3"
                  p={3}
                  ariaExpanded={competencyGroups.length === 1 || ariaExpanded}
                >
                  <UserCompetenciesList
                    list={childCompetencies}
                    showClassifications
                  />
                </Collapse>
              </Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LogbookView;
