import React, { useMemo, useState } from 'react';
import { FaFilter, FaSpinner } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';
import { toast } from 'react-toastify';
import { daysAgo } from '../../../../../../utils/date';
import { recordApproveMultiple } from '../../../../../../services/logbookRecords';
import { fetchFlags } from '../../../../../../services/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../../../../actions/FeatureFlags';
import { NOTICE_LOGBOOKS_READONLY_TITLE, NOTICE_MAINTENANCE_MODE_BODY } from '../../../../../../constants/flagsmith';

const RecordsFilters = ({
  onChange,
  className = '',
  selectedRecords,
  handleRefreshRecords
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  // Local State
  const [loading, setLoading] = useState(false);

  const hasSelectedRecords = useMemo(() => {
    return Array.isArray(selectedRecords) && selectedRecords.length > 0;
  }, [selectedRecords]);

  const batchApproveRecords = () => {
    setLoading(true);
    fetchFlags().then((fetchedFlags) => {
      if (fetchedFlags?.logbooks_readonly?.enabled && fetchedFlags?.logbooks_readonly?.value) {
        dispatch(showFeatureFlagNoticeModal({
          modalTitle: NOTICE_LOGBOOKS_READONLY_TITLE,
          modalBody: NOTICE_MAINTENANCE_MODE_BODY
          // preventClose: true
        }));
        setLoading(false);
        return;
      }

      toast.info('Approving records...', { toastId: 'approvingRecords' });

      recordApproveMultiple({
        records: selectedRecords,
        currentUserName: currentUser?.name,
        currentUserId: currentUser?.id
      }).then(() => {
        toast.dismiss('approvingRecords');
        toast.success(`${selectedRecords.length} ${pluralize('Record', selectedRecords.length)} approved!`);
        handleRefreshRecords();
      }).catch((error) => {
        console.error(error);
        toast.error('Something went wrong, try again.');
      }).finally(() => {
        setLoading(false);
      });
    });
  };

  return (
    <div
      className={`d-flex align-items-center ${hasSelectedRecords ? 'justify-content-between' : 'justify-content-end'} ${className}`}
    >
      {hasSelectedRecords && (
        <button
          className='btn btn-sm btn-primary'
          onClick={batchApproveRecords}
          disabled={loading}
          type="button"
        >
          {loading ? (
            <span className='d-flex align-items-center'>
              <FaSpinner className='fa-spin' /> Approving...
            </span>
          ) : (
            <span>
              Approve {pluralize('Record', selectedRecords.length)} ({selectedRecords.length})
            </span>
          )}
        </button>
      )}

      <div className='d-flex align-items-center'>
        <FaFilter className='mr-2' />
        <div className='mr-2'>
          <select
            name="gteDate"
            className='form-control form-control-sm'
            style={{
              width: '200px'
            }}
            onChange={(e) => {
              const { value } = e.currentTarget;

              onChange((prev) => {
                const newConfig = {
                  ...prev,
                  page: 1,
                  gteDate: value
                };

                if (value === 'undefined') {
                  newConfig.gteDate = undefined;
                }

                return newConfig;
              });
            }}
          >
            <option selected value="undefined">All Time</option>
            <option value={daysAgo(30).toDateString()}>Last 30 days</option>
            <option value={daysAgo(60).toISOString()}>Last 60 days</option>
            <option value={daysAgo(90).toISOString()}>Last 90 days</option>
            <option value={daysAgo(180).toISOString()}>Last 6 months</option>
          </select>
        </div>
        <div>
          <select
            name='isApproved'
            className='form-control form-control-sm'
            style={{
              minWidth: '150px'
            }}
            onChange={(e) => {
              const { value } = e.currentTarget;

              onChange((prev) => {
                const newConfig = {
                  ...prev,
                  page: 1
                };

                if (value === 'undefined') {
                  newConfig.isApproved = undefined;
                }

                if (value === 'null') {
                  newConfig.isApproved = null;
                }

                if (value === 'true') {
                  newConfig.isApproved = true;
                }

                return newConfig;
              });
            }}
          >
            <option value="undefined" name="All Statuses">
              All Statuses
            </option>
            <option value="true" name="">Approved</option>
            <option value="null" name="">Unapproved</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default RecordsFilters;
