import React from 'react';
import { NameBlock } from '@apprentage/components';
import { useSelector } from 'react-redux';
import { roleReadable } from '@apprentage/utils';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';

const UserCell = ({
  value: userId,
  handleClickRow,
  showRole = false
}) => {
  const organization = useSelector((state) => state.organization);
  const searches = useSelector((state) => state.searches);
  // Searches
  const usersHash = searches?.usersHash || {};
  const user = usersHash[userId];

  if (!user) {
    return null;
  }

  return (
    <NameBlock
      className="text-left"
      name={user?.name}
      {...(handleClickRow
        ? { onClick: () => { handleClickRow(user); } }
        : {})}
      {...(showRole
        ? { role: roleReadable(user?.role, organization?.type) }
        : {})}
      membership={user?.membership}
      testUser={user?.testUser}
      nameSize="sm"
      pictureSize='sm'
      pictureHeight={20}
      pictureWidth={20}
      profilePicture={user?.profilePicture || DEFAULT_PROFILE_PIC}
    />
  );
};

export default UserCell;
