import React from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { DashboardCards } from '../WorkforceSuite/Dashboard/DashboardCards';
import { setSideSheet } from '../../../actions/SideSheets';
import { SHEET_KEY_MATERIAL_TEMPLATE } from '../../../constants/sideSheets';

const DashboardKnowledgeCapture = () => {
  const dispatch = useDispatch();
  return (
    <div className='DashboardKnowledgeCapture'>
      <div className="pt-4 pb-2">
        <div className="row">
          <div className='col'>
            <div>
              <div
                className="h3 m-0"
              >
                Knowledge Capture
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-3 row'>

        <div
          className="col-12 col-sm-4 mb-4"
        >
          <DashboardCards
            title="How-To"
            size="sm"
            titleIconComponent={() => (
              <FaPlusCircle size={20} className='mr-2 text-primary opacity-50' />
            )}
            onClick={() => {
              dispatch(setSideSheet({
                key: SHEET_KEY_MATERIAL_TEMPLATE,
                data: {
                  promptType: 'howTo'
                },
                className: 'MaterialSideSheet'
              }));
            }}
            subTitle="Effectively communicate instructions with our template to format existing documents for better understanding."
          />
        </div>

        <div
          className="col-12 col-sm-4 mb-4"
        >
          <DashboardCards
            title="Explanation"
            mobileTitle="Explain"
            size="sm"
            titleIconComponent={() => (
              <FaPlusCircle size={20} className='mr-2 text-primary opacity-50' />
            )}
            onClick={() => {
              dispatch(setSideSheet({
                key: SHEET_KEY_MATERIAL_TEMPLATE,
                data: {
                  promptType: 'explanation'
                },
                className: 'MaterialSideSheet'
              }));
            }}
            subTitle="Explain anything clearly with our template to transform existing documents into well-structured explanations."
          />
        </div>

        <div
          className="col-12 col-sm-4 mb-4"
        >
          <DashboardCards
            title="Tutorial"
            size="sm"
            titleIconComponent={() => (
              <FaPlusCircle size={20} className='mr-2 text-primary opacity-50' />
            )}
            onClick={() => {
              dispatch(setSideSheet({
                key: SHEET_KEY_MATERIAL_TEMPLATE,
                data: {
                  promptType: 'tutorial'
                },
                className: 'MaterialSideSheet'
              }));
            }}
            subTitle="Craft technical tutorials with our template to transform existing documents into an engaging guide."
          />
        </div>

        <div
          className="col-12 col-sm-6 mb-4"
        >
          <DashboardCards
            title="Reference"
            size="sm"
            titleIconComponent={() => (
              <FaPlusCircle size={20} className='mr-2 text-primary opacity-50' />
            )}
            onClick={() => {
              dispatch(setSideSheet({
                key: SHEET_KEY_MATERIAL_TEMPLATE,
                data: {
                  promptType: 'reference'
                },
                className: 'MaterialSideSheet'
              }));
            }}
            subTitle="Create dynamic reference guides from existing documents that highlight the most relevant details."
          />
        </div>

        <div
          className="col-12 col-sm-6 mb-4"
        >
          <DashboardCards
            title="Executive Summary"
            mobileTitle="Exec. Summary"
            size="sm"
            titleIconComponent={() => (
              <FaPlusCircle size={20} className='mr-2 text-primary opacity-50' />
            )}
            onClick={() => {
              dispatch(setSideSheet({
                key: SHEET_KEY_MATERIAL_TEMPLATE,
                data: {
                  promptType: 'execSummary'
                },
                className: 'MaterialSideSheet'
              }));
            }}
            subTitle="Generate executive summaries with actionable insights that highlight key findings, recommendations, next steps to facilitate strategic decision-making."
          />
        </div>

        {/* <div
                className="col-12 col-sm-4 mb-4"
              >
                <DashboardCards
                  title="Reference"
                  titleIconComponent={() => (
                    <HiDocumentSearch size={20} className='mr-2' />
                  )}
                  // externalLink
                  href={TURBINE_TRAINING_PROGRAM_DOCS}
                  subTitle="Build & Manage your Training Programs."
                />
              </div> */}

      </div>

    </div>
  );
};

export default DashboardKnowledgeCapture;
