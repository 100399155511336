import React from 'react';
import { FaFileAlt } from 'react-icons/fa';
import { fileType } from '../../../../../services/resources';
import File from './file.png';

const NameIcon = ({ type, url, name }) => {
  // File type
  const fType = fileType(type);
  const fileTypeName = fType?.name;
  const fileTypeIcon = fType?.icon;

  if (fileTypeName === 'material') {
    return (
      <FaFileAlt size={30} className='text-muted' />
    );
  }

  if (fileTypeName === 'link') {
    return (
      <i
        className={fileTypeIcon}
        style={{
          fontSize: '1.25rem',
          padding: '5px',
          background: '#027bff',
          color: 'white'
        }}
      />
    );
  }

  if (fileTypeName === 'image') {
    return (
      <img
        src={url}
        alt={name}
        height="30"
        width="30"
        style={{
          height: '30px',
          width: '30px'
        }}
      />
    );
  }

  // return (
  //   <FileType
  //     className={fileTypeIcon}
  //   />
  // );

  return (
    <span
      className="d-inline-block text-center"
      style={{ width: '30px' }}
    >
      <img
        alt="file"
        src={File}
        height="30"
        style={{
          height: '30px'
        }}
      />
    </span>
  );
};

export default NameIcon;
