import { pathOr } from 'ramda';
import { fetchApprenticeship, fetchApprenticeships } from '../services/apprenticeships';
import { fetchUser } from '../services/currentUser';
import { fetchWageSchedule } from '../services/employers';
import { fetchUserProfile } from '../services/userProfiles';
import {
  SET_APPRENTICESHIPS, RESET_APPRENTICESHIPS,
  SET_APPRENTICESHIP, RESET_APPRENTICESHIP,
  SET_APPRENTICESHIP_USER, RESET_APPRENTICESHIP_USER,
  SET_APPRENTICESHIP_USER_WAGE_SCHEDULE,
  SET_APPRENTICESHIP_USER_PROFILE,
  SET_ENTRY_ORG_ID,
  SET_DIRECTORY_USER,
  SET_APPRENTICESHIP_MATERIALS,
  RESET_APPRENTICESHIP_MATERIALS
} from './types';
import { getApprenticeshipUserInstructionProvider } from './InstructionProviders';
import { fetchMaterials } from '../services/materials';
import { resetCronUsers } from './CronUsers';
import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';

export const getApprenticeships = ({
  ids,
  orgId,
  employerId,
  instructionProviderId,
  limit = DEFAULT_PAGINATION_LIMIT,
  page = 1
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchApprenticeships({
        ids,
        orgId,
        employerId,
        instructionProviderId,
        page,
        limit
      }).then((response) => {
        const list = response?.items || [];
        // ORG CHECK
        dispatch({
          type: SET_ENTRY_ORG_ID,
          entryOrgId: list && list[0] ? list[0]?.orgId : null
        });

        dispatch({
          type: SET_APPRENTICESHIPS,
          list,
          pagination: {
            limit,
            total: response?.total,
            page: response?.page || 1,
            rangeFrom: response?.rangeFrom,
            rangeTo: response?.rangeTo
          }
        });

        resolve(list);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const getApprenticeship = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchApprenticeship(id).then((apprenticeship) => {
        // ORG CHECK
        dispatch({
          type: SET_ENTRY_ORG_ID,
          entryOrgId: apprenticeship?.orgId || null
        });

        dispatch(getApprenticeshipMaterials({
          apprenticeshipId: apprenticeship?.id,
          orgId: apprenticeship?.orgId
        }));
        dispatch({ type: SET_APPRENTICESHIP, apprenticeship });
        resolve(apprenticeship);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const getApprenticeshipMaterials = ({
  apprenticeshipId,
  orgId,
  limit = 1,
  page = 1,
  order = '-createdAt'
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchMaterials({
        orgId,
        apprenticeshipId,
        page,
        limit,
        order
      }).then((response) => {
        const list = response?.items || [];
        // ORG CHECK
        dispatch({
          type: SET_ENTRY_ORG_ID,
          entryOrgId: list && list[0] ? list[0]?.orgId : null
        });

        dispatch({
          type: SET_APPRENTICESHIP_MATERIALS,
          list,
          pagination: {
            limit,
            total: response?.total,
            page: response?.page || 1,
            rangeFrom: response?.rangeFrom,
            rangeTo: response?.rangeTo
          }
        });
        resolve(response);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

/**
 *
 * Apprentice User Wage Schedule
 *
 */
export const getApprenticeshipUserWageSchedule = ({
  wageScheduleId = '',
  wageSchedules = {},
  apprenticeshipId = ''
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // Given: wageScheduleId, use it
      let id = wageScheduleId;

      // Given: no wageScheduleId is provided
      // And: wageSchedules has an entry for the apprenticeshipId
      if (!id && apprenticeshipId && wageSchedules[apprenticeshipId] !== undefined) {
        id = wageSchedules[apprenticeshipId];
      }

      if (!id) {
        console.error('getApprenticeshipUserWageSchedule: missing required arguments');
        resolve(null);
        return;
      }

      fetchWageSchedule(id).then((userWageSchedule) => {
        dispatch({
          type: SET_APPRENTICESHIP_USER_WAGE_SCHEDULE,
          userWageSchedule
        });
        resolve(userWageSchedule);
      }).catch((error) => {
        console.error('getApprenticeshipUserWageSchedule', error);
        reject(error);
      });
    });
  };
};

/**
 *
 * Apprentice User Profile
 *
 */
export const getApprenticeshipUserProfile = (userProfileId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!userProfileId) {
        const customError = new Error('getApprenticeshipUserProfile: missing required arguments, userProfileId');
        reject(customError);
      }

      fetchUserProfile(userProfileId).then((userProfile) => {
        dispatch({
          type: SET_APPRENTICESHIP_USER_PROFILE,
          userProfile
        });
        resolve(userProfile);
      }).catch((error) => {
        console.error('getApprenticeshipUserProfile', error);
        reject(error);
      });
    });
  };
};

export const getApprenticeshipUser = (userId, apprenticeshipId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchUser({
        userId
        // select: [
        //   'id',
        //   'userProfileId',
        //   'name',
        //   'locationId',
        //   'isApprentice',
        //   'email',
        //   'apprenticeshipIds'
        // ]
      }).then((user) => {
        dispatch({ type: SET_APPRENTICESHIP_USER, user });
        dispatch({ type: SET_DIRECTORY_USER, directoryUser: user });

        if (user?.userProfileId) {
          /**
           * User Profile
           */

          dispatch(getApprenticeshipUserProfile(user.userProfileId)).then((userProfile) => {
            const wageSchedules = pathOr({}, ['wageSchedules'], userProfile);
            const instructionProviders = pathOr({}, ['instructionProviders'], userProfile);
            /**
             * User Wage Schedule
             */
            if (apprenticeshipId && wageSchedules[apprenticeshipId] !== undefined) {
              dispatch(getApprenticeshipUserWageSchedule({
                wageSchedules,
                apprenticeshipId
              }));
            }
            /**
             * User Instruction Provider
             */
            if (apprenticeshipId && instructionProviders[apprenticeshipId] !== undefined) {
              dispatch(getApprenticeshipUserInstructionProvider({
                instructionProviders,
                apprenticeshipId
              }));
            }

            resolve(user);
          });
          return;
        }

        resolve(user);
      }).catch((error) => {
        console.error('getApprenticeshipUser', error);
        reject(error);
      });
    });
  };
};

export const resetApprenticeshipUser = () => {
  return (dispatch) => {
    dispatch({ type: RESET_APPRENTICESHIP_USER });
  };
};

export const resetApprenticeship = () => {
  return (dispatch) => {
    dispatch({ type: RESET_APPRENTICESHIP });
    dispatch({ type: RESET_APPRENTICESHIP_MATERIALS });
    dispatch(resetCronUsers());
  };
};

export const resetApprenticeships = () => {
  return (dispatch) => {
    dispatch({ type: RESET_APPRENTICESHIPS });
  };
};
