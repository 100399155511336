import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { deleteProject } from '../../../services/projects';
import { canDeleteEntry } from '../../../services/currentUser';
import { ORG_CONSOLE_PROJECTS } from '../../../constants/routes';
import DeleteContent from '.';
import TaskStatus from './TaskStatus';

const DeleteProject = ({
  className = '',
  contentId,
  title,
  contentType = 'projects',
  buttonText = 'Delete Project'
}) => {
  const history = useHistory();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const project = useSelector((state) => state.project);
  // Misc
  const role = currentUser?.role || [];
  // Local State
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    $('#collapse-deleteContentHowTo').collapse('hide');
  }, [contentId]);

  const canDelete = () => {
    if (!canDeleteEntry(role)) {
      return false;
    }

    if (Array.isArray(project.cdnFiles) && project.cdnFiles.length !== 0) {
      return false;
    }

    return true;
  };

  const showDeleteButton = canDelete();

  const handleDelete = () => {
    setLoading(true);
    toast.info('Processing...', { toastId: 'deleteProjectInfo' });

    deleteProject(contentId).then(() => {
      toast.dismiss('deleteProjectInfo');
      toast.success(`${title} Deleted!`);
      history.replace(ORG_CONSOLE_PROJECTS);
    }).catch((error) => {
      console.error(`deleteEntry Entry: ${contentType}`, error);
    });
  };

  return (
    <DeleteContent
      id={contentId}
      title={title}
      contentType={contentType}
      buttonText={buttonText}
      handleDelete={handleDelete}
      showDeleteButton={showDeleteButton}
      loading={loading}
      className={className}
    >
      <ol className="m-0 px-4">
        <li className="mb-2">
          Delete all of this Project's Images <TaskStatus statusType="files" complete={!project.cdnFiles || project.cdnFiles.length === 0} />
        </li>
      </ol>
    </DeleteContent>
  );
};

export default DeleteProject;
