import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import { ICON_SEND } from '@apprentage/components/dist/constants/icons';
import Icon from '@apprentage/components/dist/components/Icon';
import {
  inviteTypeEnum,
  INVITE_TYPE_EMPLOYER_AGREEMENT_SIGNER_MGMT,
  INVITE_TYPE_APPRENTICE_AGREEMENT_SIGNER_APPRENTICE,
  INVITE_TYPE_EMPLOYER_PROFILE_UPDATE,
  INVITE_TYPE_REGISTRATION,
  ROLE_STUDENT,
  rolesEnum
} from '@apprentage/constants';
import {
  isProgramManager,
  isProgramTrainer,
  reactSelectDefaultValue,
  reactSelectOptionsFromArray,
  reactSelectOptionsFromEnum
} from '@apprentage/utils';
import { resetCurrentModal } from '../../../actions/Modals';
import { sendEmailUserInviteHtml } from '../../../services/mailer';
import generateRandomNumber from '../../../utils/generateRandomNumber';
import { createInvite, generateInviteLink } from '../../../services/invites';
import { getInvites } from '../../../actions/Invites';
import { createEmployerAgreement } from '../../../services/employerAgreements';
import { getEmployerAgreements } from '../../../actions/EmployerAgreements';
import { canAccessAllLocations } from '../../../permissions/access';
import { TURBINE, TURBINE_ADMIN } from '../../../constants/urls';
import Modal from '../../Modal';
import SelectMenu from '../../ManageContent/SelectMenu';
import OrgLocation from '../../OrgLocation';
import { createApprenticeAgreement } from '../../../services/apprenticeAgreements';
import { getApprenticeAgreements } from '../../../actions/ApprenticeAgreements';

const UserInvitation = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const currentModal = useSelector((state) => state.currentModal);
  const locations = useSelector((state) => state.locations);
  const employerProfile = useSelector((state) => state.employerProfile);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const employerAgreement = useSelector((state) => state.employerAgreement);
  const apprenticeAgreement = useSelector((state) => state.apprenticeAgreement);
  const wageSchedules = useSelector((state) => state.wageSchedules);
  const course = useSelector((state) => state.course);
  // const courseCohorts = useSelector((state) => state.courseCohorts);
  // Organization
  const orgSlug = pathOr('', ['slug'], organization);
  // Current User
  const currentUserRole = pathOr([], ['role'], currentUser);
  const currentUserName = pathOr('', ['name'], currentUser);
  // Current Modal
  const modalData = pathOr({}, ['data'], currentModal);
  const modalTitle = pathOr('New Invitation', ['modalTitle'], modalData);
  const inviteTypeTitle = pathOr('', ['inviteTypeTitle'], modalData);
  const inviteTypes = pathOr(null, ['inviteTypes'], modalData); // {}
  const includeName = pathOr(false, ['includeName'], modalData);
  const includeTitle = pathOr(false, ['includeTitle'], modalData);
  const includeRole = pathOr(false, ['includeRole'], modalData);
  // Training Program
  const apprenticeshipInvitation = Boolean(apprenticeship?.id);
  const apprenticeshipId = pathOr('', ['id'], apprenticeship);
  const apprenticeshipTitle = pathOr('', ['title'], apprenticeship);
  // Training Program : User
  const apprenticeshipUser = pathOr(null, ['user'], apprenticeship);
  const apprenticeshipUserProfile = pathOr(null, ['userProfile'], apprenticeship);
  const apprenticeshipUserWageSchedule = pathOr(null, ['userWageSchedule'], apprenticeship);
  const apprenticeshipUserInstructionProvider = pathOr(null, ['userInstructionProvider'], apprenticeship);
  // Course
  const courseInvitation = Boolean(course?.id);
  const classId = pathOr('', ['id'], course);
  const courseTitle = pathOr('', ['title'], course);
  // Organization
  const orgId = pathOr('', ['id'], organization);
  const orgType = pathOr('', ['id'], organization);
  const orgName = pathOr('', ['name'], organization);
  const orgEmail = pathOr('', ['email'], organization);
  const orgLogo = pathOr('', ['orgLogo'], organization);
  // Employer Profile
  const employerProfileId = pathOr('', ['id'], employerProfile);
  // Local State
  const [loading, setLoading] = useState(false);
  const [inviteType, setInviteType] = useState(modalData?.inviteType || '');
  const [firstName, setFirstName] = useState(modalData?.firstName || '');
  const [lastName, setLastName] = useState(modalData?.lastName || '');
  const [title, setTitle] = useState(modalData?.title || '');
  const [email, setEmail] = useState(modalData?.email || '');
  const allowedRoles = pathOr([], ['allowedRoles'], modalData);
  const [firstRole] = allowedRoles;
  const [role, setRole] = useState(
    modalData?.role || firstRole || ROLE_STUDENT
  );
  const [locationId, setLocationId] = useState(
    modalData?.locationId || currentUser?.locationId
  );

  const inviteTypeEditable = Boolean(!modalData?.inviteType);
  const locationEditable = Boolean(!modalData?.locationId);
  const roleEditable = Boolean(!modalData?.role);

  // if employerAgreement exists, only allow update employer profile
  const allowedInviteTypeEnum = useMemo(() => {
    if (inviteTypes) {
      return inviteTypes;
    }

    return inviteTypeEnum;
  }, [inviteTypes]);

  const inviteTypeOptions = reactSelectOptionsFromEnum(allowedInviteTypeEnum);
  const inviteTypeDefaultValue = reactSelectDefaultValue(
    inviteType,
    inviteTypeOptions
  );

  const roleOptions = useMemo(() => {
    let options = reactSelectOptionsFromEnum(rolesEnum);
    // Filter by allowedRoles
    options = options.filter((r) => allowedRoles.includes(r.value));
    // Change terminology of "Student" role
    options = options.map((r) => {
      if (r.value === 'student') {
        if (orgType === 'workforce') {
          r.label = 'User';
        }
        if (apprenticeship?.id) {
          r.label = 'Trainee';
        }

        return r;
      }
      return r;
    });

    return options;
  }, [apprenticeship?.id, orgType]);
  const roleDefaultValue = reactSelectDefaultValue(role, roleOptions);

  const locationOptions = useMemo(() => {
    let allowedLocations = !locations.list ? [] : [...locations.list];

    if (!canAccessAllLocations(currentUserRole)) {
      allowedLocations = allowedLocations.filter((l) => !l.defaultLocation);
    }

    const options = reactSelectOptionsFromArray(allowedLocations, 'name');
    return options;
  }, [locations?.list]);
  const locationDefaultValue = reactSelectDefaultValue(
    locationId,
    locationOptions
  );

  const locationName = useMemo(() => {
    if (locationId) {
      const orgLocation = locations.hash[locationId];

      if (orgLocation !== undefined) {
        return orgLocation?.name;
      }
    }

    return 'Employer';
  }, [locationId]);

  const formIsDisabled = useMemo(() => {
    if (includeName && (!firstName.length || !lastName.length)) {
      return true;
    }

    if (includeTitle && !title) {
      return true;
    }

    if (includeRole && !role) {
      return true;
    }

    if (!email) {
      return true;
    }

    return false;
  }, [firstName, lastName, email, title]);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  // Support existing invitation architecture
  const registerLink = useMemo(() => {
    // const cohortId = courseCohortId;

    let url = `${TURBINE}/login/${orgSlug}`;
    const params = {
      action: 'signup'
    };

    // Class
    if (course?.id) {
      params.classId = course.id;

      // Cohort
      // if (cohortId) {
      //   url += `&cohortId=${cohortId}`;
      // }

      // Waive Costs
      // if (waiveCosts) {
      //   url += `&wc=${waiveCosts}`;
      // }

      // Invitation Only (inviteOnly)
      if (course?.inviteOnly !== undefined) {
        params.io = course.inviteOnly;
      }
    }

    if (firstName && lastName) {
      params.userName = `${firstName} ${lastName}`;
    }

    if (email) {
      params.userEmail = email;
    }

    if (locationId) {
      params.userLocationId = locationId;
    }

    if (includeRole && role) {
      params.userRole = role;
    }

    if (apprenticeshipId) {
      params.apprenticeshipId = apprenticeshipId;

      if (isProgramManager([role])) {
        params.continueUrl = `${TURBINE_ADMIN}/org/workforce/apprenticeships/${apprenticeshipId}?orgId=${orgId}`;
      }

      if (isProgramTrainer([role])) {
        params.continueUrl = `${TURBINE_ADMIN}/org/workforce/apprenticeships/${apprenticeshipId}/users?orgId=${orgId}`;
      }
    }

    const queryParams = new URLSearchParams(params);

    url += `?${queryParams.toString()}`;

    return url;
  }, [
    orgId,
    email,
    apprenticeshipId,
    course?.id,
    course?.inviteOnly,
    firstName,
    includeRole,
    lastName,
    locationId,
    orgSlug,
    role
  ]);

  const createInvitation = (inviteData = {}) => {
    const accessCode = generateRandomNumber(6);
    const dataToSave = {
      firstName,
      lastName,
      email,
      ...(title.length ? { title } : {}),
      inviteType,
      accessCode,
      sentAt: new Date().toISOString(),
      viewedAt: '',
      // Invite Specific Details
      orgId,
      locationId,
      ...(inviteType === INVITE_TYPE_REGISTRATION ? { registerLink } : {}),
      ...inviteData
    };

    const invitesConfig = {
      // All Locations or the location the currentUser has access to
      ...(canAccessAllLocations(currentUserRole) ? {} : { locationId }),
      // Apprenticeship
      ...(apprenticeship?.id ? { apprenticeshipId } : {}),
      // Employer Profile
      ...(employerProfile?.id ? { employerProfileId } : {}),
      // Registration
      ...(inviteType === INVITE_TYPE_REGISTRATION
        ? { inviteType: INVITE_TYPE_REGISTRATION }
        : {})
    };

    const apprenticeshipConfigs = apprenticeshipInvitation
      ? { apprenticeshipInvitation, apprenticeshipTitle }
      : {};

    const courseConfigs = courseInvitation
      ? { courseInvitation, courseTitle }
      : {};

    const emailConfig = {
      orgName,
      orgEmail,
      orgLogo,
      locationName,
      currentUserName,
      ...(includeName ? { fullName: `${firstName} ${lastName}` } : {}),
      inviteType,
      emails: [email],
      accessCode,
      ...apprenticeshipConfigs,
      ...courseConfigs
    };

    createInvite(dataToSave).then((inviteId) => {
      // Send Invite Email
      sendEmailUserInviteHtml({
        ...emailConfig,
        inviteLink: generateInviteLink(inviteId)
      })
        .then(() => {
          dispatch(getInvites(invitesConfig)).then(() => {
            toast.success('Invitation sent!');
            handleClose();
          });
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!inviteType) {
      toast.error('Type of Invitation is required!');
      return false;
    }

    toast.info('Sending...');

    setLoading(true);

    if (inviteType === INVITE_TYPE_EMPLOYER_AGREEMENT_SIGNER_MGMT) {
      // Check if there is currently an employerAgreement for this employer & apprenticeship
      if (employerAgreement?.id) {
        createInvitation({
          employerAgreementId: employerAgreement?.id,
          employerProfileId,
          apprenticeshipId
        });
      } else {
        createEmployerAgreement({
          orgId,
          locationId,
          accessCode: generateRandomNumber(6),
          name: 'Employer Acceptance Agreement – Appendix E',
          employerProfileId,
          apprenticeshipId,
          wageSchedules: wageSchedules.list
        }).then((newEmployerAgreementId) => {
          createInvitation({
            employerAgreementId: newEmployerAgreementId,
            employerProfileId,
            apprenticeshipId
          });
          dispatch(
            getEmployerAgreements({
              employerProfileId,
              apprenticeshipId
            })
          );
        });
      }

      return;
    }

    if (inviteType === INVITE_TYPE_APPRENTICE_AGREEMENT_SIGNER_APPRENTICE) {
      // Check if there is currently an apprenticeAgreement for this employer & apprenticeship
      if (apprenticeAgreement?.id) {
        createInvitation({
          apprenticeAgreementId: apprenticeAgreement?.id,
          apprenticeshipId
        });
      } else {
        createApprenticeAgreement({
          orgId,
          locationId,
          accessCode: generateRandomNumber(6),
          name: 'Apprentice Agreement – Appendix B',
          apprenticeshipId,
          // Training Program: User
          userId: apprenticeshipUser?.id,
          employerAgreementId: employerAgreement?.id,
          userProfileId: apprenticeshipUserProfile?.id,
          wageScheduleId: apprenticeshipUserWageSchedule?.id,
          instructionProviderId: apprenticeshipUserInstructionProvider?.id
        }).then((newApprenticeAgreementId) => {
          createInvitation({
            apprenticeAgreementId: newApprenticeAgreementId,
            apprenticeshipId
          });
          dispatch(
            getApprenticeAgreements({
              userProfileId: apprenticeshipUserProfile?.id,
              apprenticeshipId
            })
          );
        });
      }

      return;
    }

    if (inviteType === INVITE_TYPE_EMPLOYER_PROFILE_UPDATE) {
      createInvitation({
        employerProfileId,
        apprenticeshipId
      });
      return;
    }

    if (inviteType === INVITE_TYPE_REGISTRATION) {
      createInvitation({
        role,
        ...(apprenticeshipInvitation ? { apprenticeshipId } : {}),
        ...(courseInvitation ? { classId } : {})
      });
    }
  };

  if (!currentModal?.visible) {
    return null;
  }

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal?.visible}
      close={handleClose}
    >
      <div className="card">
        <div className="card-header">
          <h1 className="h5 m-0">{modalTitle}</h1>
        </div>

        <form
          id="invite-user"
          name="invite-user"
          onSubmit={onSubmit}
        >
          <div className="card-body pb-4">
            {inviteTypeTitle && <p className="mb-4">{inviteTypeTitle}</p>}

            <div className="mb-3">
              <div className="mb-2 font-weight-bold">
                Employer: <span className="text-danger">*</span>
              </div>
              {locationEditable ? (
                <div className="d-flex align-items-center w-100">
                  {locationDefaultValue && (
                    <OrgLocation
                      locationId={locationId}
                      className="h6 m-0"
                      imageHeight={30}
                      showOnlyImage
                    />
                  )}

                  <div style={{ flexGrow: 1 }}>
                    <SelectMenu
                      id="locationId"
                      name="locationId"
                      autoComplete="new-location"
                      options={locationOptions}
                      defaultValue={locationDefaultValue}
                      onChange={({ locationId: newLocationId }) => {
                        setLocationId(newLocationId);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <OrgLocation
                  locationId={locationId}
                  className="h6 m-0"
                  imageHeight={30}
                />
              )}
            </div>

            {inviteTypeEditable && (
              <div className="mb-3">
                <div className="mb-2 font-weight-bold">
                  Type of Invitation: <span className="text-danger">*</span>
                </div>
                <SelectMenu
                  onChange={({ inviteType: newInviteType }) => {
                    setInviteType(newInviteType);
                  }}
                  defaultValue={inviteTypeDefaultValue}
                  name="inviteType"
                  options={inviteTypeOptions}
                />
              </div>
            )}

            {includeRole && (
              <div className="mb-3">
                <div className="mb-2 font-weight-bold">
                  User Role: <span className="text-danger">*</span>
                </div>
                <SelectMenu
                  onChange={({ role: newRole }) => {
                    setRole(newRole);
                  }}
                  defaultValue={roleDefaultValue}
                  name="role"
                  options={roleOptions}
                  disabled={!roleEditable}
                />
              </div>
            )}

            {includeName && (
              <div className="mb-3 d-flex">
                <div className="mr-3 flex-fill">
                  <label
                    htmlFor="firstName"
                    className="font-weight-bold"
                  >
                    First Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    className="form-control"
                    value={firstName}
                    required
                    autoFocus
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setFirstName(value);
                    }}
                  />
                </div>
                <div className="flex-fill">
                  <label
                    htmlFor="lastName"
                    className="font-weight-bold"
                  >
                    Last Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    value={lastName}
                    required
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setLastName(value);
                    }}
                  />
                </div>
              </div>
            )}

            {includeTitle && (
              <div className="mb-3">
                <label
                  htmlFor="title"
                  className="font-weight-bold"
                >
                  Title
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  value={title}
                  // required
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setTitle(value);
                  }}
                />
                <div className="small mt-1">
                  Ex: President, Director of Education, etc.
                </div>
              </div>
            )}

            <div>
              <label
                htmlFor="email"
                className="font-weight-bold"
              >
                Email <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                name="email"
                className="form-control"
                value={email}
                required
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setEmail(value);
                }}
              />
            </div>
          </div>
          <div className="card-footer d-flex justify-content-between align-items-center">
            <button
              title="Send Invitation"
              aria-label="Send Invitation"
              className={`btn ${formIsDisabled ? 'btn-secondary' : 'btn-primary'}`}
              type="submit"
              disabled={loading || formIsDisabled}
            >
              <Icon
                className={ICON_SEND}
                size={15}
              />
              <span className="ml-1">{loading ? 'Sending...' : 'Send'}</span>
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UserInvitation;
