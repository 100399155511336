import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'flagsmith/react';
import {
  FaAddressCard,
  FaCog,
  FaEllipsisH,
  FaExclamationCircle,
  FaExternalLinkSquareAlt,
  FaListOl,
  FaPencilAlt,
  FaSortAmountUp,
  FaTimes,
  FaUser
} from 'react-icons/fa';
import NameBlock from '@apprentage/components/dist/components/NameBlock';
import { roleReadable } from '@apprentage/utils';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { getDirectoryUser, setDirectoryUserEditing } from '../../../../actions/Directory';
import {
  canAccessAllUsers,
  canDirectMessageUser,
  canManageDirectoryUsers,
  isActiveMembership
} from '../../../../services/currentUser';
import {
  FLAG_USERS_READONLY,
  NOTICE_USERS_READONLY_BODY,
  NOTICE_USERS_READONLY_TITLE
} from '../../../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../../actions/FeatureFlags';
import { getGroups, getOrgUserGroupIds } from '../../../../actions/Groups';
import { setCurrentModal } from '../../../../actions/Modals';
import {
  CLASS_CONSOLE_QUIZ_OUTCOMES,
  ORG_CONSOLE_USER,
  ORG_CONSOLE_USERS,
  ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS
} from '../../../../constants/routes';
import { routeWithClassId } from '../../../../services/courses';
import { TURBINE_ADMIN } from '../../../../constants/urls';
import { resetSideSheet } from '../../../../actions/SideSheets';
import Loading from '../../../Loading';
import ContactBlock from './ContactBlock';
import WorkBlock from './WorkBlock';
import EditForms from './EditForms';
import SkillsTags from '../../../SkillsTags';
import UserGroupsBlock from './UserGroupsBlock';
import DirectMessageButton from '../../../btns/DirectMessageButton';
import SendEmailButton from '../../../btns/SendEmailButton';
import KeyValueItem from '../../../KeyValueItem';
import ApprenticeshipsList from '../../ApprenticeshipsList';
import './style.css';

const UserDetails = ({
  className = '',
  style = {},
  contentId,
  showNameBlock,
  hideClose,
  handleClose
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const isUserRoute = useRouteMatch(ORG_CONSOLE_USER);
  const history = useHistory();
  const flags = useFlags([FLAG_USERS_READONLY]);
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const directoryUser = useSelector((state) => state.directoryUser);
  const sideSheet = useSelector((state) => state.sideSheet);
  const course = useSelector((state) => state.course);
  // Organization
  const orgId = organization?.id || '';
  const orgType = organization?.type || '';
  const enableUserGroups = organization?.enableUserGroups || false;
  const integration = organization?.integration || false;
  const integrateSlack = organization?.integrateSlack || false;
  // User
  const role = currentUser?.role || [];
  const userId = currentUser?.id || '';
  // Directory User
  const directoryUserEditing = directoryUser?.directoryUserEditing || false;
  const directoryUserId = directoryUser?.id || '';
  const name = directoryUser?.name || '';
  const email = directoryUser?.email || '';
  const slackUserId = directoryUser?.slackUserId || '';
  const membership = directoryUser?.membership || [];
  const apprenticeshipIds = directoryUser?.apprenticeshipIds || [];
  const title = 'User Information';
  // Sheet
  const route = sideSheet?.data?.route || '';
  // Params
  const deepLinkActiveTab = params?.deepLinkActiveTab || '';

  const handleCancel = () => {
    dispatch(setDirectoryUserEditing(false));

    if (deepLinkActiveTab && handleClose) {
      console.log('deepLinkActiveTab', deepLinkActiveTab);
      handleClose();
    }
  };

  const handleEditUserDetails = () => {
    if (flags?.users_readonly?.enabled && flags?.users_readonly?.value) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_USERS_READONLY_TITLE,
        modalBody: NOTICE_USERS_READONLY_BODY
        // preventClose: true
      }));
      return;
    }

    // User Groups
    dispatch(getOrgUserGroupIds({ orgId })).then((userGroupIds) => {
      dispatch(getGroups({
        orgId,
        type: 'user',
        sortByIds: userGroupIds
      }));
    });

    dispatch(getDirectoryUser({ orgId, userId: directoryUserId })).then(() => {
      dispatch(setDirectoryUserEditing(true));
    });
  };

  const onClickAdvancedSettings = () => {
    if (flags?.users_readonly?.enabled && flags?.users_readonly?.value) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_USERS_READONLY_TITLE,
        modalBody: NOTICE_USERS_READONLY_BODY
        // preventClose: true
      }));
      return;
    }

    dispatch(setCurrentModal({
      key: 'userSettings',
      data: {
        user: directoryUser
      }
    }));
  };

  const onClickApprenticeshipRow = (apprenticeshipId) => {
    const url = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}/users/${directoryUserId}`;

    history.push(url);
    dispatch(resetSideSheet());
  };

  if (contentId && !directoryUserId) {
    return (
      <div className="col">
        <div className="card mb-1 border-bottom h-100">
          <div className="card-body p-0">
            <Loading
              className="position-relative bg-white text-dark"
            />
          </div>
        </div>
      </div>
    );
  }

  if (directoryUserEditing) {
    return (
      <EditForms
        handleCancel={handleCancel}
      />
    );
  }

  const canEditUserDetails = canManageDirectoryUsers(role) || directoryUserId === userId;

  return (
    <div
      className={`card mb-1 border-bottom ${className || ''}`}
      style={style}
    >
      <div className='card-header d-flex align-items-center justify-content-between'>
        <h6 className="m-0 font-weight-bold">
          <span className='d-flex align-items-center'>
            <FaUser size={20} />
            <span className='ml-1 text-nowrap'>
              {title}
            </span>
          </span>
        </h6>

        {canEditUserDetails ? (
          <div className="btn-group align-items-center small">
            <button
              className="py-0 btn btn-link btn-sm rounded text-ships-officer"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              title="Manage Menu"
              aria-label='Manage Menu'
            >
              <FaEllipsisH size={20} />
            </button>
            <div className="dropdown-menu dropdown-menu-right p-0">
              {!hideClose && (
                <button
                  onClick={handleClose}
                  className="dropdown-item p-2 border-bottom"
                  type='button'
                >
                  <span className='d-flex align-items-center'>
                    <FaTimes size={15} />
                    <span className='ml-2 small'>
                      Close
                    </span>
                  </span>
                </button>
              )}

              {integration && integrateSlack && slackUserId && canDirectMessageUser(role, orgType) && (
                <DirectMessageButton
                  name={name}
                  organization={organization}
                  slackUserId={slackUserId}
                  buttonText="Slack"
                  className="dropdown-item border-bottom p-2 small"
                />
              )}

              <SendEmailButton
                text="Send Email"
                userName={name}
                userEmail={email}
                className="dropdown-item border-bottom p-2 small"
              />

              {canEditUserDetails && (
                <button
                  className="dropdown-item p-2 border-bottom"
                  type="button"
                  title={`Edit ${name}`}
                  onClick={handleEditUserDetails}
                >
                  <span className='d-flex align-items-center'>
                    <FaPencilAlt size={15} />
                    <span className='ml-2 small'>
                      Edit Information
                    </span>
                  </span>
                </button>
              )}

              {course?.id && (
                <a
                  title={`View ${name}'s Quiz Scores`}
                  className="dropdown-item border-bottom p-2"
                  href={routeWithClassId({
                    route: `${TURBINE_ADMIN + CLASS_CONSOLE_QUIZ_OUTCOMES}/${directoryUserId}`,
                    classId: course?.id
                  })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className=' d-flex align-items-center justify-content-between'>
                    <span className='d-flex align-items-center'>
                      <FaListOl size={15} />
                      <span className='ml-2 small'>
                        View Quiz Scores
                      </span>
                    </span>
                    <FaExternalLinkSquareAlt size={15} className='opacity-50' />
                  </span>
                </a>
              )}

              {canAccessAllUsers(role, orgType) && (course?.id || !isUserRoute) && (
                <a
                  className="dropdown-item border-bottom p-2"
                  href={`${TURBINE_ADMIN + ORG_CONSOLE_USERS}/${directoryUserId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className=' d-flex align-items-center justify-content-between'>
                    <span className='d-flex align-items-center'>
                      <FaAddressCard size={15} />
                      <span className='ml-2 small'>
                        Open in User Directory
                      </span>
                    </span>
                    <FaExternalLinkSquareAlt size={15} className='opacity-50' />
                  </span>
                </a>
              )}

              {canManageDirectoryUsers(role) && (
                <button
                  className="dropdown-item p-2 border-bottom"
                  type="button"
                  onClick={onClickAdvancedSettings}
                >
                  <span className='d-flex align-items-center'>
                    <FaCog size={15} />
                    <span className='ml-2 small'>
                      Advanced Settings
                    </span>
                  </span>
                </button>
              )}

              {canManageDirectoryUsers(role) && (
                <div className='card-footer p-2 small'>
                  <KeyValueItem
                    title='Email'
                    value={email}
                  />
                  <KeyValueItem
                    title='ID'
                    value={directoryUserId}
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            {!hideClose && (
              <span>
                <button
                  onClick={handleClose}
                  className="btn btn-sm btn-white"
                  type='button'
                  aria-label='Close'
                >
                  <span className='d-flex align-items-center'>
                    <FaTimes size={15} />
                  </span>
                </button>
              </span>
            )}
          </>
        )}
      </div>
      <div
        id="user-details-body"
        className="card-body"
        style={{
          overflowY: 'scroll'
        }}
      >
        {showNameBlock && (
          <div className='d-flex justify-content-center'>
            <NameBlock
              data-demo="disabled"
              key={`directory-user-${directoryUserId}`}
              name={directoryUser?.name}
              nameSize="lg"
              title={directoryUser?.title || roleReadable((directoryUser?.role || []), orgType)}
              profilePicture={directoryUser?.profilePicture || DEFAULT_PROFILE_PIC}
              pictureSize="md"
              className="mb-3"
            />
          </div>
        )}

        {!isActiveMembership(membership) && (
          <div className='h5 m-0 d-flex justify-content-center'>
            <div className="badge alert-danger d-flex align-items-center p-2">
              <FaExclamationCircle
                size={15}
              />
              <div className='ml-1'>
                Inactive
              </div>
            </div>
          </div>
        )}

        <ContactBlock />

        {enableUserGroups && (
          <UserGroupsBlock />
        )}

        <SkillsTags
          title="Skills"
          size='md'
          className="text-center mb-4"
          ids={directoryUser?.skillIds}
        />

        <WorkBlock
          route={route}
        />

        {Array.isArray(apprenticeshipIds) && apprenticeshipIds.length > 0 && (
          <div className="card">
            <div className='card-header d-flex justify-content-between align-items-center'>
              <div className='font-weight-bold d-flex align-items-center'>
                <FaSortAmountUp />
                <span className='ml-2'>
                  Training Programs
                </span>
              </div>
            </div>

            <div className='card-body p-0'>
              <ApprenticeshipsList
                displayType="TABLE"
                ids={apprenticeshipIds}
                hiddenColumns={['employerIds']}
                onClickRow={onClickApprenticeshipRow}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
