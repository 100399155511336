import React, { useState } from 'react';
import { FaBell } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import NotificationsList from '../lists/NotificationsList';

const Notifications = ({
  title = '',
  className = '',
  children,
  showMenu = false
}) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const notificationsOrg = useSelector((state) => state.notificationsOrg);
  // Organization
  const orgId = organization?.id || '';
  // Pagination
  const initialListConfig = {
    orgId,
    classId: null,
    includePinned: true,
    limit: 3,
    page: 1
  };
  // Local State
  const [listConfig] = useState(initialListConfig);

  return (
    <div className={className}>
      {title && (
        <div className="card-header bg-light p-3 h5 m-0">
          <span className="d-flex align-items-center">
            <FaBell />
            <span className="ml-2">{title}</span>
          </span>
        </div>
      )}

      {Array.isArray(notificationsOrg?.pinned)
        && notificationsOrg?.pinned.length > 0 && (
          <NotificationsList
            list={notificationsOrg?.pinned || []}
            showMenu={showMenu}
          />
      )}

      <NotificationsList
        listConfig={listConfig}
        showMenu={showMenu}
      />

      {children || null}
    </div>
  );
};

export default Notifications;
