import React from 'react';

const Switch = ({
  id = 'switch',
  iconComponent,
  slim = false,
  onChange,
  value = false,
  className = '',
  testId = '',
  label = 'ON',
  disabled = false
}) => (
  <div
    className={`d-flex align-items-center ${slim ? '' : 'border rounded p-2'} text-ships-officer ${className}`}
    style={{
      background: 'none',
      fontSize: '.9rem',
      padding: '.25rem .5rem',
      minWidth: 'initial'
    }}
  >
    <div
      className="custom-control custom-switch d-flex align-items-center"
      style={{ minHeight: 'auto', height: '1rem' }}
    >
      <input
        data-testid={testId}
        type="checkbox"
        className="custom-control-input"
        id={`switch-${id}`}
        value={value}
        onChange={onChange}
        checked={value}
        disabled={disabled}
      />
      <label
        className="custom-control-label"
        htmlFor={`switch-${id}`}
      >
        <span className='d-flex align-items-center'>
          {iconComponent && iconComponent()}
          <span
            className={`text-nowrap ${value && 'font-weight-bold'}`}
          >
            {label}
          </span>
        </span>
      </label>
    </div>
  </div>
);

export default Switch;
