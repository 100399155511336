import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchBox from '@apprentage/components/dist/components/SearchBox';
import { getApprenticeships } from '../../../actions/Apprenticeships';
import { canAccessAllLocations } from '../../../permissions/access';
import Loading from '../../Loading';
import List from './List';
import ListDisplayType from './ListDisplayType';
import CardsDisplay from './CardsDisplay';

/**
 * @param {string} employerId Employer Id of User
 * @param {string} instructionProviderId
 * @param {'TABLE' | 'CARD'} displayType
 * @param {string[]} hiddenColumns Hide columns from the table
 * @param {Function} onClickRow function to execute when we click on  table row or card
 * @returns {ReactElement} returns JSX with the component ApprenticeshipsList.
 */

const ApprenticeshipsList = ({
  ids,
  employerId,
  instructionProviderId,
  displayType,
  hiddenColumns,
  onClickRow,
  showControls = false
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeships = useSelector((state) => state.apprenticeships);
  // Defaults
  const defaultDisplayType = 'TABLE';
  // Organization
  const orgId = organization?.id || '';
  // Current User
  const locationId = currentUser?.locationId || '';
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  // Local State
  const [activeDisplayType, setActiveDisplayType] = useState(displayType || defaultDisplayType);
  const [searchTerm, setSearchTerm] = useState('');

  const changeDisplayType = (type) => {
    setActiveDisplayType(type);
    localStorage.setItem('programsListDisplayType', type);
  };

  const filterBySearchTerm = useCallback((c) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const title = c.title.toLowerCase().includes(lowerCaseSearchTerm);

    if (title) {
      return true;
    }

    return false;
  }, [searchTerm]);

  const hiddenColumnsList = useMemo(() => {
    if (hiddenColumns) {
      return hiddenColumns;
    }

    if (canAccessAllLocations(role)) {
      return [];
    }

    return ['employerIds'];
  }, [role, hiddenColumns]);

  const filteredApprenticeships = useMemo(() => {
    let list = apprenticeships?.list;

    if (searchTerm) {
      list = list.filter((c) => filterBySearchTerm(c));
    }

    return list;
  }, [apprenticeships?.list, filterBySearchTerm, searchTerm]);

  useEffect(() => {
    const config = {
      orgId,
      ...(canAccessAllLocations(role) ? {} : { employerId: locationId })
    };

    if (employerId) {
      config.employerId = employerId;
    }

    if (instructionProviderId) {
      config.instructionProviderId = instructionProviderId;
    }

    if (ids) {
      config.ids = ids;
    }

    if (orgId) {
      dispatch(getApprenticeships(config));
    }
  }, [ids, orgId, employerId, locationId, instructionProviderId, role, dispatch]);

  useEffect(() => {
    const type = localStorage.getItem('programsListDisplayType') || null;

    if (type && !displayType) {
      setActiveDisplayType(type);
    }
  }, [displayType]);

  if (!apprenticeships.fetched && !apprenticeships.list) {
    return <Loading text="Loading Apprenticeships..." />;
  }

  return (
    <div className="apprenticeships-list">
      {showControls && (
        <div className="row py-3">
          <div className="col d-flex align-items-center justify-content-between">
            <SearchBox
              onSubmit={(newSearchTerm) => {
                setSearchTerm(newSearchTerm);
              }}
              autoFocus
              placeholder="Search by title..."
            />
            <div className="d-flex">
              <ListDisplayType
                onChange={changeDisplayType}
                activeType={displayType || activeDisplayType}
              />
            </div>
          </div>
        </div>
      )}

      {activeDisplayType === 'TABLE' && (
        <div className='bg-white'>
          <List
            data={filteredApprenticeships}
            hiddenColumns={hiddenColumnsList}
            onClickRow={onClickRow}
          />
        </div>
      )}

      {activeDisplayType === 'CARD' && (
        <CardsDisplay
          data={filteredApprenticeships}
        />
      )}
    </div>
  );
};

export default ApprenticeshipsList;
