import { parseName } from '@apprentage/utils';

const makeInitials = (name = '') => {
  const fullName = parseName(name);

  return (
    fullName?.firstName.substring(0, 1) + fullName?.lastName.substring(0, 1)
  );
};

export default makeInitials;
