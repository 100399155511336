import React, { Component, Fragment } from 'react';
import * as GLOBALS from '../../../constants/globals';
import ColorSwatches from '../ColorSwatches';

class ClassColor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      color: props.classColor || GLOBALS.CLASS_COLOR_DEFAULT
    };
  }

  handleClick = (color) => {
    const { handleColorChange } = this.props;

    this.setState({ color });

    if (handleColorChange) {
      handleColorChange({ classColor: color });
    }
  }

  render() {
    const { color } = this.state;
    const { classColor, showLabel = true } = this.props;

    return (
      <Fragment>
        {showLabel && (
          <label htmlFor="classColor">
            <b>Course Color:</b> <span className="text-danger">*</span>
          </label>
        )}

        <ColorSwatches
          activeColor={color || classColor}
          handleClick={this.handleClick}
        />

        <input
          id="classColor"
          type="hidden"
          className="form-control"
          name="classColor"
          defaultValue={color || classColor}
        />
      </Fragment>
    );
  }
}

export default ClassColor;
