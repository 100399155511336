import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { getLogbook } from '../../../../../actions/Logbooks';
import LogbookView from '../../../../LogbookView';
import ProgramContainer from '../../../Logbook/ProgramContainer';
import LogbookProgress from '../../../Logbook/LogbookProgress';

const LogbookBlock = ({ className = 'card mt-3' }) => {
  const dispatch = useDispatch();
  // redux
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const organization = useSelector((state) => state.organization);
  const logbook = useSelector((state) => state.logbook);
  const logbookHours = useSelector((state) => state.logbookHours);
  const logbookRecords = useSelector((state) => state.logbookRecords);
  // Apprenticeship
  const apprenticeshipId = apprenticeship?.id || null;
  const apprenticeshipUser = apprenticeship?.user || null;
  const apprenticeshipUserId = apprenticeshipUser?.id || null;

  useEffect(() => {
    if (apprenticeshipId && apprenticeshipUserId) {
      dispatch(
        getLogbook({
          apprenticeshipId,
          userId: apprenticeshipUserId,
          orgId: organization?.id,
          logbookHours: true,
          logbookCompHours: true
        })
      );
    }
  }, [apprenticeshipId, apprenticeshipUserId, dispatch, organization?.id]);

  return (
    <div className={className}>
      {logbook?.id && (
        <div
          className="card-body"
          style={{ minHeight: '300px' }}
        >
          <ProgramContainer
            className="animateIn my-3"
            apprenticeshipId={apprenticeship?.id}
            apprenticeshipTitle={apprenticeship?.title}
            logbookId={logbook?.id}
            logbookLocationId={logbook?.employerId}
          >
            <div className='w-100'>
              <LogbookProgress
                // className='mb-3'
                records={logbookRecords.list}
                ojtHours={apprenticeship?.ojtHours}
              />
            </div>
          </ProgramContainer>

          <LogbookView
            ariaExpanded
          />
        </div>
      )}

      {((!logbook?.id && !logbook.fetched) || !logbookHours.fetched) && (
        <div className="p-3">
          <Skeleton
            count={3}
            className="mb-3"
            style={{ height: '55px' }}
          />
        </div>
      )}

      {!logbook?.id && logbook.fetched && (
        <div className="py-5 d-flex justify-content-center flex-column">
          <div className="mt-3 w-100 text-center">No Logbook.</div>
        </div>
      )}
    </div>
  );
};

export default LogbookBlock;
