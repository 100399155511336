import {
  INVITE_TYPE_APPRENTICE_AGREEMENT_SIGNER_APPRENTICE,
  INVITE_TYPE_EMPLOYER_AGREEMENT_SIGNER_MGMT,
  INVITE_TYPE_EMPLOYER_PROFILE_UPDATE,
  INVITE_TYPE_REGISTRATION
} from '@apprentage/constants';
import * as twApi from '../tw-api';
import { TURBINE_ADMIN_EMAIL } from '../../constants/globals';
import { GMAIL_SEND } from '../../constants/api';
import baseTemplate from './templates/baseTemplate';
import inviteUserCreateJobProfilesHtml from './templates/inviteUserCreateJobProfilesHtml';
import inviteUserUpdateEmployerProfileHtml from './templates/inviteUserUpdateEmployerProfileHtml';
import inviteUserSignEmployerAgreementMgmtHtml from './templates/inviteUserSignEmployerAgreementMgmtHtml';
import inviteUserSignApprenticeAgreementHtml from './templates/inviteUserSignApprenticeAgreementHtml';
import inviteUsersHtml from './templates/inviteUsersHtml';

/**
 * Sends Welcome email to newly registered user with information
 * @param {string} orgName // Org Name
 * @param {string} orgSlug // Org Type (community, workforce)
 * @param {string} orgId // Org ID
 * @param {string} name // Newly registered user's name
 * @param {string} email // Newly registered user's email
 * @param {string} phone // Newly registered user's phone
 */
export const inviteUsers = ({
  orgName,
  orgEmail,
  courseInvitation,
  courseTitle,
  apprenticeshipInvitation,
  apprenticeshipTitle,
  emails,
  registerLink
}) => {
  let subject = `Invitation to join ${orgName}'s Workforce Space!`;
  let title = 'Organization Invitation';
  let trackingSlug = 'inviteOrgUser';

  if (courseInvitation) {
    subject = `Invitation to Join ${courseTitle}`;
    title = 'Course Invitation';
    trackingSlug = 'inviteCourseUser';
  }

  if (apprenticeshipInvitation) {
    subject = `Invitation to Join ${apprenticeshipTitle} Training Program`;
    title = 'Training Program Invitation';
    trackingSlug = 'inviteApprenticeshipUser';
  }

  const to = orgEmail || TURBINE_ADMIN_EMAIL;
  const emailParts = {
    to,
    from: {
      name: orgName
    },
    replyTo: to,
    bcc: emails,
    subject,
    body: '',
    htmlBody: baseTemplate({
      orgName,
      title,
      trackingSlug,
      bodyContent: inviteUsersHtml({
        orgName,
        registerLink,
        ...(courseInvitation ? { courseInvitation, courseTitle } : {}),
        ...(apprenticeshipInvitation
          ? { apprenticeshipInvitation, apprenticeshipTitle }
          : {})
      })
    })
  };

  return new Promise((resolve, reject) => {
    return twApi
      .post(GMAIL_SEND, '', JSON.stringify(emailParts))
      .then((response) => {
        if (response && response.status === 200) {
          resolve();
        } else {
          console.error(response.status); // ex: 'error_invitation_fail'

          reject({
            message: response.message,
            status: response.status
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  });
};

/**
 * Sends email inviting user to create Job Profiles
 * @param {string} orgName // Org Name
 * @param {string} orgEmail // Org Email
 * @param {string} orgLogo // Org Logo URL
 * @param {string} locationName // Org Location Name "Employer"
 * @param {string} inviteLink // Link to Jop Profile create page on Turbine Console
 * @param {string} emails // 'alan.mabry@ymail.com' comma separated emails
 */
export const sendEmailUserCreateJobProfilesHtml = ({
  orgName,
  orgEmail,
  orgLogo,
  locationName,
  inviteLink,
  emails
}) => {
  const emailParts = {
    to: orgEmail || TURBINE_ADMIN_EMAIL,
    from: {
      name: orgName
    },
    bcc: emails,
    subject: `Invitation to create ${locationName} Job Profiles`,
    body: '',
    htmlBody: baseTemplate({
      orgName,
      orgLogo,
      title: 'Invitation to Create Job Profiles',
      trackingSlug: 'createJobProfiles',
      bodyContent: inviteUserCreateJobProfilesHtml({
        locationName,
        orgName,
        inviteLink
      })
    })
  };

  return new Promise((resolve, reject) => {
    return twApi
      .post(GMAIL_SEND, '', JSON.stringify(emailParts))
      .then((response) => {
        if (response && response.status === 200) {
          resolve();
        } else {
          console.error(response.status); // ex: 'error_invitation_fail'

          reject({
            message: response.message,
            status: response.status
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  });
};

/**
 * Sends email inviting user to Sign Employer Agreement
 * @param {string} orgName // Org Name
 * @param {string} orgEmail // Org Email
 * @param {string} orgLogo // Org Logo URL
 * @param {string} locationName // Org Location Name "Employer"
 * @param {string} inviteLink // Link to Jop Profile create page on Turbine Console
 * @param {string} inviteType // Type of Invite, used to tailor content
 * @param {string} accessCode // Random number for verifying access
 * @param {string} emails // 'alan.mabry@ymail.com' comma separated emails
 */
export const sendEmailUserInviteHtml = ({
  orgName,
  orgEmail,
  orgLogo,
  apprenticeshipInvitation,
  apprenticeshipTitle,
  courseInvitation,
  courseTitle,
  locationName,
  currentUserName,
  inviteType,
  inviteLink,
  accessCode,
  fullName,
  emails
}) => {
  const fromName = apprenticeshipInvitation
    ? `${locationName} and ${orgName}`
    : orgName;

  const emailParts = {
    to: orgEmail || TURBINE_ADMIN_EMAIL,
    from: {
      name: fromName
    },
    bcc: emails,
    body: ''
  };

  if (inviteType === INVITE_TYPE_REGISTRATION) {
    let subject = `Invitation to join ${orgName}'s Workforce Space!`;
    let title = 'Organization Invitation';
    let trackingSlug = 'inviteOrgUser';

    if (courseInvitation) {
      subject = `Invitation to Join ${courseTitle}`;
      title = 'Course Invitation';
      trackingSlug = 'inviteCourseUser';
    }

    if (apprenticeshipInvitation) {
      subject = `${currentUserName} at ${locationName} invited you to Join ${apprenticeshipTitle}`;
      title = 'Training Program Invitation';
      trackingSlug = 'inviteApprenticeshipUser';
    }

    emailParts.subject = subject;
    emailParts.htmlBody = baseTemplate({
      orgName,
      orgLogo,
      title,
      trackingSlug,
      bodyContent: inviteUsersHtml({
        ...(courseInvitation ? { courseInvitation, courseTitle } : {}),
        ...(apprenticeshipInvitation
          ? { apprenticeshipInvitation, apprenticeshipTitle, fullName }
          : {}),
        locationName,
        orgName,
        registerLink: inviteLink,
        accessCode
      })
    });
  }

  if (inviteType === INVITE_TYPE_EMPLOYER_PROFILE_UPDATE) {
    emailParts.subject = `Invitation to provide ${locationName} Workforce information`;
    emailParts.htmlBody = baseTemplate({
      orgName,
      orgLogo,
      title: 'Invitation to provide Workforce Information',
      trackingSlug: 'updateWorkforceData',
      bodyContent: inviteUserUpdateEmployerProfileHtml({
        locationName,
        orgName,
        inviteLink,
        accessCode
      })
    });
  }

  if (inviteType === INVITE_TYPE_EMPLOYER_AGREEMENT_SIGNER_MGMT) {
    emailParts.subject = `Invitation to Sign ${locationName} Employer Agreement`;
    emailParts.htmlBody = baseTemplate({
      orgName,
      orgLogo,
      title: 'Invitation to Sign Employer Agreement',
      trackingSlug: 'signEmployerAgreementMgmt',
      bodyContent: inviteUserSignEmployerAgreementMgmtHtml({
        locationName,
        orgName,
        inviteLink,
        accessCode
      })
    });
  }

  if (inviteType === INVITE_TYPE_APPRENTICE_AGREEMENT_SIGNER_APPRENTICE) {
    emailParts.subject = 'Invitation to Sign Apprentice Agreement';
    emailParts.htmlBody = baseTemplate({
      orgName,
      orgLogo,
      title: 'Invitation to Sign Apprentice Agreement',
      trackingSlug: 'signApprenticeAgreement',
      bodyContent: inviteUserSignApprenticeAgreementHtml({
        locationName,
        orgName,
        inviteLink,
        accessCode
      })
    });
  }

  return new Promise((resolve, reject) => {
    return twApi
      .post(GMAIL_SEND, '', JSON.stringify(emailParts))
      .then((response) => {
        if (response && response.status === 200) {
          resolve();
        } else {
          console.error(response.status); // ex: 'error_invitation_fail'

          reject({
            message: response.message,
            status: response.status
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  });
};
