import React, {
  useState, useRef, useMemo, useEffect
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FaCloudUploadAlt,
  FaCog,
  FaEllipsisH,
  FaExclamationTriangle,
  FaImages,
  FaStar,
  FaVideo
} from 'react-icons/fa';
import { reactSelectDefaultValues, reactSelectOptionsFromArray } from '@apprentage/utils';
import SelectMenu from '@apprentage/components/dist/components/SelectMenu';
import { createProject, updateProject } from '../../../services/projects';
import { ORG_CONSOLE_PROJECTS, ORG_CONSOLE_PROJECT_BASE } from '../../../constants/routes';
import { routeWithOrgId } from '../../../services/organizations';
import { setCurrentModal } from '../../../actions/Modals';
import { deleteFile } from '../../../services/aws';
import { getFullAddress } from '../../../services/locations';
import { getCoordinates } from '../../../services/ipApi';
import { canAccessUserGroups } from '../../../services/currentUser';
import {
  MODAL_KEY_MANAGE_CDN_FILE,
  MODAL_KEY_SEARCH_SELECT_USERS,
  MODAL_KEY_UPLOAD_FILES
} from '../../../constants/modals';
import { setProjectFiles } from '../../../actions/Projects';
import { deleteResource } from '../../../services/resources';
import { getResourcesByParentId } from '../../../actions/Resources';
import RenderTinyMceEditor from '../../ManageContent/Wysiwyg/RenderTinyMceEditor';
import ItemsDnd from '../../ManageContent/Dnd/Items';
import RadioGroup from '../../ManageContent/RadioGroup';
import Address from '../../ManageContent/Address';
import removeStringFromArray from '../../../utils/removeStringFromArray';
import Collapse from '../../ManageContent/Collapse';
import LocationSelect from '../../ManageContent/LocationSelect';
import Users from '../../ManageContent/Users';
import { fetchFlags } from '../../../services/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../actions/FeatureFlags';
import { NOTICE_RESOURCES_READONLY_TITLE } from '../../../constants/flagsmith';
import DeleteProject from '../../ManageContent/DeleteContent/DeleteProject';
import { getCourses } from '../../../actions/Courses';
import SkillsSelectMenu from '../Skills/SkillsSelectMenu';
import imageOrientation from '../../../utils/imageOrientation';

const Form = () => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const project = useSelector((state) => state.project);
  const locations = useSelector((state) => state.locations);
  const userGroups = useSelector((state) => state.userGroups);

  const courses = useSelector((state) => state.courses);

  // Current User
  const role = currentUser?.role || [];
  // Organization
  const orgId = organization?.id || '';
  // Misc
  const [loading, setLoading] = useState(false);
  const [showcasedImageOrientation, setShowcasedImageOrientation] = useState(null);
  // Fields
  const [bodyExceedMaxChars, setBodyExceedsMaxChars] = useState(false);
  const bodyRef = useRef(null);
  const [title, setTitle] = useState(project?.title || null);
  const [clientName, setClientName] = useState(project?.clientName || null);
  const [teamName, setTeamName] = useState(project?.teamName || null);
  const [projectSize, setProjectSize] = useState(project?.projectSize || null);
  const [imgIds, setImgIds] = useState(project?.imageIds || []);
  const [isFeatured, setIsFeatured] = useState(project?.isFeatured || false);
  const [learningModulesIds, setLearningModulesIds] = useState(
    project?.classIds || []
  );
  const [projectVideo, setProjectVideo] = useState(project?.projectVideo || null);
  // Address
  const [addressLine1, setAddressLine1] = useState(project?.addressLine1 || null);
  const [addressLine2, setAddressLine2] = useState(project?.addressLine2 || null);
  const [addressCity, setAddressCity] = useState(project?.addressCity || null);
  const [addressState, setAddressState] = useState(project?.addressState || null);
  const [addressPostalCode, setAddressPostalCode] = useState(project?.addressPostalCode || null);
  const [addressCountry, setAddressCountry] = useState(project?.addressCountry || null);
  const [addressLat, setAddressLat] = useState(project?.addressLat || null);
  const [addressLng, setAddressLng] = useState(project?.addressLng || null);
  // Location
  const [locationId, setLocationId] = useState(project?.locationId || null);
  // Users
  const [userIds, setUserIds] = useState(project?.userIds || []);
  // User Groups
  const [userGroupIds, setUserGroupIds] = useState(project?.userGroupIds || []);
  // SKills (Expertise / Services)
  const [skillIds, setSKillIds] = useState(project?.skillIds);

  const onChangeSkills = (options) => {
    setSKillIds(options.map((skill) => skill.value));
  };

  // Select Menu
  const userGroupIdsOptions = reactSelectOptionsFromArray(userGroups.list, 'title');
  const userGroupIdsDefaultValues = reactSelectDefaultValues(
    userGroupIds,
    userGroupIdsOptions
  );

  const learningModulesOptions = reactSelectOptionsFromArray(courses.list, 'title');
  const learningModulesDefaultValues = reactSelectDefaultValues(learningModulesIds, learningModulesOptions);

  const handleUserAddressOnChange = (e) => {
    const { name: fieldName, value } = e.currentTarget;

    if (fieldName === 'line1') {
      setAddressLine1(value);
    }

    if (fieldName === 'line2') {
      setAddressLine2(value);
    }

    if (fieldName === 'city') {
      setAddressCity(value);
    }

    if (fieldName === 'state') {
      setAddressState(value);
    }

    if (fieldName === 'postal_code') {
      setAddressPostalCode(value);
    }

    if (fieldName === 'country') {
      setAddressCountry(value);
    }
  };

  const showSuccess = () => {
    toast.success('Project saved!');
    setLoading(false);
  };

  const handleFileSettingsClick = ({ item }) => {
    fetchFlags().then((fetchedFlags) => {
      if (fetchedFlags?.resources_readonly?.enabled && fetchedFlags?.resources_readonly?.value) {
        dispatch(showFeatureFlagNoticeModal({
          modalTitle: NOTICE_RESOURCES_READONLY_TITLE
        }));

        return;
      }

      dispatch(setCurrentModal({
        key: MODAL_KEY_MANAGE_CDN_FILE,
        data: {
          modalTitle: 'File Settings',
          currentFile: item,
          currentFileId: item?.id,
          contentId: project?.id,
          contentType: 'projects'
        }
      }));
    });
  };

  const uploadFiles = () => {
    dispatch(setCurrentModal({
      key: MODAL_KEY_UPLOAD_FILES,
      data: {
        title: 'Upload Images',
        contentId: project?.id,
        contentType: 'projects',
        integration: false,
        multiple: true,
        accept: 'image/*',
        callback: (cdnFiles) => {
          const cdnFileIds = cdnFiles.map((cdnFile) => cdnFile.id);
          const newImageIds = [
            ...cdnFileIds,
            ...imgIds
          ];
          setImgIds(newImageIds);
          dispatch(setProjectFiles(cdnFiles));
          updateProject({
            imageIds: newImageIds.length ? newImageIds : null
          }, project?.id);
        }
      }
    }));
  };

  // const googleMapsUrl = useMemo(() => {
  //   const url = 'https://www.google.com/maps/place';

  //   if (addressLine1 && addressCity && addressState && addressPostalCode) {
  //     const fullAddress = getFullAddress({
  //       location: {
  //         addressLine1,
  //         addressCity,
  //         addressState,
  //         addressPostalCode
  //       }
  //     });

  //     const formattedFullAddress = fullAddress.replaceAll(' ', '+');

  //     return `${url}/${formattedFullAddress}`;
  //   }

  //   return url;
  // }, [addressLine1, addressCity, addressState, addressPostalCode]);

  /**
   *
   * @param {object} param.item
   * @param {number} param.index item's index in array
   */
  const handleDeleteItem = ({ item }) => {
    let newImageIds = [...imgIds];

    newImageIds = removeStringFromArray(imgIds, item?.id);

    toast.info('Deleting image...', { toastId: 'deletingImageInfo' });

    setLoading(true);
    setImgIds(newImageIds);

    deleteFile({ url: item?.url, orgId }).then(() => {
      deleteResource(item?.id).then(() => {
        dispatch(getResourcesByParentId(project?.id, 'projects'));
      });
      updateProject({ imageIds: newImageIds }, project?.id).then(() => {
        toast.dismiss('deletingImageInfo');
        toast.success('Image deleted');
        setLoading(false);
      });
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    toast.dismiss('portraitWarning');

    const newSkillIds = Array.isArray(skillIds) && skillIds.length > 0 ? skillIds : null;
    const newImageIds = imgIds.length ? imgIds : null;
    const newUserGroupIds = userGroupIds.length ? userGroupIds : null;
    const newUserIds = userIds.length ? userIds : null;
    const newLearningModulesIds = learningModulesIds?.length
      ? learningModulesIds
      : null;

    const dataToSave = {
      title,
      clientName,
      teamName,
      locationId,
      projectSize,
      projectVideo: projectVideo || null,
      skillIds: newSkillIds,
      userGroupIds: newUserGroupIds,
      userIds: newUserIds,
      isFeatured: isFeatured || null,
      addressLine1,
      addressLine2,
      addressCity,
      addressState,
      addressPostalCode,
      addressCountry,
      addressLat,
      addressLng,
      classIds: newLearningModulesIds,
      imageIds: newImageIds
    };

    if (bodyRef && bodyRef.current && bodyRef.current.isDirty) {
      dataToSave.body = bodyRef.current.getContent();
    }

    setLoading(true);

    if (project?.id) {
      updateProject(dataToSave, project?.id).then(() => {
        showSuccess();
        document.location = routeWithOrgId({
          route: `${ORG_CONSOLE_PROJECT_BASE}/${project.id}`,
          orgId
        });
      });
    } else {
      createProject({
        ...dataToSave,
        orgId
      }).then((response) => {
        if (response?.id) {
          document.location = `${ORG_CONSOLE_PROJECT_BASE}/manage/${response?.id}`;
          showSuccess();
        } else if (response?.error) {
          console.error(response?.message || response?.error);
          toast.error(response.error);
        }
      });
    }
  };

  const selectUsers = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_SEARCH_SELECT_USERS,
        data: {
          title: 'Select Stakeholders',
          ids: userIds,
          searchFilters: {
            excludeRoles: ['superAdmin']
          }
        },
        callbackPrimaryAction: (newUserIds) => {
          setUserIds(newUserIds);
        }
      })
    );
  };

  const projectFullAddress = useMemo(() => {
    const result = '';

    if (addressCity && addressState) {
      const fullAddress = getFullAddress({
        location: {
          ...(addressLine1 ? { addressLine1 } : {}),
          addressCity,
          addressState,
          ...(addressPostalCode ? { addressPostalCode } : {})
        }
      });

      return fullAddress;
    }

    return result;
  }, [addressLine1, addressCity, addressState, addressPostalCode]);

  useEffect(() => {
    if (!projectFullAddress) {
      setAddressLat(null);
      setAddressLng(null);
      return;
    }

    getCoordinates(projectFullAddress).then((response) => {
      setAddressLat(response?.latitude);
      setAddressLng(response?.longitude);
    }).catch((error) => {
      console.error('getCoordinates', error);
      setAddressLat(null);
      setAddressLng(null);
    });
  }, [projectFullAddress]);

  useEffect(() => {
    dispatch(getCourses({ orgId }));

    return function cleanup() {
      toast.dismiss('portraitWarning');
    };
  }, [dispatch, orgId]);

  return (
    <div className="bg-white border shadow rounded mt-3 overflow-content">
      <form onSubmit={handleSubmit}>
        <div className="card-body">
          {project?.id && (
            <div
              id="challenge-activeChallenge"
              className="form-group mb-4 border p-3"
            >
              <div className="mb-1 d-flex align-items-center font-weight-bold">
                <FaStar
                  className={isFeatured ? 'text-primary' : 'text-muted opacity-50'}
                />
                <span className='ml-1'>
                  Featured
                </span>
              </div>
              <div className="text-muted medium mb-2">
                Show this project on the dashboard.
              </div>
              <RadioGroup
                name="isFeatured"
                value={isFeatured}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setIsFeatured(value === 'true');
                }}
              />
            </div>
          )}

          <div className="mb-3">
            <label className="mb-2">
              <b>Title:</b> <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="title"
              defaultValue={title}
              onChange={(e) => {
                const { value } = e.currentTarget;

                setTitle(value);
              }}
              autoFocus={!project?.id}
              required
            />
          </div>

          {project?.id && (
            <div className="mb-3">
              <label className="mb-2">
                <b>Client Name:</b>
              </label>
              <input
                type="text"
                className="form-control"
                name="clientName"
                defaultValue={clientName}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setClientName(value);
                }}
              />
            </div>
          )}

          {project?.id && (
            <div className="mb-3">
              <label
                htmlFor="learningModules"
                className="mb-2"
              >
                <b>Courses:</b>
              </label>
              <SelectMenu
                name="learningModules"
                options={learningModulesOptions}
                defaultValue={learningModulesDefaultValues}
                placeholder="Select Learning modules..."
                onChange={(values) => {
                  setLearningModulesIds(
                    values?.map((classOption) => classOption?.value) || []
                  );
                }}
                isMulti
              />
            </div>
          )}

          <SkillsSelectMenu
            className='position-relative list-group-item py-4 mb-3'
            ids={skillIds}
            onChange={onChangeSkills}
            title="Skills (Services)"
            subtitle="Select skills based on services provided"
            showHelpText
            showManageLink
          />

          {project?.id && (
            <div className="mb-3">
              <div className="mb-2 font-weight-bold">
                Office Location
              </div>
              {locations.list && Array.isArray(locations.list) && locations.list.length !== 0 && (
                <LocationSelect
                  className="mr-0 mr-sm-2 flex-fill"
                  defaultValue={locationId}
                  onChange={({ selectedLocationId }) => {
                    setLocationId(selectedLocationId);
                  }}
                  data={locations.list}
                />
              )}
            </div>
          )}

          {/* Groups */}
          {project?.id && canAccessUserGroups(role, organization?.enableUserGroups) && userGroupIdsOptions && (
            <div className="mb-3">
              <div className="mb-2 font-weight-bold">User Groups</div>
              <SelectMenu
                name="groupIds"
                options={userGroupIdsOptions}
                defaultValue={userGroupIdsDefaultValues}
                placeholder='Select Group...'
                onChange={(values) => {
                  const newValues = values.length === 0 ? null : values.map((tag) => tag.value);
                  // OLD NOTE: null converted to undefined when data is formatted before sent to BE
                  // NOTE: setting to null may not be needed because we're saving to Firebase
                  setUserGroupIds(newValues);
                }}
                isMulti
              />
            </div>
          )}

          {project?.id && (
            <div className="mb-3">
              <label className="mb-2">
                <b>Team Name:</b>
              </label>
              <input
                type="text"
                className="form-control"
                name="teamName"
                defaultValue={teamName}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setTeamName(value);
                }}
              />
            </div>
          )}

          {project?.id && (
            <div className="mb-3">
              <label className="mb-2">
                <b>Project Size:</b>
              </label>
              <input
                type="text"
                className="form-control"
                name="projectSize"
                defaultValue={projectSize}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setProjectSize(value);
                }}
              />
              <div className='text-muted small mt-1'>
                Ex: 2 stories, 69,104 SF
              </div>
            </div>
          )}

          {project?.id && (
            <Collapse
              id="project-address"
              title="Project Address"
              className="mt-3"
              ariaExpanded
            >
              <Address
                address={{
                  line1: addressLine1,
                  line2: addressLine2,
                  city: addressCity,
                  state: addressState,
                  postal_code: addressPostalCode,
                  country: addressCountry
                }}
                editMode
                onChange={handleUserAddressOnChange}
                inputFieldStyle={{ padding: '5px 7px' }}
                autoFocus={false}
              />
              <div className="card mt-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <label
                        htmlFor="addressLat"
                      >
                        Latitude
                      </label>
                      <input
                        type="text"
                        name="addressLat"
                        className="form-control"
                        value={addressLat}
                        onChange={(e) => {
                          const { value } = e.currentTarget;

                          setAddressLat(value);
                        }}
                        readOnly
                      />
                      {/* <div className="small mt-1">
                        <span>
                          Ex: 40.50087091714842
                        </span>
                      </div> */}
                    </div>

                    <div className="col-sm-6">
                      <label htmlFor="addressLng">Longitude</label>
                      <div />
                      <input
                        type="text"
                        name="addressLng"
                        className="form-control"
                        value={addressLng}
                        onChange={(e) => {
                          const { value } = e.currentTarget;

                          setAddressLng(value);
                        }}
                        readOnly
                      />
                      {/* <div className="small mt-1">
                        <span>
                          Ex: -79.90079498465825
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="card-footer d-flex justify-content-center">
                  Auto-generated based on address.
                </div>
              </div>
            </Collapse>
          )}

          {project?.id && (
            <RenderTinyMceEditor
              id="project-body"
              className="mt-3"
              contentId={project?.id}
              contentType="projects"
              title="Project Description"
              uploadPdfButton={false}
              uploadImgButton={false}
              embedMediaButton
              defaultValue={project?.body || ''}
              editorRef={bodyRef}
              onExceedMaxChars={(exceedsMaxCharsBool) => {
                setBodyExceedsMaxChars(exceedsMaxCharsBool);
              }}
            />
          )}

          {project?.id && (
            <div className="card my-3">
              <div className="card-header d-flex align-items-center justify-content-between">
                <div>
                  <div className="h5 m-0">Stakeholders</div>
                </div>
                <div className="btn-group align-items-center">
                  <button
                    className="btn btn-link btn-sm rounded text-ships-officer"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-label="Manage Menu"
                    title="Manage Menu"
                  >
                    <FaEllipsisH />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right p-0">
                    <button
                      className="dropdown-item py-2 d-flex align-items-center"
                      type="button"
                      title="Select Manager"
                      onClick={selectUsers}
                    >
                      <FaCog className="mr-1" />
                      <span>Select Stakeholder</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Users
                  key={userIds.join(',')}
                  userIds={userIds}
                />
              </div>
            </div>
          )}

          {project?.id && (
            <div className="card mt-3 mb-4">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold">
                  <span className='d-flex align-items-center'>
                    <FaVideo />
                    <span className='ml-2'>
                      Project Video
                    </span>
                  </span>
                </h6>
              </div>
              <div className='card-body'>
                <label className="mb-1">
                  <b>Embed Code:</b>
                </label>
                <div className="text-muted medium mb-2">
                  Video showcasing the project.
                </div>
                <textarea
                  name="projectVideo"
                  className="form-control mb-2"
                  defaultValue={projectVideo}
                  placeholder="<iframe src=..."
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setProjectVideo(value);
                  }}
                />
                <div className="text-muted small">
                  Paste embed code from youtube, vimeo, etc.
                </div>
              </div>
            </div>
          )}

          {project?.id && (
            <div className="card mt-3 mb-4">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold">
                  <span className='d-flex align-items-center'>
                    <FaImages />
                    <span className='ml-2'>
                      Images
                    </span>
                  </span>
                </h6>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-primary d-flex align-items-center"
                  onClick={uploadFiles}
                >
                  <FaCloudUploadAlt size={18} />
                  <span className="ml-1">Upload</span>
                </button>
              </div>
              <div className='card-body p-0'>
                {Array.isArray(project?.cdnFiles) && project?.cdnFiles.length > 0 ? (

                  <ItemsDnd
                    key={project?.cdnFiles.length}
                    items={project?.cdnFiles}
                    showBadgeOnFirstItem
                    handleDeleteItem={handleDeleteItem}
                    handleEditItem={handleFileSettingsClick}
                    onUpdate={(newListOrder) => {
                      const newImageIds = newListOrder.map((item) => item?.id);
                      setImgIds(newImageIds);

                      let newImageOrientation = null;

                      if (Array.isArray(newListOrder) && newListOrder[0] && newListOrder[0]?.url) {
                        newImageOrientation = imageOrientation(newListOrder[0]?.url);

                        if (newImageOrientation === 'portrait') {
                          toast.error('Landscape orientation is recommend for a project\'s Showcased image.', { toastId: 'portraitWarning', autoClose: false });
                        } else {
                          toast.dismiss('portraitWarning');
                        }
                      }

                      setShowcasedImageOrientation(newImageOrientation);
                    }}
                  />

                ) : (
                  <div
                    className='d-flex justify-content-center align-items-center'
                    style={{
                      height: '50px'
                    }}
                  >
                    <span>
                      No images
                    </span>
                  </div>
                )}
              </div>

              {showcasedImageOrientation === 'portrait' && Array.isArray(project?.cdnFiles) && project?.cdnFiles.length > 0 && (
                <div className='card-footer'>
                  <div className='d-flex align-items-center justify-content-center'>
                    <FaExclamationTriangle className='text-danger' />
                    <span className='ml-2'>
                      Portrait orientation is NOT recommend for a project's <strong>Showcased</strong> image.
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}

          {project?.id && (
            <DeleteProject
              className="mt-2"
              contentId={project?.id}
            />
          )}
        </div>

        <div className="card-footer d-flex justify-content-between">
          <div>
            <button
              className="btn btn-primary mr-2"
              type="submit"
              disabled={loading || bodyExceedMaxChars}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>

            <Link
              type="button"
              title="Cancel"
              className="btn btn-sm btn-link"
              disabled={loading}
              to={routeWithOrgId({
                route: ORG_CONSOLE_PROJECTS,
                orgId
              })}
            >
              Cancel
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
