export const DASHBOARD_DEFAULT_LAYOUT = 'layout1';

export const LAYOUTS = {
  layout1: [6, 6, 12],
  layout2: [12, 6, 6]
};

export const DASHBOARD_MODULE_TYPE = {
  featuredProject: 'PROJECT',
  notifications: 'NOTIFICATIONS',
  embedWidgets: 'EMBED_WIDGETS_LIST',
  linkWidgets: 'LINK_WIDGETS_LIST'
};

export const DASHBOARD_MODULES = {
  PROJECT: 'Featured Project',
  NOTIFICATIONS: 'Notifications',
  EMBED_WIDGETS_LIST: 'Embed Widget List',
  LINK_WIDGETS: 'Link Widget List'
};
