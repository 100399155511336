import React from 'react';
import { FaBell } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FeaturedProject from '../FeaturedProject';
import WidgetsList from '../../lists/WidgetsList';
import EmbedWidgetList from '../../lists/WidgetsList/EmbedWidgetList';
import Notifications from '../Notifications';
import { ORG_CONSOLE_NOTIFICATIONS } from '../../../constants/routes';

const Module = ({ type, className, isConfig = false }) => {
  const organization = useSelector((state) => state.organization);
  const orgNotifications = organization?.notifications?.list || [];
  let module = null;
  switch (type) {
    case 'PROJECT':
      module = <FeaturedProject skipUnmount showAllProjectsLink={!isConfig} showHeader allowToggleDetails />;
      break;
    case 'LINK_WIDGETS_LIST':
      module = <WidgetsList isFeatured />;
      break;
    case 'EMBED_WIDGETS_LIST':
      module = (
        <EmbedWidgetList
          isFeatured
          silentLoadData={!isConfig}
        />
      );
      break;
    case 'NOTIFICATIONS':
      module = (
        <Notifications
          showMenu={!isConfig}
          className="mb-4"
        >
          {orgNotifications ? (
            <div className="d-flex align-items-center justify-content-center">
              <Link
                to={ORG_CONSOLE_NOTIFICATIONS}
                title="View All Company News"
                className="btn btn-white d-flex align-items-center"
              >
                <FaBell size={15} />
                <span className="ml-2">All Company News</span>
              </Link>
            </div>
          ) : (
            <span />
          )}
        </Notifications>
      );
      break;
    default:
      module = null;
  }
  return (
    <div
      className={className}
      style={isConfig ? { pointerEvents: 'none' } : {}}
    >
      {module}
    </div>
  );
};

export default Module;
