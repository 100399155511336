import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
// import { MdOutlineSpaceDashboard } from 'react-icons/md';
import { AiOutlineDeploymentUnit } from 'react-icons/ai';
import { PiChalkboardTeacher } from 'react-icons/pi';
import { canAccessSkills, isSuperAdmin } from '@apprentage/utils';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import {
  FaAtom,
  FaBell,
  FaCity,
  // FaClipboardList,
  FaIdCard,
  FaImages,
  FaLayerGroup,
  FaLifeRing,
  FaMapMarkerAlt,
  FaPhotoVideo,
  FaPlus,
  FaSearch,
  FaTh
} from 'react-icons/fa';
import { HiDocumentSearch } from 'react-icons/hi';
import OrgLogo from '@apprentage/components/dist/components/OrgLogo';
import { useFlags, useFlagsmith, useFlagsmithLoading } from 'flagsmith/react';
import {
  FLAG_APP_TURBINE_ADMIN,
  FLAG_MAINTENANCE_MODE,
  NOTICE_MAINTENANCE_MODE_BODY,
  NOTICE_MAINTENANCE_MODE_TITLE
} from '../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../actions/FeatureFlags';
import {
  BILLING,
  // ORG_CONSOLE_CHALLENGES,
  ORG_CONSOLE_COURSES,
  ORG_CONSOLE_DASHBOARD,
  ORG_CONSOLE_LOCATIONS,
  ORG_CONSOLE_NOTIFICATIONS,
  ORG_CONSOLE_PROJECTS,
  ORG_CONSOLE_RESOURCES,
  ORG_CONSOLE_SKILLS,
  ORG_CONSOLE_USERS,
  ORG_CONSOLE_WORKFORCE,
  ORG_MANAGE,
  SEARCH_CONTENT,
  SLASH
} from '../../constants/routes';

import { isDemoModeActive, toggleDemoMode } from '../../services/demoMode';
import { locationTerminology } from '../../services/locations';
import {
  canAccessAllUsers,
  canAccessBilling,
  canActivateDemoMode,
  canAccessResources,
  canAccessDashboard,
  canAccessCourses,
  // canAccessAssessments,
  canAccessOrgLocations,
  canAccessOrgSettings,
  canManageProjects
} from '../../services/currentUser';
import { logout } from '../../actions/Session';
import { resetUser } from '../../actions/Users';
import { entryOrgMatchesUserOrg, routeWithOrgId, usingLatestAppVersion } from '../../services/organizations';
import {
  TURBINE,
  TURBINE_ADMIN_DOCS,
  TURBINE_TRAINING_PROGRAM_DOCS
} from '../../constants/urls';
import { manageEntry } from '../../services/entry';
import { logUserOut, redirectToTurbineApp } from '../../services/auth';
import { setCurrentModal } from '../../actions/Modals';
import {
  canAccessAdminDocs,
  canAccessProgramTrainingDocs,
  canAccessTurbineLMS,
  canAccessWorkforceSuite
} from '../../permissions/access';

import UserDetailsBlock from './UserDetailsBlock';
// import pageTitleByPathname from './pageTitleByPathname';
import TopNavWeather from '../Weather/TopNavWeather';
import SearchBoxLauncher from './SearchBoxLauncher';
import TopNavLocation from '../TopNavLocation';
import {
  MODAL_KEY_ONBOARD_WELCOME,
  MODAL_KEY_COURSE_CREATE_FROM_DOC,
  MODAL_KEY_NOTIFICATIONS_MENU,
  MODAL_KEY_RELOAD
} from '../../constants/modals';
import './style.css';

const TopNav = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const flags = useFlags([FLAG_APP_TURBINE_ADMIN, FLAG_MAINTENANCE_MODE]);
  const { getFlags } = useFlagsmith();
  const { isLoading } = useFlagsmithLoading();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const app = useSelector((state) => state.app);
  // Misc
  const currentPage = pathOr(null, ['currentPage'], app);
  // Organization
  const orgId = organization?.id || '';
  const orgType = organization?.type || '';
  const enableResources = organization?.enableResources || false;
  const enableWorkforceSuite = organization?.enableWorkforceSuite || false;
  const enableProjects = organization?.enableProjects || false;
  const locationTerm = locationTerminology({ orgType });
  // Current User
  const userOrgId = currentUser?.orgId || '';
  const role = currentUser?.role || [];
  const userName = currentUser?.name || '';
  const userEmail = currentUser?.email || '';
  const profilePicture = currentUser?.profilePicture || '';
  // Route Match
  const isOrgNotificationsRoute = useRouteMatch(ORG_CONSOLE_NOTIFICATIONS);
  // Permissions
  const dashAccess = canAccessDashboard(role, organization);

  const handleLogout = () => {
    logUserOut().then(() => {
      dispatch(logout());
      dispatch(resetUser());
    });
  };

  const openAppsMenu = () => {
    dispatch(
      setCurrentModal({
        key: 'appsMenu'
      })
    );
  };

  const openNotificationsMenu = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_NOTIFICATIONS_MENU
      })
    );
  };

  const openLLMCourseCreate = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_COURSE_CREATE_FROM_DOC
      })
    );
  };

  const openReportIssueModal = () => {
    dispatch(
      setCurrentModal({
        key: 'sendEmail',
        data: {
          modalTitle: 'Report Issue',
          name: 'Turbine Workforce Support',
          fromName: userName,
          subject: `⚠️ Issue Reported from ${userName}`,
          hideSubject: true,
          disableSubject: true,
          replyTo: userEmail,
          notifyTurbineEmail: true,
          notifyTurbineSlack: true
        }
      })
    );
  };

  useEffect(() => {
    if (app?.entryOrgId && userOrgId) {
      if (!entryOrgMatchesUserOrg(app?.entryOrgId, userOrgId)) {
        toast.error('Access to this Entry is not allowed (ORG)');
      }
    }
  }, [app?.entryOrgId, userOrgId]);

  useEffect(() => {
    if (currentUser?.id && !currentUser?.onboarded) {
      dispatch(setCurrentModal({
        key: MODAL_KEY_ONBOARD_WELCOME
      }));
    }
  }, [currentUser?.id, currentUser?.onboarded, dispatch]);

  // FLAGS & REMOTE CONFIG
  useEffect(() => {
    if (!isLoading) {
      getFlags();
    }

    if (flags?.app_turbine_admin?.enabled && flags?.app_turbine_admin?.value !== undefined) {
      if (!usingLatestAppVersion(flags?.app_turbine_admin?.value)) {
        dispatch(setCurrentModal({
          key: MODAL_KEY_RELOAD,
          data: {
            appVersion: flags?.app_turbine_admin?.value
          }
        }));
      }
    }

    if (flags?.maintenance_mode?.enabled && flags?.maintenance_mode?.value !== undefined) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_MAINTENANCE_MODE_TITLE,
        modalBody: NOTICE_MAINTENANCE_MODE_BODY,
        preventClose: true
      }));
    }
  }, [location, getFlags, isLoading, flags?.maintenance_mode, flags?.app_turbine_admin, dispatch]);
  // /FLAGS & REMOTE CONFIG

  if (!orgId || !currentUser.id) {
    // TODO Skeleton Loading
    return null;
  }

  return (
    <nav className="navbar sticky-top bg-white border-bottom p-0 d-block">
      <div className="d-flex align-items-center justify-content-between flex-md-nowrap">
        <div className="d-flex align-items-center">
          {dashAccess && (
            <Link
              className="top-nav-trigger pr-2"
              data-cy="topNavOrgLinkUsers"
              to={SLASH}
            >
              <OrgLogo
                // className="mr-1"
                src={organization?.orgLogo}
                alt={organization?.name}
                height="30"
              />
            </Link>
          )}

          <div className="dropdown">
            <div
              className={`top-nav-trigger nav-link dropdown-toggle text-nowrap border-right ${dashAccess && 'include-hover'}`}
              id="navbarDropdownOrgMenuLink"
              data-cy="topNavOrgLinks"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                ...(dashAccess ? { paddingLeft: '10px', paddingRight: '10px' } : {})
              }}
            >
              {!dashAccess && (
                <OrgLogo
                  className="mr-2"
                  src={organization?.orgLogo}
                  alt={organization?.name}
                  height="30"
                />
              )}
            </div>

            <div
              id="navbarDropdownOrgMenu"
              className="dropdown-menu my-0 py-0"
              aria-labelledby="navbarDropdownOrgMenuLink"
              style={{
                ...(dashAccess ? { marginLeft: '-82px' } : {})
              }}
            >
              {canAccessWorkforceSuite(role, orgType, enableWorkforceSuite) && (
                <>
                  <div>
                    <div className="nav-item">
                      <Link
                        to={routeWithOrgId({
                          route: ORG_CONSOLE_WORKFORCE,
                          orgId
                        })}
                        style={{
                          minWidth: '250px'
                        }}
                        className="dropdown-item py-3 border-bottom position-relative"
                        data-cy="topNavOrgLinkWorkforce"
                      >
                        <div className="font-weight-bold d-flex align-items-center">
                          <span
                            className='rounded bg-dark mr-2 p-2 text-white d-flex align-items-center'
                          >
                            <AiOutlineDeploymentUnit
                              size={32}
                            />
                          </span>

                          <span>
                            <span className='d-block font-weight-bold '>
                              Apprentage
                            </span>
                            <span className='d-block font-weight-normal'>
                              On-the-job Training & Tracking
                            </span>
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </>
              )}

              {canAccessTurbineLMS(role, orgType) && (
                <div>
                  <div className="nav-item">
                    <Link
                      to={routeWithOrgId({
                        route: ORG_CONSOLE_DASHBOARD,
                        orgId
                      })}
                      style={{
                        minWidth: '250px'
                      }}
                      className="dropdown-item py-3 border-bottom position-relative"
                      data-cy="topNavOrgLinkWorkforce"
                    >
                      <div className="font-weight-bold d-flex align-items-center">
                        <span
                          className='rounded bg-primary mr-2 p-2 text-white d-flex align-items-center'
                        >
                          <PiChalkboardTeacher
                            size={32}
                          />
                        </span>

                        <span>
                          <span className='d-block font-weight-bold '>
                            KnowledgeOps
                          </span>
                          <span className='d-block font-weight-normal'>
                            Capture & Management
                          </span>
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              )}

              {/* {dashAccess && (
                <Link
                  className="dropdown-item border-bottom py-2"
                  data-cy="topNavOrgLinkUsers"
                  to={SLASH}
                >
                  <span className='d-flex align-items-center'>
                    <MdOutlineSpaceDashboard size={20} />
                    <span className='ml-2'>
                      Dashboard
                    </span>
                  </span>
                </Link>
              )} */}

              {canAccessAllUsers(role, orgType) && (
                <Link
                  className="dropdown-item border-bottom py-2"
                  data-cy="topNavOrgLinkUsers"
                  to={routeWithOrgId({
                    route: ORG_CONSOLE_USERS,
                    orgId
                  })}
                >
                  <span className='d-flex align-items-center'>
                    <FaIdCard size={20} />
                    <span className='ml-2'>
                      User Directory
                    </span>
                  </span>

                </Link>
              )}

              {/* <div className='nav-item'>
                <Link
                  to={routeWithOrgId({
                    route: ORG_CONSOLE_ASSISTANT,
                    orgId
                  })}
                  className="dropdown-item border-bottom py-2"
                  data-cy="topNavOrgLinkAiAssistant"
                >
                  <i className={ICON_TURBINE} /> AI Assistant
                </Link>
              </div> */}

              {canAccessResources(role, enableResources, orgType) && (
                <Link
                  className="dropdown-item border-bottom py-2"
                  data-cy="topNavOrgLinkResources"
                  to={routeWithOrgId({
                    route: `${ORG_CONSOLE_RESOURCES}`,
                    orgId
                  })}
                >
                  <span className='d-flex align-items-center'>
                    <FaPhotoVideo size={20} />
                    <span className='ml-2'>
                      Resources
                    </span>
                  </span>
                </Link>
              )}

              {canAccessCourses(role) && (
                <Link
                  className="dropdown-item border-bottom py-2"
                  data-cy="topNavOrgLinkCourses"
                  to={routeWithOrgId({
                    route: `${ORG_CONSOLE_COURSES}`,
                    orgId
                  })}
                  title="Courses"
                >
                  <span className='d-flex align-items-center'>
                    <FaLayerGroup size={20} />
                    <span className='ml-2'>
                      Courses
                    </span>
                  </span>
                </Link>
              )}

              {canManageProjects(role) && enableProjects && (
                <Link
                  to={ORG_CONSOLE_PROJECTS}
                  className="dropdown-item border-bottom py-2"
                  data-cy="topNavOrgLinkProjects"
                >
                  <span className='d-flex align-items-center'>
                    <FaImages size={20} />
                    <span className='ml-2'>
                      Projects
                    </span>
                  </span>
                </Link>
              )}

              {/* {canAccessAssessments(role, orgType) && (
                <Link
                  to={routeWithOrgId({
                    route: ORG_CONSOLE_CHALLENGES,
                    orgId
                  })}
                  title="View all Challenges"
                  className="dropdown-item border-bottom py-2"
                  data-cy="topNavOrgLinkChallenges"
                >
                  <span className='d-flex align-items-center'>
                    <FaClipboardList size={20} />
                    <span className='ml-2'>
                      Challenges
                    </span>
                  </span>
                </Link>
              )} */}

              {canAccessOrgLocations(role, orgType) && !enableWorkforceSuite && (
                <div className="nav-item">
                  <Link
                    to={routeWithOrgId({
                      route: ORG_CONSOLE_LOCATIONS,
                      orgId
                    })}
                    className="dropdown-item border-bottom py-2"
                    data-cy="topNavOrgLinkLocations"
                  >
                    <span className='d-flex align-items-center'>
                      {orgType === 'workforce' ? (
                        <FaMapMarkerAlt size={20} className='mr-2' />
                      ) : (
                        <FaCity size={20} className='mr-1' />
                      )}
                      <span>
                        {locationTerm.text}s
                      </span>
                    </span>
                  </Link>
                </div>
              )}

              {canAccessSkills(role, orgType) && (
                <Link
                  to={ORG_CONSOLE_SKILLS}
                  className="dropdown-item border-bottom py-2"
                  data-cy="topNavOrgLinkSkills"
                >
                  <span className='d-flex align-items-center'>
                    <FaAtom size={20} />
                    <span className='ml-2'>
                      Skills
                    </span>
                  </span>
                </Link>
              )}

              {canAccessResources(role, enableResources, orgType) && (
                <Link
                  to={SEARCH_CONTENT}
                  className="dropdown-item border-bottom py-2"
                  data-cy="topNavOrgLinkSkills"
                >
                  <span className='d-flex align-items-center'>
                    <FaSearch size={18} />
                    <span className='ml-2'>
                      Search
                    </span>
                  </span>
                </Link>
              )}

              <div
                className="card-footer py-2"
              >
                <div>
                  <div className="small text-muted font-weight-bold">
                    Turbine Workforce
                  </div>
                  <div className="text-muted small">
                    <span className="text-capitalize">{orgType} Edition</span> v{flags?.app_turbine_admin?.value || 0}
                  </div>
                </div>
                <div className='d-flex'>
                  {canAccessBilling(role) && (
                    <Link
                      className="btn btn-link pl-0 mr-3"
                      style={{
                        textDecoration: 'underline'
                      }}
                      to={routeWithOrgId({
                        route: BILLING,
                        orgId
                      })}
                    >
                      Billing
                    </Link>
                  )}

                  {canAccessOrgSettings(role) && (
                    <Link
                      className="btn btn-link pl-0"
                      style={{
                        textDecoration: 'underline'
                      }}
                      data-cy="topNavOrgLinkBilling"
                      to={{
                        pathname: ORG_MANAGE,
                        search: manageEntry({
                          manageType: 'edit',
                          orgId,
                          contentType: 'organization'
                        })
                      }}
                      title="Organization Settings"
                    >
                      Settings
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>

          {canAccessResources(role, enableResources, orgType) && currentPage !== 'search' && (
            <div className="d-flex align-items-center">
              <SearchBoxLauncher
                className='in-top-nav'
                placeholder='Ask or Search...'
              />
            </div>
          )}

          {/* <div className="d-none d-md-flex align-items-center">
            <span className="h5 m-0 font-weight-bold d-none d-lg-block">
              {pageTitleByPathname({ pathname, orgType })}
            </span>
            <span className="h6 m-0 font-weight-bold d-block d-lg-none">
              {pageTitleByPathname({ pathname, orgType })}
            </span>
          </div> */}
        </div>

        {currentPage !== 'Update Required' && (
          <div className="d-flex align-items-center">

            {!isOrgNotificationsRoute && (
              <button
                type="button"
                className="topNav-icon-dropdown-btn"
                onClick={openNotificationsMenu}
                aria-label='Notifications'
                style={{
                  width: '40px'
                }}
              >
                <FaBell size={18} />
              </button>
            )}

            <button
              type='button'
              className='topNav-icon-dropdown-btn mr-2'
              onClick={openAppsMenu}
              title="Widgets"
              aria-label='Widgets'
              style={{
                width: '35px'
              }}
            >
              <FaTh size={18} />
            </button>

            {orgType === 'workforce' && <TopNavWeather />}

            <ul className="navbar-nav">
              <div className="nav-item dropdown">
                <div
                  className="top-nav-trigger nav-link dropdown-toggle text-nowrap border-left"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="user-profile">
                    <img
                      src={profilePicture || DEFAULT_PROFILE_PIC}
                      alt={userName}
                    />
                  </span>
                </div>
                <div
                  className="dropdown-menu my-0 py-0 dropdown-menu-right"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <UserDetailsBlock>
                    <a
                      className="btn btn-sm btn-white border mt-2"
                      href={redirectToTurbineApp(`${TURBINE}/profile`)}
                      title="My Profile"
                    >
                      My Profile
                    </a>
                  </UserDetailsBlock>

                  {canActivateDemoMode(role) && (
                    <div className="py-2 px-3 border-top">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="privateMode"
                          defaultChecked={isDemoModeActive()}
                          onClick={toggleDemoMode}
                        />
                        <label
                          className="custom-control-label text-nowrap"
                          htmlFor="privateMode"
                        >
                          Private Mode
                        </label>
                      </div>
                    </div>
                  )}

                  {isSuperAdmin(role) && (
                    <button
                      className="dropdown-item border-top py-2"
                      onClick={openLLMCourseCreate}
                      type="button"
                    >
                      <span className='d-flex align-items-center'>
                        <FaPlus size={20} />
                        <span className='ml-2'>
                          LLM Course Create
                        </span>
                      </span>
                    </button>
                  )}

                  <button
                    className="dropdown-item border-top py-2"
                    onClick={openReportIssueModal}
                    type="button"
                  >
                    <span className='d-flex align-items-center'>
                      <FaLifeRing size={20} />
                      <span className='ml-2'>
                        Report Issue
                      </span>
                    </span>
                  </button>

                  {(canAccessAdminDocs(role) || canAccessProgramTrainingDocs(role)) && (
                    <div
                      className="nav-item bg-light py-2 pl-4 border-bottom border-top font-weight-bold"
                    >
                      Documentation
                    </div>
                  )}

                  {canAccessAdminDocs(role) && (
                    <a
                      className="dropdown-item border-bottom py-2 d-flex align-items-center"
                      href={TURBINE_ADMIN_DOCS}
                      target="_blank"
                      title="Documentation"
                      rel="noreferrer noopener"
                    >
                      <HiDocumentSearch size={20} className='mr-2' />
                      <span>
                        Turbine Admin Guide
                      </span>
                    </a>
                  )}

                  {canAccessProgramTrainingDocs(role) && (
                    <a
                      className="dropdown-item border-bottom py-2 d-flex align-items-center"
                      href={TURBINE_TRAINING_PROGRAM_DOCS}
                      target="_blank"
                      title="Documentation"
                      rel="noreferrer noopener"
                    >
                      <HiDocumentSearch size={20} className='mr-2' />
                      <span>
                        Apprentage Guide
                      </span>
                    </a>
                  )}

                  <button
                    className="dropdown-item border-top py-2"
                    onClick={handleLogout}
                    type="button"
                  >
                    <i className="fas fa-sign-out-alt text-danger" /> Sign out
                  </button>
                </div>
              </div>
            </ul>
          </div>
        )}
      </div>
      <TopNavLocation />
    </nav>
  );
};

export default TopNav;
