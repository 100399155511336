import {
  isAdminOwnerSuperAdmin,
  isProgramManager,
  isProgramTrainer
} from '@apprentage/utils';

export default function canAccessProgramTrainingDocs(role = []) {
  return (
    isProgramTrainer(role)
    || isProgramManager(role)
    || isAdminOwnerSuperAdmin(role)
  );
}
