import React, { Fragment } from 'react';
import { FaCloudUploadAlt, FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import ManageButton from '../../btns/ManageButton';
import { SHEET_KEY_IMPORT_MATERIAL } from '../../../constants/sideSheets';
import { setSideSheet } from '../../../actions/SideSheets';

const pointerId = (data) => {
  if (data) {
    const dataLen = data.length;

    if (dataLen) {
      const lastItem = data[dataLen - 1];

      if (lastItem && lastItem.sys && lastItem.sys.id) { // TODO rm after all data is flattened using flattenItems
        return lastItem.sys.id;
      }

      if (lastItem && lastItem.id) {
        return lastItem.id;
      }
    }
  }

  return undefined;
};

const EmptyData = ({
  disabled,
  classId,
  topicId,
  className = '',
  options,
  data,
  manageButton = false,
  children
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgId = organization?.id || null;

  const onClickImportJSON = () => {
    dispatch(setSideSheet({
      key: SHEET_KEY_IMPORT_MATERIAL,
      className: 'MaterialSideSheet ImportMaterialSideSheet',
      data: {
        topicId,
        classId,
        userId: currentUser?.id,
        orgId
      }
      // callbackPrimaryAction: () => {
      //   // retrieveMaterials();
      // }
    }));
  };

  return (
    <div
      className={`empty-sections border rounded bg-light px-3 pt-3 ${className}`}
    >
      <div className="row">
        {options && options.map((option, i) => (
          <div
            key={i}
            className="col mb-3"
          >
            <div className="card h-100 text-center">
              <div className="card-content p-2">
                <div className="list-group list-group-flush">
                  <div className="list-group-item" disabled={disabled}>
                    {option?.icon && (
                      <div className="h3 d-flex justify-content-center mb-3">
                        <i className={option.icon} />
                      </div>
                    )}

                    {option?.iconComponent && (
                      <div className="h3 d-flex justify-content-center mb-3">
                        {option.iconComponent()}
                      </div>
                    )}

                    {(option.title || option.contentType) && (
                      <h4 className="my-2 text-capitalize">
                        {option.title || option.contentType}
                      </h4>
                    )}

                    {option.subtitle && (
                      <p className="px-3">
                        {option.subtitle || 'Lorem ipsum'}
                      </p>
                    )}

                    {option.button && (
                      <Fragment>
                        {option.button()}
                      </Fragment>
                    )}

                    {children && (
                      <Fragment>
                        {children}
                      </Fragment>
                    )}

                    <div className='d-flex flex-column'>
                      {manageButton && (
                        <div>
                          <ManageButton
                            className="btn btn-md btn-outline-primary"
                            referenceIndex={data && data.length ? data.length : 0}
                            classId={classId}
                            topicId={topicId}
                            pointerId={pointerId(data)}
                            iconComponent={() => <FaPlus />}
                            addContent
                            addTypes={[option.contentType]}
                            manageType={data && data.length ? 'insertBelow' : 'insertAbove'}
                            // entryId={course.eUnitTracking.sys.id}
                            contentType={option.contentType}
                            disabled={disabled}
                          >
                            {option?.importJSON && (
                              <>
                                {option?.addResourceMaterial && (
                                  <button
                                    className="dropdown-item py-2 border-bottom"
                                    onClick={onClickImportJSON}
                                    disabled
                                    type="button"
                                  >
                                    <span className='d-flex align-items-center'>
                                      <FaPlus />
                                      <span className='ml-2'>
                                        Resource Material
                                      </span>
                                    </span>
                                  </button>
                                )}

                                {option?.importJSON && (
                                  <div>
                                    <button
                                      className="dropdown-item py-2"
                                      onClick={onClickImportJSON}
                                      type="button"
                                    >
                                      <span className='d-flex align-items-center'>
                                        <FaCloudUploadAlt />
                                        <span className='ml-2'>
                                          Import Material (JSON)
                                        </span>
                                      </span>
                                    </button>
                                  </div>
                                )}
                              </>
                            )}

                          </ManageButton>
                        </div>
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmptyData;
