import { ICON_CITY, ICON_MAP_PIN } from '@apprentage/constants';
import {
  SET_ORG
} from '../../actions/types';

const dictionaryByType = (orgType) => {
  switch (orgType) {
    case 'workforce': {
      return {
        location: 'Location',
        locations: 'Locations',
        locationIcon: ICON_MAP_PIN,
        supervisor: 'Supervisor',
        supervisors: 'Supervisors',
        teacher: 'Expert',
        teachers: 'Experts',
        user: 'User',
        users: 'Users',
        userGroup: 'Department',
        userGroups: 'Departments'
      };
    }
    case 'community':
    default: {
      return {
        location: 'Employer',
        locations: 'Employers',
        locationIcon: ICON_CITY,
        supervisor: 'Mentor',
        supervisors: 'Mentors',
        teacher: 'Instructor',
        teachers: 'Instructors',
        user: 'Trainee',
        users: 'Trainees',
        userGroup: 'Group',
        userGroups: 'Groups'
      };
    }
  }
};

export default function dictionary(state = {}, action) {
  switch (action.type) {
    case SET_ORG: {
      return {
        ...state,
        ...dictionaryByType(action?.organization?.type)
      };
    }
    default:
      return state;
  }
}
