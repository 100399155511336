import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FaArrowAltCircleRight,
  FaArrowLeft, FaExclamationTriangle, FaInfoCircle
} from 'react-icons/fa';
import { FaFileMedical } from 'react-icons/fa6';
import { MdDriveFileMove } from 'react-icons/md';
import Collapse from '@apprentage/components/dist/components/Collapse';
import CircleButton from '@apprentage/components/dist/components/CircleButton';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  IMAGE_MOVE_FILE_CENTER_ARROW_RIGHT,
  IMAGE_MOVE_FILE_LEFT,
  IMAGE_MOVE_FILE_RIGHT
} from '../../../constants/assets';
import { createResource, fetchResource, updateResource } from '../../../services/resources';
import { updateMaterial } from '../../../services/materials';
import { getResourcesByParentId } from '../../../actions/Resources';
import { resetSideSheet } from '../../../actions/SideSheets';
import { routeWithOrgId } from '../../../services/organizations';
import { ORG_CONSOLE_RESOURCES } from '../../../constants/routes';
import { DEFAULT_PAGINATION_LIMIT } from '../../../constants/api';
import { fetchResourceGroup } from '../../../services/resourceGroups';
import { scrollElem } from '../../../services/manageContent';
import { resetMaterial } from '../../../actions/Materials';
import MaterialIcon from '../../ManageContent/MaterialIcon';
import PaginationSupabase from '../../ManageContent/PaginationSupabase';
import MyMaterials from '../../Dashboard/MyMaterials';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import DotMenu from './DotMenu';
import ChooseResourceFolder from './ChooseResourceFolder';
import './style.css';

const MaterialAddSideSheet = ({ className = '' }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const sideSheet = useSelector((state) => state.sideSheet);
  const materials = useSelector((state) => state.materials);
  // Current User
  const userId = currentUser?.id || null;
  // Organization
  const orgId = organization?.id || null;
  // Side Sheet
  const confirmMoveAfterCreate = sideSheet?.data?.confirmMoveAfterCreate || false;
  const moveToResources = sideSheet?.data?.moveToResources || false;
  const sideSheetTitle = sideSheet?.data?.title || 'Add Material';
  // Local State
  const [resourceGroup, setResourceGroup] = useState(sideSheet?.data?.resourceGroup || null);
  const [groupIds, setGroupIds] = useState(sideSheet?.data?.groupIds || null);
  const [material, setMaterial] = useState(sideSheet?.data?.material || null);
  const [currentMaterialResourceGroup, setCurrentMaterialResourceGroup] = useState(null);
  const materialResourceGroupTitle = currentMaterialResourceGroup?.title || 'My Resources';
  const disableContinue = resourceGroup?.id === currentMaterialResourceGroup?.id;

  const initialListConfig = {
    orgId,
    userId,
    limit: DEFAULT_PAGINATION_LIMIT,
    isDraft: true,
    // workflowType: null,
    page: 1,
    order: '-createdAt'
  };

  const [listConfig, setListConfig] = useState(initialListConfig);

  const moveFromMyMaterials = () => {
    createResource({
      parentType: 'resourceGroup',
      parentId: resourceGroup?.id,
      parentIds: Array.isArray(groupIds) ? groupIds : null,
      contentId: material?.id,
      createdAt: material?.createdAt,
      updatedAt: material?.updatedAt,
      userId: currentUser?.id,
      userName: currentUser?.name,
      orgId: organization?.id,
      // Material
      type: 'material',
      name: material?.title,
      body: material?.body
    }).then((response) => {
      const resourceId = response?.id || null;

      updateMaterial({
        resourceId,
        isDraft: null
      }, material?.id);

      if (moveToResources && !confirmMoveAfterCreate) {
        history.push(routeWithOrgId({
          route: ORG_CONSOLE_RESOURCES,
          urlParams: {
            groupIds: groupIds.join(',')
          }
        }));
        toast.success('Material added!');
        dispatch(resetSideSheet());
      } else {
        dispatch(getResourcesByParentId(resourceGroup?.id, 'resourceGroup')).then(() => {
          toast.success('Material added!');
          dispatch(resetSideSheet());
        });
      }
    });
  };

  const moveFromResourceGroup = () => {
    // User is in the existing Resource Group and clicked "move"
    // then selected a new Resource Group "resourceGroup"
    // below we are updating the existing Material Resource's parentId & parentIds
    // to be the same as the new "resourceGroup" they selected.
    if (!groupIds || (Array.isArray(groupIds) && groupIds.length === 0)) {
      toast.error('Something went wrong, try again');
      return;
    }

    updateResource({
      parentId: resourceGroup?.id,
      parentIds: groupIds
    }, material?.resourceId).then(() => {
      // Redirect User to new Resource Group
      history.push(routeWithOrgId({
        route: ORG_CONSOLE_RESOURCES,
        urlParams: {
          groupIds: groupIds.join(',')
        }
      }));
      toast.success('Material moved!');
      dispatch(resetSideSheet());
    });
  };

  const onClickContinue = () => {
    if (currentMaterialResourceGroup?.id) {
      // Material has corresponding Resource
      // and is in a Resource Group
      moveFromResourceGroup();
    } else {
      // Material does NOT have a corresponding Resource
      // and is not in any Resource Group
      moveFromMyMaterials();
    }
  };

  const retrieveMaterialLocation = useCallback(() => {
    if (material?.id && material?.resourceId) {
      fetchResource(material?.resourceId).then((resource) => {
        if (resource?.parentId) {
          fetchResourceGroup(resource?.parentId).then((materialResourceGroup) => {
            setCurrentMaterialResourceGroup(materialResourceGroup);
          });
        }
      });
    }
  }, [material?.id, material?.resourceId]);

  useEffect(() => {
    retrieveMaterialLocation();
  }, [retrieveMaterialLocation]);

  useEffect(() => {
    return function cleanup() {
      if (material?.id) {
        dispatch(resetMaterial());
      }
    };
  }, [dispatch, material?.id]);

  if (moveToResources && !resourceGroup?.id) {
    return (
      <div className={`card ${className}`}>
        <CardHeader title={sideSheetTitle} moveToResources={moveToResources} />

        <div className='card-body overflow-y-scroll'>
          <ChooseResourceFolder
            onSelect={(data) => {
              setResourceGroup(data?.resourceGroup);
              setGroupIds(data?.resourceGroupIds);
              // setResourceGroupsList(data?.resourceGroupsList);
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={`card ${className}`}>
      <CardHeader title={sideSheetTitle} moveToResources={moveToResources} />

      {material?.id && (
        <>
          {moveToResources ? (
            <div
              className='d-flex align-items-center font-weight-bold px-3 pt-3'
            >
              <CircleButton
                className="shadow mr-3"
                size='sm'
                onClick={() => {
                  setResourceGroup(null);
                }}
              >
                <FaArrowLeft size={15} className='text-primary' />
              </CircleButton>
              <div>
                All Resources
              </div>
            </div>
          ) : (
            <div
              className='d-flex align-items-center font-weight-bold px-3 pt-3'
            >
              <CircleButton
                className="shadow mr-3"
                size='sm'
                onClick={() => {
                  setMaterial(null);
                }}
              >
                <FaArrowLeft size={15} className='text-primary' />
              </CircleButton>
              <div>
                My Resources
              </div>
            </div>
          )}
        </>
      )}

      <div
        id='MaterialAddSideSheet-MyMaterials'
        className='card-body overflow-y-scroll pb-0'
      >
        {material?.id && (
          <div>
            <p
              className='text-center mb-0'
            >
              {material?.resourceId ? 'Move' : 'Publish'} {material?.title}?
            </p>

            <div
              className='d-flex align-items-start justify-content-center'
            >
              <div
                className='text-center'
              >
                <img
                  src={IMAGE_MOVE_FILE_LEFT}
                  className='mx-auto'
                  height={180}
                  style={{
                    height: '180px'
                  }}
                  alt="Move File"
                />
                <div
                  className='mt-2'
                  style={{
                    maxWidth: '120px'
                  }}
                >
                  <div className='small'>
                    {materialResourceGroupTitle}
                  </div>
                  <div className='small mt-1 mb-0 text-muted font-weight-normal'>
                    ( Current Folder )
                  </div>
                </div>
              </div>
              <div
                className='d-flex justify-content-center m-4'
                style={{
                  width: '50px'
                }}
              >
                <img
                  src={IMAGE_MOVE_FILE_CENTER_ARROW_RIGHT}
                  className='mx-auto'
                  height={150}
                  style={{
                    height: '150px'
                  }}
                  alt="Move File"
                />
              </div>
              <div
                className='text-center'
              >
                <img
                  src={IMAGE_MOVE_FILE_RIGHT}
                  className='mx-auto'
                  height={180}
                  style={{
                    height: '180px'
                  }}
                  alt="Move File"
                />
                <div
                  className='mt-2'
                  style={{
                    maxWidth: '120px'
                  }}
                >
                  <div className='small font-weight-bold'>
                    {resourceGroup?.title}
                  </div>
                  <div className='small mt-1 mb-0'>
                    ( New Folder )
                  </div>
                </div>
              </div>
            </div>

            <div
              className='d-flex justify-content-center mt-4'
            >
              <button
                className={`btn btn-md ${disableContinue ? 'btn-outline-secondary' : 'btn-primary'}`}
                type="button"
                onClick={onClickContinue}
                disabled={disableContinue}
              >
                <span className='d-flex align-items-center'>
                  <span className='mr-2'>
                    Continue
                  </span>
                  <FaArrowAltCircleRight />
                </span>
              </button>
            </div>

            {disableContinue && (
              <div className='mt-2 d-flex justify-content-center'>
                <p className='d-flex align-items-center small'>
                  <FaExclamationTriangle className='text-danger mr-1' />
                  <span>
                    Material is already in <strong>{currentMaterialResourceGroup?.title}</strong>.
                  </span>
                </p>
              </div>
            )}

            <div
              className='mt-3 mb-2 d-flex align-items-center'
            >
              <MaterialIcon
                className='ml-1'
                promptType={material?.promptType}
                fileUploadFlow={material?.fileUploadFlow}
              />
              <span className='ml-1 font-weight-bold'>
                Material
              </span>
            </div>

            <Collapse
              id="challenge-info"
              title={material?.title}
              className="mb-3"
              p={3}
            >
              <RenderMarkdown
                enableNewEditor
                source={material?.body}
              />
            </Collapse>

            <div
              className='my-3'
            >
              <FaInfoCircle className='d-inline mr-1' />
              <span>
                {material?.resourceId ? 'Moving' : 'Publishing'} <span className='font-weight-bold'>{material?.title}</span> will make it accessible to all {organization?.name} users who can access <span className='font-weight-bold'>{resourceGroup?.title}</span>.
              </span>
            </div>

          </div>
        )}

        {!material?.id && (
          <>
            <MyMaterials
              selectable
              listConfig={listConfig}
              confirmMoveAfterCreate={confirmMoveAfterCreate}
              onSelect={(data) => {
                setMaterial(data);
              }}
            />
            {Array.isArray(materials.list) && materials.list.length > 0 && (
              <PaginationSupabase
                items={materials?.list}
                pagination={materials?.pagination}
                page={materials?.pagination?.page}
                onClickNext={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: materials?.pagination?.page + 1
                  }));
                  scrollElem('MaterialAddSideSheet-MyMaterials');
                }}
                onClickPrev={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: materials?.pagination?.page - 1
                  }));
                  scrollElem('MaterialAddSideSheet-MyMaterials');
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MaterialAddSideSheet;

const CardHeader = ({ title, moveToResources }) => {
  return (
    <div className='card-header d-flex align-items-center justify-content-between'>
      <div
        className="h6 m-0 font-weight-bold"
        style={{
          width: '72%'
        }}
      >
        <span
          className="d-flex align-items-center"
        >
          {moveToResources ? (
            <MdDriveFileMove size={20} className='d-none d-sm-block mr-2' />
          ) : (
            <FaFileMedical size={20} className='d-none d-sm-block mr-2' />
          )}
          <span
            className="text-nowrap"
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            }}
          >
            {title}
          </span>
        </span>
      </div>
      <div className='d-flex align-items-center'>
        <DotMenu />
      </div>
    </div>
  );
};
