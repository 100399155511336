import React, { Component } from 'react';
import Color from './Color';
import './style.css';

const colors = [
  {
    name: 'grey',
    hex: '#b3bac5'
  },
  {
    name: 'black',
    hex: '#344563'
  },
  {
    name: 'green',
    hex: '#61bd4f'
  },
  {
    name: 'yellow',
    hex: '#f2d600'
  },
  {
    name: 'orange',
    hex: '#ff9f1a'
  },
  {
    name: 'red',
    hex: '#eb5a46'
  },
  {
    name: 'purple',
    hex: '#c377e0'
  },
  {
    name: 'blue',
    hex: '#0079bf'
  },
  {
    name: 'sky',
    hex: '#00c2e0'
  },
  {
    name: 'lime',
    hex: '#51e898'
  },
  {
    name: 'pink',
    hex: '#ff78cb'
  }
];

class ColorSwatches extends Component {
  state = {
    activeColor: null
  }

  handleClick = (color) => {
    const { handleClick } = this.props;

    if (handleClick) {
      this.props.handleClick(color);
    }

    this.setState({
      activeColor: color
    }, () => {
      console.log('ColorSwatches', this.state);
    });
  }

  render() {
    const { activeColor } = this.props;

    return (
      <div className="ColorSwatches">
        {colors.map((color, i) => (
          <Color
            key={i}
            color={color.hex}
            handleClick={this.handleClick}
            activeColor={activeColor}
          />
        ))}
      </div>
    );
  }
}

export default ColorSwatches;
